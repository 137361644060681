import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Section5Figures() {
  useEffect(() => {
    gsap.from('.zorg-numbers', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.zorg-numbers',
    })
  }, [])

  return (
      <>
      <g >
      <path
        style={{
          fill: "none",
        }}
        d="M634.24 3626.58h169.09v67.29H634.24z"
      />
      <text transform="translate(696.915 3652.827)">
        <tspan
          x={0}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 35,
          }}
          className="zorg-numbers"
        >
          {"32"}
        </tspan>
        <tspan
          x={-46.37}
          y={17}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"instanties samen in "}
        </tspan>
        <tspan
          x={-49.73}
          y={34}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"De Rotterdamse Zorg"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M483.11 3546.3h187.02v101.26H483.11z"
      />
      <text transform="translate(559.427 3587.555)">
        <tspan
          x={0}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 55,
          }}
          className="zorg-numbers"
        >
          {"1"}
        </tspan>
        <tspan
          x={34.38}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 40,
          }}
        />
        <tspan
          x={-48.21}
          y={17}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Zero Tolerance Day"}
        </tspan>
        <tspan
          x={-29.94}
          y={34}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {" in Rotterdam"}
        </tspan>
      </text>
      <path
        style={{
          fill: "#e1aa82",
        }}
        d="m360.29 3634.85 3.59 1.84s6.55 4 7.68 6.1c1.13 2.1.88 9.44.31 10.49-.57 1.05-5.29.59-5.29.59l-9.71-12.16 3.42-6.86z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M304.59 3627.9s-6.02-6.31-6.8-7.62c-1.12-1.89-4.98-23.69-4.98-23.69l8.84-4.98 9.69 34.48-6.75 1.81z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M297.1 3572.16s17.98.36 25.26 1.09c1.31.13 2.28.28 2.73.43 2.93 1.02 4.98 28.19 5.63 32.93.65 4.74 1.64 18.14 1.64 18.14h-25.49s-7.16-23.3-8.62-27.19-3.91-17.11-3.42-21.51c.48-4.4 2.27-3.89 2.27-3.89z"
      />
      <path
        style={{
          fill: "#e1aa82",
        }}
        d="m368.07 3660.22-15.21 5.64c.01 3.22-7.67 56.82-7.67 56.82l12.42 3.61s1.46.1 2.5-.63l-8.4-2.98 16.36-62.46z"
      />
      <path
        style={{
          fill: "#d09e78",
        }}
        d="m337.7 3722.68 14.23-56.96-14.98.03-5.88 56.93 14.19 4.2 1.26-.63-8.82-3.57z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M306.86 3624.75s-7 7.45-8.79 23.71c-.95 8.64-11.58 21.8 39.96 17.45.44 0 .27 4.63.27 4.63s25.74-2.06 30.65-8.95c-.15-9.18.39-5.28-.15-9.18-.84-6.03-6.3-9.05-21.54-12.61-13.12-3.07-11.16-8.62-14.9-15.05h-25.5zM348.19 3726.88c-1.1-.78-2.05-.93-2.73-.88-1.27.09-2.54-.1-3.77-.44l-10.59-2.88-.03 7.14 19.13-.08c-.01-.01.19-1.29-2.01-2.86z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M363.95 3727.41c-.51-.45-1.32-1.06-3.8-1.75-.91.63-2.2.63-2.2.63l-12.76-3.61.05 7.05 20.1.28s-.34-1.68-1.39-2.6z"
      />
      <path
        style={{
          fill: "#e1aa82",
        }}
        d="M324.43 3542.18s.57 20.92-2.12 21.51c-2.68.59-5.04-.17-5.04-.17l-7.16-4.91s-2.12-12.87 3.74-16.01c5.86-3.13 10.58-.42 10.58-.42z"
      />
      <path
        style={{
          fill: "#d09e78",
        }}
        d="M311.34 3558.61s6.18 3.98 5.93 4.91c-.24.93 0 7.54 0 7.54l-10.25-.47 4.32-11.98z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M323.73 3573.43c.01 0 .01 0 0 0 3.3-.02 6.21 1.23 8.18 7.03 1.81 5.33 4.94 28.32 5.91 35.64.19 1.44.95 2.72 2.1 3.54l20.61 14.73-3.91 7.86-26.52-13.3c-1.37-.68-2.35-1.98-2.68-3.52l-8.38-39.32c0-.01-2.96-11.88 4.69-12.66zM302.38 3570.55s12.61 0 14.89.51c2.28.51 3.76 2.08 3.76 2.08l-19.38-.99.73-1.6z"
      />
      <path
        style={{
          fill: "#272425",
        }}
        d="M323.24 3537.94s-4.23-7.9-15.62-5.87c-11.39 2.03-13.34 9.77-12.86 16.59.5 7.13-2.19 9.49-4.77 11.77-5.18 4.58-2.55 9.55-2.11 12.69.66 4.67-6.23 8.51-3.46 16.83 1.68 5.05 6.62 6.83 11.58 6.97 3.8.11 11.58-.27 12.33-6.9.9-7.94 5.51-7.79 6.6-14.28 1.35-8.06-2.14-7.9.13-15.06 1.4-4.43 9.11-13.04 9.41-17.44.29-4.39-1.23-5.3-1.23-5.3z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M310.93 3675.48h4.64v46.75h-4.64z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M313.25 3721.92c6.68 0 23.43 3.62 23.43 8.09h-46.86c.01-4.46 16.76-8.09 23.43-8.09zM280.97 3604.62s29.96-1.86 31.19 20.72c.87 15.91 2.29 29.37 6.82 31.01 4.62 1.66 10.32 1.2 18.13 2.45 12.94 2.07 11.81 10.71 11.81 10.71s-33.17 9.73-49.08 4.39c-12.62-4.23-15.87-18.24-17.12-26.01-3.2-19.83-1.75-43.27-1.75-43.27z"
      />
      <path
        transform="rotate(9.495 633.86 3727.832)"
        style={{
          fill: "#f99d31",
        }}
        d="M494.99 3716.75h277.28v22.36H494.99z"
      />
      <path
        transform="rotate(-80.509 522.384 3711.864)"
        style={{
          fill: "none",
        }}
        d="M511.73 3670.93h21.34v81.82h-21.34z"
      />
      <text
        transform="rotate(9.495 -22116.063 4915.412)"
        style={{
          fill: "#522e91",
          fontFamily: "'Sansa'",
          fontWeight: 'bold',
          fontSize: "20.672px",
        }}
      >
        {"De"}
      </text>
      <path
        transform="rotate(-80.509 577.823 3718.398)"
        style={{
          fill: "none",
        }}
        d="M560.38 3660.11h34.91v116.52h-34.91z"
      />
      <text
      // className="zorg-numbers"
        transform="rotate(9.495 -22184.665 5075.468)"
        style={{
          fill: "#522e91",
          fontFamily: "'Sansa'",
          fontWeight: 'bold',
          fontSize: "45.4785px",
        }}
      >
        {"100"}
      </text>
      <path
        transform="rotate(-80.509 690.872 3741.131)"
        style={{
          fill: "none",
        }}
        d="M680.21 3667.06h21.34v148.08h-21.34z"
      />
      <text
        transform="rotate(9.495 -22182.268 5634.422)"
        style={{
          fill: "#522e91",
          fontFamily: "'Sansa'",
          fontWeight: 'bold',
          fontSize: "20.672px",
        }}
      >
        {"Van Sander"}
      </text>
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#00aeef",
        }}
        d="M697.21 3580.13c.02.01.9.03 1.95.05 1.05.02 1.92.04 1.93.05.03.04.01 1.31-.02 1.51-.1.57-.34 1.15-.55 1.32-.41.34-1.27.5-3.16.59-1.49.07-3.18.11-3.71.09-1.54-.07-3.01-.23-3.48-.39-.67-.23-1.13-.68-1.13-1.11 0-.45.46-.91 1.29-1.33 1.01-.5 2.1-.68 4.34-.74.75-.02 1.62-.04 1.93-.05.32 0 .59 0 .61.01z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#f799d1",
        }}
        d="M722.47 3494.03c0 .33 1.32 10.13 1.32 10.13l-9.69 6.72-.66-10.46 9.03-6.39z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="M668.11 3524.89c.3.01 1.04.04 1.65.05.6.01 1.11.03 1.12.04.05.03 4.47.07 7.82.07 3.47 0 5.09-.02 7.02-.08l.78-.02-.02.51c-.01.28-.03.72-.04.97-.01.25-.04.82-.05 1.26l-.09 2.04c-.03.68-.07 1.59-.09 2-.1 2.04-.15 3.07-.2 3.9-.02.5-.06 1.1-.07 1.35-.01.24-.04.88-.07 1.42-.08 1.43-.11 2.17-.27 5.8-.02.45-.04.94-.05 1.08a302.703 302.703 0 0 1-.57 8.14c-.01.12-.04.44-.05.71-.02.27-.05.69-.07.92-.02.23-.05.65-.07.92-.02.27-.06.81-.09 1.19-.03.38-.09 1.25-.14 1.93-.11 1.51-.22 2.86-.41 4.98-.27 3.04-.38 4.64-.57 7.86-.08 1.37-.15 2.58-.16 2.7l-.02.22-.61.02c-.75.03-2.95.09-3.75.1l-.59.01-.04-.5c-.09-1.01-.19-2.65-.33-5-.3-5.29-.37-6.67-.44-10.04-.07-2.98-.11-4.5-.14-5.37-.02-.49-.03-.88-.09-2.64-.02-.65-.09-2.4-.15-3.9-.08-1.99-.11-3.38-.12-5.14-.01-1.32-.02-2.4-.03-2.39-.02.02-.46 4.29-.69 6.67-.29 3.13-.46 5.07-.59 6.9-.05.79-.29 4.11-.39 5.55-.03.41-.09 1.21-.12 1.77-.04.56-.13 1.76-.2 2.64s-.15 2.01-.18 2.5c-.33 5.38-.59 9.49-.6 9.51-.02.03-3.88.16-4.01.14-.04-.01-.18-.02-.31-.02h-.23l-.02-.29c-.04-.49-.41-7.05-.46-8.2-.01-.22-.04-.86-.07-1.44-.03-.57-.07-1.4-.09-1.84-.05-1.15-.13-2.87-.2-4.08-.03-.57-.08-1.54-.11-2.15-.07-1.43-.2-3.58-.35-5.89-.16-2.43-.23-3.84-.3-6.56-.03-1.16-.05-1.57-.09-2.62-.01-.23-.04-1.01-.07-1.72-.15-3.58-.2-4.73-.23-5.44-.09-1.83-.14-3.03-.16-3.28-.02-.27-.1-2.09-.15-3.12-.01-.32-.02-1.39-.02-2.38 0-1.53 0-1.79.05-1.79.02.01.29.01.59.03z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
        d="M703.15 3549.3c.39-.29.96-.35 3.05-.34 1.5.01 3.9.09 4.79.15.47.04 2.17.11 3.79.18.54.02 1.3.06 1.7.1.95.07 2.13.08 3.1 0 .95-.07 1.27-.09 2.09-.12.88-.03 2.77-.12 3.37-.16 1.32-.09 5.64-.2 6.19-.16 1.11.09 1.37.14 1.66.35.15.11.2.17.27.33.07.19.08.26.09 1.3.02 1.77-.09 2.57-.41 2.9-.06.06-.2.13-.38.19-.27.09-.31.1-1.12.1-.46 0-1.17.01-1.57.01l-.74.01.24 26.43.02 1.48h-.19c-.11 0-.69.01-1.29.02-.64.01-1.11.01-1.12-.01l-.12-27.89c-.01-.01-1.88.01-4.17.04-4.97.06-3.76.06-8.75 0-2.29-.03-4.16-.04-4.17-.04-.02.02-.06 7.03-.08 17.55-.01 5.67-.03 10.32-.04 10.33-.01.02-.49.02-1.17.01-.63-.01-1.22-.02-1.29-.02h-.13l.02-2.45.2-23.35.01-2.09-.94-.03c-.51-.01-1.16-.02-1.43-.01-.28.01-.58 0-.68-.02-.98-.18-1.08-.42-1.14-2.61-.06-1.63-.02-1.91.34-2.18zM676.43 3480.74c.73.05 1.78.29 2.66.61.45.17 1.22.54 1.65.81 1.2.74 2.49 2.06 3.31 3.39.2.33.49.66 1.6 1.81.41.43.92.97 1.13 1.21s.61.66.89.94c.27.28.76.8 1.07 1.15.32.35.88.95 1.26 1.33.38.38.78.79.89.92l.2.23.33-.11c1.1-.36 2.14-.65 4.83-1.37 1.07-.29 2.32-.62 2.77-.74 1.54-.43 2.76-.73 4.59-1.16.18-.04.34-.06.36-.05.01.02.12.45.25.97.12.52.29 1.22.37 1.56.18.71.34 1.46.33 1.48-.01.01-.78.38-1.51.72-.99.46-2.34 1.12-6.81 3.32-4.24 2.08-5.84 2.68-7.48 2.82-.42.04-1.17.01-1.4-.05-.11-.03-.11-.02-.04.72.18 1.78.35 3.97.57 7.2.32 4.74.47 6.77.82 11.01.48 5.94.8 10.35 1.05 14.61.06 1.02.12 2.12.15 2.45.13 2.12.04 11.14-.16 15.13-.03.59-.06 1.13-.07 1.21l-.02.15-.26.04c-1.2.19-3.26.4-4.81.5-3.88.26-7.41.24-11.14-.05-3.18-.24-7.9-.94-11.47-1.69-.77-.16-.89-.2-.91-.26-.03-.07-.06-.66-.15-2.27-.03-.65-.01-2.34.06-4.03.01-.26.04-.9.05-1.42.1-2.77.37-7.64.73-13.48.59-9.51 1.09-15.02 1.97-21.81.41-3.16 1.18-8.14 1.75-11.21.71-3.87 1.54-7.36 2.23-9.38.59-1.71 1.33-3.18 2.07-4.11.71-.89 1.29-1.38 2.6-2.2.37-.23.8-.43 1.34-.6.69-.28 1.45-.36 2.35-.3z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#e5f7fd",
        }}
        d="M673.98 3484.53c.73.12 1.56.44 2.34.91 1.19.73 2.7 2.13 4.14 3.86 2.33 2.79 4.29 5.56 5.52 7.76l.32.58c.02.04.18 0 .68-.15.85-.26 1.87-.53 2.87-.76 1.22-.28 3.18-.77 3.83-.95 1.91-.52 4.24-1.07 4.3-1.01.12.13.59 1.6.87 2.77l.09.37-.27.29c-.61.63-3.04 3.01-3.81 3.71-1.98 1.81-3.39 2.83-5.06 3.66-.96.48-1.59.7-2.37.84-.59.1-.94.12-1.49.07-1.26-.11-2.45-.47-3.83-1.17-1.24-.63-3.24-1.87-4-2.47-1.77-1.42-3.72-3.28-5.09-4.87-2.09-2.43-3.49-5.1-3.88-7.37-.23-1.33-.07-2.5.5-3.64.46-.93 1.34-1.74 2.28-2.14.55-.23.89-.3 1.4-.32.25 0 .54.01.66.03z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
        d="M683.75 3575.31c1.92 1.58 2.55 2.19 3.07 2.98.24.37.31.51.31.73 0 .22-.06.34-.26.48-.71.48-2.32-.24-5.68-2.52-.68-.46-1.05-.74-1.99-1.49l-.62-.5.44-.02c.39-.02 1.79-.07 3.53-.12l.6-.02.6.48zM674.94 3576.06c1.27.95 2.68 2.14 3.33 2.79.49.49.74.81.93 1.19.11.22.13.3.15.52.01.22 0 .28-.06.38-.21.34-.92.43-1.72.22-.61-.16-1.77-.67-2.32-1.03-1.1-.7-3.04-2.15-3.94-2.95-.52-.45-1.46-1.44-1.42-1.48.02-.02 2.92-.09 3.72-.09h.73l.6.45z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#63439c",
        }}
        d="M712 3537.08c.01.01.13.02.26.02.26-.01 1.15.09 2.05.22 1.29.2 3.06.37 4.97.48.35.02 1.01.06 1.47.09.53.03 1.98.06 4.01.09 1.74.02 3.74.05 4.43.06.69.01 1.6.01 2.02 0 .49-.02.77-.02.8.01.11.09.09 2.98-.04 4.34-.1 1.11-.29 2.21-.46 2.74-.11.34-.39.94-.63 1.38-.34.61-.67.98-1.23 1.37-.9.63-2.29 1.01-5.02 1.37-3.37.44-5.41.58-9.88.64-2.34.03-3.98.07-6.74.14-.67.02-1.74.04-2.38.04h-1.16l-.02.11c-.01.06-.03.3-.04.52-.01.22-.09 1.02-.18 1.77-.35 2.99-.58 5.04-.63 5.55-.03.29-.1.95-.16 1.47-.12 1.13-.26 2.38-.35 3.37-.04.4-.17 1.54-.28 2.54-.12.99-.27 2.42-.35 3.16-.08.74-.21 1.94-.3 2.66-.09.72-.24 2.06-.35 2.98-.33 2.84-.59 4.94-.66 5.52l-.08.56-.18.02c-.67.08-4.2.04-4.29-.05-.04-.04-.11-.68-.18-1.61l-.11-1.4c-.03-.38-.12-1.4-.21-2.27-.43-4.21-.6-6.23-.81-9.49-.22-3.33-.27-5.03-.27-8.53 0-4.91.12-7.06.52-9.49.23-1.35.4-2 .78-2.85.36-.81.75-1.47 1.28-2.17.43-.57 1.24-1.31 1.94-1.77.66-.44 2.19-1.16 3.12-1.48.37-.12 2.32-.74 2.77-.87 1.56-.46 3.38-.84 5.02-1.05.32-.04.77-.11 1-.14.42-.08.51-.09.55-.05z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#00aeef",
        }}
        d="M707.89 3488.38c.41.03 1.06.13 1.11.16.02.02.05.08.05.14 0 .11-.24 1.1-.46 1.89-.08.27-.16.66-.19.87l-.05.37-.14.03c-1 .21-1.86.51-2.91 1l-.5.23-.1-.31c-.21-.67-.81-2.88-.81-3.01 0-.09.35-.33.83-.57 1.17-.58 2.29-.86 3.17-.8zM703.35 3492.95c.45.08.8.18 1.65.46 1.47.49 2.02.85 2.02 1.33 0 .12-.02.17-.13.28-.22.22-.55.34-2.47.89-1.77.51-3.68 1.2-4.47 1.61-.48.25-.86.41-.97.41-.1 0-.1 0-.37-.84-.24-.73-.58-1.93-.65-2.26-.02-.1-.01-.11.34-.32 1.35-.8 2.75-1.41 3.59-1.56.39-.07 1.07-.06 1.46 0zM724 3500.81c.15.1.35.24.46.33.11.08.32.25.48.37.45.34 1.6 1.39 2.02 1.83.91.98 1.62 2.03 2.41 3.57 1.32 2.6 2.31 6.04 3 10.52.77 4.93-.3 11.44-.3 17.87 0 1.42-.01 2.62-.02 2.66-.01.05-.06.08-.16.1-.29.05-4.51.06-6.71.02-2.89-.05-4.39-.11-7.16-.31-1.51-.1-1.95-.15-3.72-.35-2.19-.26-2.59-.32-2.59-.44 0-.03-.01-.12-.01-.2-.13-1.24-.4-4.98-.48-6.51-.15-2.95-.14-6.82.02-9.74.21-3.88.7-7.55 1.42-10.64.3-1.3.72-2.71.79-2.71.04 0 .11.17.11.25 0 .11.26.64.4.83.33.43.74.66 1.23.68.9.05 1.79-.37 2.89-1.32.91-.79 3.34-2.94 4.07-4.22.26-.44.3-.52.76-1.44.62-1.23.7-1.35.77-1.35.03.03.18.11.32.2z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#baabd3",
        }}
        d="M690.5 3458.31c1.7.23 3.11.94 4.24 2.11.61.64 1.19 1.57 1.58 2.55.22.56 1.03 3.02 1.16 3.54.55 2.18.58 4.61.11 7.39-.7 4.1-2.89 8.29-5.22 9.97-.84.61-1.52.91-2.5 1.12-1.68.35-3.59-.15-4.78-1.24-.07-.07-.16-.11-.19-.09-.14.08-.94 1.42-1.52 2.54l-.4.77-.12-.17c-.28-.37-2.3-2.07-3.11-2.62-.82-.55-2.65-1.37-3.5-1.56-.31-.07-.39-.11-.38-.18.18-.65 1.08-3.52 1.62-5.18l.7-2.15-.09-1.1c-.07-.91-.08-1.24-.03-1.89.19-2.52 1.07-5.37 2.44-7.81 1.83-3.28 3.99-5.14 6.79-5.83 1.08-.25 2.14-.31 3.2-.17z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#f799d1",
        }}
        d="M713.23 3499.62c-.02-.04-.14-.03-.4.03-1.2.25-2.76-.31-3.84-1.39-1.03-1.02-1.79-2.83-2.14-5.05-.09-.59-.1-.81-.1-2.18 0-1.16.02-1.71.08-2.27.54-4.98 2.33-8.7 5.65-11.72 1.89-1.73 3.69-2.62 5.67-2.84.57-.06 1.39-.05 1.92.02.89.12 1.56.33 2.36.74.77.39 1.37.81 1.96 1.38.59.57.97 1.07 1.54 2 1.01 1.66 1.44 2.84 1.65 4.55.09.68.07 2.39-.02 3.16-.26 2.14-.84 4.23-1.8 6.41-.14.32-.58 1.22-.98 2l-.72 1.42v.03c-3.06 2.42-6.67 4.06-10.48 5.61-.05-.27-.09-.53-.14-.8-.1-.58-.19-1.08-.21-1.1zM715.8 3517.73c.56.09 1.9.2 3.03.23.85.02 1.27.04 2.34.11.7.04 2.82.16 2.92.16.01 0 .03.12.03.28 0 .46-.13 2.68-.25 4.12-.15 1.9-.17 2.18-.3 3.53-.03.29-.12 1.3-.2 2.25-.22 2.7-.43 4.7-.53 5.28-.07.41-.34 1.51-.49 1.99-.12.4-.3.76-.46.91-.26.24-.88.57-1.59.84-.34.13-1.54.5-2.3.72-1.43.4-3.26.76-5.06.99-1.08.14-3.12.38-3.9.46-1.41.15-5.6.68-6.41.82-1.07.18-1.83.4-2.22.64-.38.24-.48.38-1.22 1.7-.85 1.52-1.1 1.9-1.46 2.2-.32.26-.52.35-.79.35-.29 0-.51-.12-.67-.36-.2-.29-.23-.47-.23-1.08 0-.51 0-.57.1-.89.13-.43.27-.74.51-1.17.11-.18.24-.43.3-.55.23-.46.79-1.21 1.18-1.58.41-.39 1.46-1.23 1.99-1.6.07-.05.29-.21.69-.49.71-.51 2-1.35 3.17-2.06.92-.55 1.84-1.14 2.71-1.71.82-.53 1.25-.82 1.92-1.23.41-.26.6-.38 1.18-.76.17-.11.57-.37.89-.57l.58-.37.02.31c.02.17.06.63.1 1.02.08.78.18 1.93.18 2.04 0 .09-.04.1.89-.24 1.62-.6 2.47-1.04 2.96-1.52.39-.39.42-.5.49-1.78.04-.83.03-1.24-.08-4.27-.02-.5-.05-1.85-.07-3-.02-1.15-.05-2.55-.07-3.1-.06-1.83-.08-2.66-.07-2.66 0 .01.09.02.19.04z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#00aeef",
        }}
        d="M684.6 3450.39c.56.07 1.44.3 1.97.49.79.3 1.34.61 2.03 1.16l.36.3.42-.07c.68-.1 1.96-.14 2.64-.07 1.39.14 2.86.58 3.98 1.2 2.15 1.18 3.55 3.04 3.92 5.19.05.27.08.59.08.72 0 .22.02.24.47.68.68.65 1.13 1.34 1.41 2.19.19.56.24.83.29 1.48.07.97-.03 1.81-.34 2.72-.61 1.82-1.7 3.23-3.39 4.37-.29.2-.55.36-.57.36-.03 0-.14-.15-.24-.32-.62-.98-1.01-1.43-1.61-1.87-1.31-.97-3.01-1.39-5.37-1.32-2.31.06-4.43.6-6.69 1.7-1.62.78-2.63 1.55-3.63 2.71-.69.81-.99 1.25-1.67 2.46-.33.6-.62 1.1-.62 1.11-.06.08-2.17-.78-3.32-1.35-1.26-.62-1.94-1.08-2.63-1.76-1.1-1.1-1.61-2.3-1.61-3.83 0-1.09.25-1.93.81-2.75.12-.19.23-.36.23-.38 0-.03-.09-.23-.2-.46-.51-1.02-.65-1.68-.65-2.97 0-.84.02-1.04.14-1.61.38-1.81 1.14-3.31 2.16-4.27.77-.73 1.5-1.11 2.53-1.31.23-.05.47-.09.53-.11.07-.02.19-.15.31-.35 1.56-2.59 4.31-4.11 7.36-4.08.32 0 .73.02.9.04z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
        d="M686.39 3470.03c1.26 1.29 1.75 1.7 3.16 2.67.3.2.89.62 1.32.93 1.17.84 1.66 1.15 2.3 1.46l.57.28.68.03c.82.04 1.46.14 2.05.31.76.23.87.39.82 1.16-.06.96-.39 1.86-1.1 3.05-.62 1.04-1.28 1.88-2.26 2.88-.98.99-1.59 1.43-2.7 1.96-.87.42-1.12.48-2.06.51-1.25.04-2.13-.11-2.98-.54-.39-.2-.57-.33-.89-.65-.46-.46-.64-.72-1.25-1.78-1.41-2.46-2.92-5.93-3.85-8.83-.34-1.06-.34-.94.01-1.32l.3-.33.12.45.12.45.16-.08c.26-.14 1.36-.83 1.73-1.1.57-.41 1.73-1.31 2.05-1.58l.3-.26-.23-.33-.23-.33.28-.12c.15-.07.3-.13.32-.13.02-.01.59.56 1.26 1.24zM720.17 3489.11c.13.14.09.27-.09.39-.21.15-1.47 1.38-1.84 1.82-.42.49-.43.67-.08 1.26.44.73.8 1.08 1.09 1.08.35 0 .61-.16 1.55-.94.26-.21.32-.22.42-.07.07.1.07.12 0 .22-.04.06-.23.25-.42.41-.62.55-1.18 1.1-1.85 1.84-1.09 1.2-1.55 1.62-2.67 2.46-.83.62-2.4 1.74-2.87 2.04-.67.44-1.42.65-2.04.58-.71-.07-1.65-.44-2.08-.84-.3-.28-.41-.49-.45-.82-.03-.25-.03-.25-.19-.27-.72-.11-1.29-.58-1.69-1.39-.26-.53-.35-.89-.35-1.42 0-.56.09-.88.34-1.16.31-.33.35-.34 1.71-.55 1.14-.18 1.59-.27 2.67-.57 1.14-.31 2.14-.63 3.53-1.12 1.25-.45 1.63-.62 2.61-1.19.76-.45.89-.53 1.86-1.31.36-.29.69-.53.72-.53.02 0 .08.03.12.08z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
        d="M707.41 3494.91c.62.42.99.71.99.78 0 .04-.02.08-.05.11-.07.06-.21 0-.53-.27-.14-.11-.38-.29-.54-.39-.16-.11-.3-.21-.31-.23-.01-.02 0-.1.01-.16l.02-.12.41.28z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#866db2",
        }}
        d="M720.74 3472.81c1.1.07 2.41.34 3.34.69 1.53.58 3.06 1.66 4.09 2.9.58.68 1.32 1.97 1.7 2.95.41 1.04.62 3.07.48 4.56-.18 1.82-.68 3.78-1.3 5.12-.99 2.14-2.9 5.25-4.53 7.38-.33.43-.33.44-.5.41-.45-.06-1.23-.2-1.46-.27l-.26-.07-.09-.28c-.05-.15-.18-.6-.3-.99s-.34-1.15-.49-1.66c-.16-.52-.37-1.25-.47-1.62-.27-.99-.51-1.75-.8-2.61-.15-.42-.37-1.11-.5-1.52-.36-1.19-.34-1.15-1.09-1.63-.79-.5-1.96-1.21-2.62-1.58-1.59-.89-4.38-2.09-6.3-2.71-.76-.25-.86-.29-.86-.38 0-.14.23-1.08.43-1.71.67-2.19 1.71-3.9 3.01-4.95 1.95-1.57 4.87-2.27 8.52-2.03z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="M408.75 3692.83c.03-.01 1.31-1.21 2.82-2.65 1.51-1.44 2.77-2.62 2.79-2.62.09.01 1.84 1.82 2.08 2.17.66.95 1.13 2.1 1.07 2.63-.11 1.05-1.1 2.47-3.65 5.25-2 2.18-4.34 4.6-5.11 5.31-2.27 2.05-4.58 3.87-5.47 4.31-1.26.61-2.55.61-3.15 0-.62-.64-.62-1.93-.02-3.68.73-2.11 2.01-3.9 5.12-7.11 1.04-1.07 2.23-2.32 2.66-2.77.44-.44.82-.82.86-.84zM417.68 3699.18c.03-.01 1.31-1.21 2.82-2.65 1.51-1.44 2.77-2.62 2.79-2.62.09.01 1.84 1.83 2.08 2.17.66.96 1.13 2.11 1.08 2.63-.11 1.05-1.1 2.48-3.65 5.26-2 2.18-4.34 4.6-5.11 5.31-2.27 2.05-4.58 3.87-5.48 4.31-1.26.61-2.55.61-3.15 0-.62-.64-.63-1.93-.02-3.68.73-2.11 2.01-3.9 5.11-7.11 1.04-1.08 2.23-2.32 2.66-2.77.45-.45.83-.83.87-.85z"
      />
      <path
        transform="rotate(-180 450.295 3678.989)"
        style={{
          fill: "#f99d31",
        }}
        d="M447.99 3658.16h4.61v41.65h-4.61z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M450.29 3699.81c-6.64 0-23.29 3.85-23.29 8.61h46.58c0-4.75-16.65-8.61-23.29-8.61zM482.38 3582.78s-29.78-1.98-31.01 22.04c-.86 16.93-2.27 31.25-6.78 32.99-4.59 1.77-10.26 1.28-18.02 2.61-12.87 2.2-11.74 11.39-11.74 11.39s32.97 10.35 48.79 4.67c12.55-4.51 15.77-19.4 17.02-27.68 3.19-21.08 1.74-46.02 1.74-46.02z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="M440.77 3570.5c-1 14-3.7 33.92-3.59 35.02.12 1.29.37 1.73 1.43 2.2 1.05.48 14.56 1.84 14.86 2.13s.38 4.1.14 4.67c-.23.56-16.53 1.87-18.58 1.18-2.05-.69-4.08-4.19-4.33-7.35-.25-3.16.28-28 .28-28-1.24-17.03 10.46-19.21 9.79-9.85z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="m457.25 3597.82 6.38 5.97s9.2 14.29 8 25.54c-1.2 11.25-4.68 16.81-15.47 16.81h-30.85c-.71 0 14.24 47.45 14.68 49.55.44 2.1-19.83 10.5-28.87 5.85 0 0-.6-.53-.71-2.03-.11-1.5-.05-3.41-.05-3.41s-15.36-6.08-17.59-10.95c0 0-.22-.73.01-1.82.17-.82 6.33-29.79 12.75-46.98 7.6-20.35 19.97-18.03 34.74-17.73 0 0-.67-5.9-.35-8.01.32-2.11.96-3.53 2.1-3.82.84-.22-.08-1.1-.62-1.55 5.26-3.41 15.85-7.42 15.85-7.42z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="m457.25 3597.82 6.38 4.98s9.2 11.92 8 21.3c-1.2 9.38-4.68 14.01-15.47 14.01h-30.85s-2.86-7.17-3.26-8.55c-.41-1.38-.68-2.85-.08-2.6.6.25 1.2 6.95 13.29 6.95s25.6-7.07 24.62-13.08c-.98-6.01-8.93-5.67-10.57-5.67h-9.03s-.67-4.92-.35-6.68c.32-1.76.96-2.94 2.1-3.19.84-.18-.08-.92-.62-1.29 5.25-2.83 15.84-6.18 15.84-6.18zM410.36 3696.1s-4.96-1.96-9.66-4.56c1.51-1.94 3.97-5.33 4.27-7.08.44-2.55 3.16-31.6 3.42-31.7.26-.09.3.34.3.34l1.67 43z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="M465.64 3569.31c-6.71-4.56-12.26-3.01-21.08-2.83-3.62.74-5.12 3.17-6.51 6.3-1.15 2.59-.09 14.84-.1 15.97-.29 2.63-.49 6.17 2.37 10.73.83 5.77-1.43 15.56-1.32 17.37.53 8.55 28.17 2.67 27.14-1.23-5.14-19.43.75-29.42.44-36.88-.21-5.28-.6-8.47-.94-9.43z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="M441.26 3574.22s3.7 6.87 4.34 8.63c.64 1.76-2.42 29.68-2.63 30.38-.21.69-2.66 2.26-2.66 2.26l.03.3-3.59-.11s.31-16.5.17-18.38c-.14-1.88-3.56-11.37-4.06-13.98-.5-2.62 4.62-16.26 4.62-16.26l3.78 7.16z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="M461.01 3569.11s5.57.79 5.42 5.55c0 0 .98-.43 1.25-1.45.28-1.02 2.74-13.22 2.31-15.26-.43-2.04-2-5.13-5.12-8.43 0-.01-9.5 12.72-3.86 19.59z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="M451.77 3562.18s-9.15 25.99-9.37 26.81c-.21.82 7.72 24.77 8.22 25.51.5.74 4.27.9 6.12 1.31 1.85.41 13.01 2.19 15.12 4.16 0 0 .76-.57.51-1.87-.25-1.31-5.55-14.47-5.77-15.33-.21-.86.35-26.92.35-26.92s-2.52-17.34-15.18-13.67z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#e27e67",
        }}
        d="m453.63 3543.98-.35 3.14s-.48 6.46-.55 7.44c-.07.98.61 2.78 3.09 3.15 2.02.31-4.91 3.64-9.04 2.69-4.27-.99-5.27-5.02-3.63-5.28.51-.08 1.05-.53 1.38-.87 1.03-1.08 1.37-6.34.53-7.8-.84-1.46 8.57-2.47 8.57-2.47z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="M441.56 3518c1.91-3.23 4.81-5.7 8.85-6.27 7.94-1.12 16.34 3.29 18.14 14.98.52 3.38.59 7.05.48 10.74l-3.03 2.87-.72 1.88-4.62-1.51-14.88-3.97c1.46-3.9 2.49-7.88 2.49-7.88l-8.16 2.86c0 .01-1-8.48 1.45-13.7z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="m447.2 3577.15-1.6 2.31s-.54 1.91-3.22-.99-5.09-12.56-5.99-15.67c-.9-3.11.67-6.59 5.18-10.77s5.33-5.58 5.33-5.58l16.16-8.85.43 13.4-16.29 26.15z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="m443.7 3544.06-2 2.01s-1.66.83-1.85 4.05c-.18 3.22 2.47 10.25 4.11 14.78 1.65 4.53 5.23 3.47 11.55-2.52 6.31-6 8.55-14.36 8.55-14.36l-1.44-6.12-18.92 2.16z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#522e91",
        }}
        d="m458.15 3538.66 2.1 5.48s-.3 6.14-4.93 11.86-12.32 12.81-11.88 26.1c0 0 .04.97.82 1.09.78.12 4.68.23 7.27 1.1 0 0 1.83.6 2.19-1.46 4.21-23.85 6.51-15.69 12.39-33.13 2.67-7.92 3.73-14.53 2.43-22.97-1.4-9.11-6.8-13.79-12.87-14.89 3.07 2.69 6.77 9.37 5.21 25.89l-2.73.93z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="m445.55 3530.63-5.25-.63c.37-5.44.46-12.5 6.62-12.86 7.44-.43 14.81 12.6 13.97 20.56l-15.34-7.07z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#e1aa82",
        }}
        d="m460.84 3538.76-.37 3.87c-.64 6.68-5.92 11.55-11.74 10.81-5.82-.74-10.05-6.8-9.41-13.48l.92-9.63s.8-6.51 6.02-8.31c5.23-1.79 15.32 6.76 14.58 16.74z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#fff",
        }}
        d="M440.72 3528.32c.67-2.07 2.2-5.15 5.54-6.3 3.94-1.35 10.63 3.17 13.38 9.74-10.44-10.66-16.23-7.45-18.92-3.44z"
      />
      <path
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#272425",
        }}
        d="M455.67 3565.45c-6.35 12.69-13.99 31.5-14.68 32.39-.81 1.04-1.31 1.27-2.7 1.05-1.39-.22-16.64-7.12-17.11-7.06-.47.06-2.58 3.32-2.63 3.94-.05.62 16.79 11.51 19.36 12.14 2.57.63 6.61-1.18 8.55-3.75 1.95-2.57 14.53-24.34 14.53-24.34 10.35-13.97-1.08-22.85-5.32-14.37z"
      />
      <path
        style={{
          fill: "#f5ac83",
        }}
        d="m418.66 3596.12-2.95-3.54c-1.67-1.38-2.72-3.36-2.64-5l.05-.99c.06-1.32 1.69-1.44 2.94-.21l5.12 5.46"
      />
    </g>
      </>
  )
}
