import React from "react";

export default function Section7() {
    return (
        <g id="lj">
            <path
              style={{
                fill: "#7bc143",
              }}
              d="M718.39 4970.94c10.99 4.26 67.32-48.9 71.18-56.39 3.85-7.48 26.13-51.95-13.53-75.59-25.07-14.94-13.37-25.92-16.7-44.25-8.93-1.88-17.88-3.69-26.97-4.58-16.63 2.56-33.27 5.71-49.85 5.36-16.09-.34-32.05-3.95-47.98-5.98-15.29-1.94-32.16-3.83-47.2.55-25.54 7.43-42.98 34.05-48.28 58.96-2.4 11.28-.23 19.95 6.18 29.4 3.84 5.66 7.84 10.68 9.1 17.62 1.18 6.56.2 13.34-2.16 19.54-5.58 14.66-17.07 21.32-31.42 25.84-8.27 2.6-16.91 3.9-25.19 6.48-7.27 2.27-12.05 8.77-19.12 11.59-4.07 1.62-8.98.04-9.98-4.69-1.94-9.13 5.2-16.62 7-25.88 1.79-9.2 7.12-15.99 12.52-23.36 4.26-5.82.3-13.88 1.79-21.08 1.46-7.05-.1-15.59-3.79-21.74-6.17-10.27-17.07-17.88-15.08-31.22 1.81-12.16 8.62-22.86 13.8-33.78 11.57-24.35 32.42-40.12 59.43-43.29 34.94-4.1 70.13 7.55 104.09 13.96 29.47 5.57 58.8 2.67 86.9-6.84.5-1.02.97-2.06 1.38-3.09-2.01-1.77-4.16-3.57-6.49-5.44 0 0-72.51 15.25-97.39 2.17-24.88-13.08-130.46-39.71-161.58 16.45-31.12 56.16-63.38 147.58-97.4 178.82-34.02 31.24-94.05 46.13-94.36 58.39-.3 12.26 58.18 74.66 58.18 74.66l-21.91-1.95s-40.27-62.51-53.19-60.77c-12.92 1.74 27.48 52.51 27.48 52.51l-14.24-.94s-41.25-48.14-54.07-39.9c-12.82 8.24 37.95 47.08 37.95 47.08l-17.94 6.07s-35.98-32.84-50.81-27.42c-14.82 5.42-16.24 23.43-16.24 23.43l54.69 28.9-12.75 12.56s-34.55-37.31-51.08-26.57c-16.53 10.74-19.4 28.72-19.4 28.72s44.54 28.84 71.88 27.45 58.95-13.79 96.84-11.8c37.89 1.99 58.58 12.27 80.94 35.44 22.36 23.17 75.95 78.18 75.95 78.18s-44.19 42.52-42.44 61.98c1.75 19.46 17.54 42.71 37.94 26.73 20.4-15.99 26.02-22.18 23.88-38.13-2.15-15.96 12.58-30.13 12.58-30.13l46.86-41.8s-24.86-110.49-2.01-120.52c22.85-10.04 37.66 2.71 55.71 12.14s63.94 2.36 68.88-6.23c4.94-8.59-13.7-32.03-13.7-32.03s38.71 1.69 52.73-19.33c14.02-21.03 12.96-38.09 9-39.36s-23.87-.39-41.76-14.86c-17.87-14.46 30.16-46.29 41.15-42.04z"
            />
            <path
        style={{
          fill: "#522e91",
        }}
        d="M531.45 4823.71v116.21h84.19v-116.21"
      />
      <path
        style={{
          fill: "#6c4db2",
        }}
        d="M558.81 4873.57h23.72v12.08h-23.72zM584.69 4873.57h23.72v12.08h-23.72zM536.56 4873.57h11.56v12.08h-11.56zM558.81 4832.9h23.72v12.08h-23.72zM584.69 4832.9h23.72v12.08h-23.72zM536.56 4832.9h11.56v12.08h-11.56zM536.56 4853.23h17.66v12.08h-17.66zM556.38 4853.23h23.72v12.08h-23.72zM588.52 4853.23h23.72v12.08h-23.72zM536.56 4893.9h17.66v12.08h-17.66zM556.38 4893.9h23.72v12.08h-23.72zM588.52 4893.9h23.72v12.08h-23.72z"
      />
        </g>
    )
}