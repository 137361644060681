import React from "react";

export default function Section2() {
    return (
       <>
	    <path  style={{fill:"#7BC143"}} d="M309.42,1557.57l-120.99,1.1v-0.06h-75.69c-24.53,0-44.41,22.29-44.41,49.79   c0,27.5,19.88,49.79,44.41,49.79h56.4c24.53,0,44.41,22.29,44.41,49.79c0,27.5-19.88,49.79-44.41,49.79h3.26   c-15.64,0-28.32,14.21-28.32,31.75c0,17.53,12.68,31.75,28.32,31.75h240.47c15.64,0,28.32-14.21,28.32-31.75   c0-17.53-12.68-31.75-28.32-31.75h120.48c24.52,0,44.41-22.29,44.41-49.79c0-27.5-19.88-49.79-44.41-49.79h-67.91   c-24.53,0-44.41-22.29-44.41-49.79c0-27.5,19.88-49.79,44.41-49.79h-60.01"/>
		<path  style={{fill:"#7BC143"}} d="M512.18,1821.78l139.76-1.1v0.06h87.44c28.33,0,51.3-22.29,51.3-49.79   c0-27.5-22.97-49.79-51.3-49.79h-65.15c-28.33,0-51.3-22.29-51.3-49.79c0-27.5,22.97-49.79,51.3-49.79h-3.76   c18.06,0,32.71-14.21,32.71-31.75c0-17.53-14.64-31.75-32.71-31.75H392.69c-18.06,0-32.71,14.21-32.71,31.75   c0,17.53,14.64,31.75,32.71,31.75H253.52c-28.33,0-51.3,22.29-51.3,49.79c0,27.5,22.97,49.79,51.3,49.79h78.45   c28.33,0,51.3,22.29,51.3,49.79c0,27.5-22.97,49.79-51.3,49.79h69.32"/>
		<path  style={{fill:"#7BC143"}} d="M323.84,1493.52c0,17.53,14.21,31.75,31.75,31.75H220.51c-27.5,0-49.79,22.29-49.79,49.79   c0,27.5,22.29,49.79,49.79,49.79h76.14c27.5,0,49.79,22.29,49.79,49.79c0,27.5-22.29,49.79-49.79,49.79h67.28"/>
		<path  style={{fill:"#7BC143"}} d="M555.99,1722.87l152.92-1.1v0.06h95.67c31,0,56.13-22.29,56.13-49.79   c0-27.5-25.13-49.79-56.13-49.79H733.3H494.88"/>

	      <path
      style={{
        fill: "#522e91",
      }}
      d="M322.23 1619.54v-135.35l87.43-73.09 87.43 73.09v135.35z"
    />
	 <path
      style={{
        fill: "#f799d1",
      }}
      d="M410.76 1408.08c-.64-.53-1.69-.52-2.32.02l-95.91 82.59c-.63.55-.7 1.51-.16 2.15l7.02 8.15c.55.64 1.52.71 2.15.16l87.04-74.96c.64-.54 1.68-.55 2.32-.02l89.92 75.06c.64.54 1.61.45 2.15-.2l6.9-8.26c.54-.64.45-1.61-.19-2.14l-98.92-82.55z"
    />
	<path
      style={{
        fill: "#522e91",
      }}
      d="M413.69 1619.54H270.92v-101.19l69.64-58.89 73.13 58.89z"
    />
    <path
      style={{
        fill: "#f799d1",
      }}
      d="M263.26 1524.06c-2.45 0-2.94-1.31-1.09-2.92l76.16-66.15c1.85-1.6 4.88-1.6 6.72 0l76.16 66.15c1.85 1.61 1.36 2.92-1.09 2.92H263.26zM417.13 1479.93c0 .5-.41.92-.92.92h-13.74c-.5 0-.92-.41-.92-.92v-23.64c0-.5.41-.91.92-.91h13.74c.5 0 .92.41.92.91v23.64z"
    />
	 <path
      style={{
        fill: "none",
        stroke: "#f799d1",
        strokeWidth: 3,
        strokeMiterlimit: 10,
      }}
      d="M392.59 1603.59c0 .84-.69 1.52-1.53 1.52l-36.8-.08c-.84 0-1.52-.69-1.52-1.52l.14-66.31c0-.83.69-1.52 1.53-1.52l36.8.07c.84 0 1.52.69 1.52 1.53l-.14 66.31z"
    />
    <path
      style={{
        fill: "#f799d1",
      }}
      d="M399.68 1614.33h-1.63v-4.96c0-.67-.56-1.22-1.22-1.22h-1.72v-4.96c0-.68-.55-1.23-1.23-1.23h-42.69c-.68 0-1.23.55-1.23 1.23v4.96h-1.72c-.67 0-1.22.55-1.22 1.22v4.96h-1.64c-.67 0-1.22.55-1.22 1.23v3.91c0 .66.55 1.22 1.22 1.22h54.31c.68 0 1.23-.56 1.23-1.22v-3.91a1.25 1.25 0 0 0-1.24-1.23zM586.79 1777.26v-8.13h-6.53v-8.12h-6.53v-8.13l-6.53.01v-8.13h-6.53v-8.12h-6.53v-8.13h-6.53v-8.12h-6.53v64.99H593.31v-8.12zM513.59 1719.5c-55.62 9.59-59.54 62.43-114.15 67.2v-9.82c0-5.68 3.73-10.61 9.12-12.34 35.1-11.28 50.35-58.97 105.03-65.2v20.16z"
    />
	<path
      style={{
        fill: "#522e91",
      }}
      d="M408.56 1764.53c35.1-11.28 50.35-58.97 105.03-65.2v2.55h2.76v-5.64l-3.07.35c-33.14 3.77-52.35 22.49-69.3 39-11.54 11.25-22.45 21.87-36.26 26.31-6.6 2.12-11.04 8.14-11.04 14.98v8.47c0 .82.69 1.48 1.51 1.43l1.25-.07v-9.82c0-5.69 3.73-10.62 9.12-12.36z"
    />
    <path
      style={{
        fill: "#522e91",
      }}
      d="M524.67 1689.55h-6.05c-2.78 0-5.03 2.26-5.03 5.04v24.9h34.86v-6.11c0-13.15-10.64-23.83-23.78-23.83z"
    />
	<path
      style={{
        fill: "#f799d1",
      }}
      d="M516.35 1716.73v-22.14c0-1.26 1.02-2.28 2.27-2.28h6.05c11.59 0 21.02 9.45 21.02 21.07v3.34h-29.34z"
    />
    <path
      style={{
        fill: "#522e91",
      }}
      d="M513.59 1719.5h34.86v6.16h-34.86zM518.47 1725.66h5.16v59.69h-5.16zM540.72 1725.66h5.16v59.69h-5.16z"
    />
	<path
      style={{
        fill: "#522e91",
      }}
      d="M521.05 1730.69h22.24v3.11h-22.24zM521.05 1741.46h22.24v3.11h-22.24zM521.05 1752.24h22.24v3.11h-22.24zM521.05 1763.01h22.24v3.11h-22.24zM521.05 1773.78h22.24v3.11h-22.24z"
    />

	   </>
    )
}