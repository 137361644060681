import React, { useEffect } from "react";
import { gsap } from 'gsap';


export default function FooterSection() {

    useEffect(() => {
        gsap.to('.tree-small', {
            y: '-3px'
        })

        gsap.to('.tree-big', {
            y: '-3px'
        })

        gsap.timeline({
            defaults:{duration:15, ease:'none'},
            repeat: -1
        })
        .to('#kite-top', {
            motionPath:{
                path: '#kite-path',
                align: '#kite-path',
                alignOrigin: [1, 1],
                autoRotate: false
            }
        })

    }, [])
   
    return (
        <>
            <g id="footer">
            <path id="bg_x5F_bottom_00000139987217751766727510000011723407479385517981_" style={{fill:'#7BC143'}} d="M761.064 5840.31C735.961 5840.14 711.417 5838.42 686.604 5832.86C675.055 5830.27 663.547 5827.86 651.829 5827.74C620.378 5827.42 589.106 5823.39 557.685 5822.3C541.476 5821.74 525.256 5821.87 509.116 5823.57C494.035 5825.15 479.083 5827.83 464.19 5830.68C433.718 5836.51 403.524 5842.54 372.512 5844.72C340.792 5846.94 309.051 5848.78 277.301 5850.51C212.482 5854.03 210.965 5857.87 146.246 5863.12L0 5871.03V5998H960.84V5871.03C930.157 5865.23 899.435 5859.76 868.693 5854.58C833.109 5848.59 797.536 5840.54 761.603 5840.33C761.424 5840.31 761.244 5840.31 761.064 5840.31Z" />
            <g>
                <g>
                    <polygon style={{fill:"#F799D1"}} points="657.7,5872.01 656.9,5883.65 651.25,5885.84 648.89,5881.33 650.16,5872.3    "/>
                    <polygon style={{fill:"#F799D1"}} points="679.45,5871.36 679.18,5882.75 676.35,5886.3 672.18,5883.46 670.42,5871.36    "/>
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="681.0422" y1="5845.0586" x2="681.0422" y2="5845.0586">
                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                    </linearGradient>
                    <path style={{fill:"url(#SVGID_1_)"}} d="M681.04,5845.06"/>
                    <polyline style={{fill:"#6F57A6"}} points="680.98,5847.21 679.78,5879.53 670.65,5879.78 667.55,5846.47 667.53,5845.44      667.39,5844.62 667.35,5843.45 667.23,5842.74 664.69,5813.03 661.83,5817.31 660.12,5842.88 661.04,5844.06 659.97,5845.02      661,5846.17 659.83,5847.1 658.41,5872.63 649.57,5872.63 647.32,5847.65 648.5,5846.65 647.21,5845.47 648.13,5844.02      647.09,5842.88 645.66,5813.43 645.66,5797.63 682.09,5795.93 682.09,5810.03 681.11,5842.91    "/>
                    
                        <linearGradient id="SVGID_00000048460624573211824540000001195731501145336242_" gradientUnits="userSpaceOnUse" x1="612.3536" y1="5771.2324" x2="696.7069" y2="5771.2324">
                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                    </linearGradient>
                    <path style={{fill:"url(#SVGID_00000048460624573211824540000001195731501145336242_)"}} d="M636.13,5790.96l-23.78-14.8l5.14-10.41     l12.34,7.32l9.23-29.38c3.35-3.24,16.82-6.38,16.82-6.38l1.62-2.94l6.92,5.58l7.18-6.17l1.58,2.95c0,0,15.78,3.01,19.47,6.96     c5.77,6.18,3.71,48.75,3.71,48.75l-11.74,3.76l1.4,10.1c0,0-9.24,3.34-14.96,1.54c-3.92-1.23-5.83-7.02-6.65-10.56l-2.88,0.09     c-0.03,3.66-0.5,9.9-3.68,11.01c-4.4,1.52-14.74-3.1-14.74-3.1l1.84-15.52l-1.39-17.18L636.13,5790.96z M684.18,5768.87     l-1.01,13.5l2.59-0.97L684.18,5768.87z"/>
                    <g>
                        <defs>
                            <path id="SVGID_00000067227017071013996900000013564588437861599668_" d="M636.13,5790.96l-23.78-14.8l5.14-10.41l12.34,7.32       l9.23-29.38c3.35-3.24,16.82-6.38,16.82-6.38l1.62-2.94l6.92,5.58l7.18-6.17l1.58,2.95c0,0,15.78,3.01,19.47,6.96       c5.77,6.18,3.71,48.75,3.71,48.75l-11.74,3.76l1.4,10.1c0,0-9.24,3.34-14.96,1.54c-3.92-1.23-5.83-7.02-6.65-10.56l-2.88,0.09       c-0.03,3.66-0.5,9.9-3.68,11.01c-4.4,1.52-14.74-3.1-14.74-3.1l1.84-15.52l-1.39-17.18L636.13,5790.96z M684.18,5768.87       l-1.01,13.5l2.59-0.97L684.18,5768.87z"/>
                        </defs>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_00000067227017071013996900000013564588437861599668_" style={{overflow:"visible",fill:"#00AEEF"}}/>
                        <clipPath id="SVGID_00000059276255617055753450000016774276230540677530_">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_00000067227017071013996900000013564588437861599668_" style={{overflow:"visible"}}/>
                        </clipPath>
                        <g style={{clipPath:"url(#SVGID_00000059276255617055753450000016774276230540677530_)"}}>                        
                                <linearGradient id="SVGID_00000094592484440640172890000011188313136952726413_" gradientUnits="userSpaceOnUse" x1="643.1638" y1="5772.3149" x2="643.7064" y2="5772.3149">
                                <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                <stop offset="1" style={{stopColor:"#522E91"}}/>
                            </linearGradient>
                            
                                <rect x="643.16" y="5734.81" style={{opacity:0.44,fill:'url(#SVGID_00000094592484440640172890000011188313136952726413_)'}} width="0.54" height="75.01"/>
                            
                                <linearGradient id="SVGID_00000067213443043267123630000001216943437480955563_" gradientUnits="userSpaceOnUse" x1="631.4913" y1="5772.3149" x2="632.0339" y2="5772.3149">
                                <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                <stop offset="1" style={{stopColor:"#522E91"}}/>
                            </linearGradient>
                            
                                <rect x="631.49" y="5734.81" style={{opacity:0.44,fill:'url(#SVGID_00000067213443043267123630000001216943437480955563_)'}} width="0.54" height="75.01"/>
                            
                                <linearGradient id="SVGID_00000142890600772515369850000014111018331385924753_" gradientUnits="userSpaceOnUse" x1="619.8188" y1="5772.3149" x2="620.3615" y2="5772.3149">
                                <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                <stop offset="1" style={{stopColor:"#522E91"}}/>
                            </linearGradient>
                            
                                <rect x="619.82" y="5734.81" style={{fill:"url(#SVGID_00000142890600772515369850000014111018331385924753_)"}} width="0.54" height="75.01"/>
                            <g>
                                <g>
                                        <linearGradient id="SVGID_00000148627057576491702530000006231425542204046986_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5741.9697" x2="698.4899" y2="5741.9697">
                                            <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                            <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                            <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                            <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                            <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                            <stop offset="1" style={{stopColor:"#522E91"}}/>
                                        </linearGradient>
                                    
                                        <rect x="610.61" y="5741.7" style={{fill:"url(#SVGID_00000148627057576491702530000006231425542204046986_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000024709070370914917120000003149015227232466321_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5750.7837" x2="698.4899" y2="5750.7837">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5750.51" style={{fill:"url(#SVGID_00000024709070370914917120000003149015227232466321_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000016781554921648866480000008005508790150854536_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5759.5981" x2="698.4899" y2="5759.5981">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5759.33" style={{fill:"url(#SVGID_00000016781554921648866480000008005508790150854536_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000092453026365399644540000000613745289611258263_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5768.4126" x2="698.4899" y2="5768.4126">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5768.14" style={{fill:"url(#SVGID_00000092453026365399644540000000613745289611258263_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000142855924548295399720000000114380993888700561_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5777.2266" x2="698.4899" y2="5777.2266">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5776.96" style={{fill:"url(#SVGID_00000142855924548295399720000000114380993888700561_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000096018100949315105570000018242453509262644615_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5786.041" x2="698.4899" y2="5786.041">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5785.77" style={{fill:"url(#SVGID_00000096018100949315105570000018242453509262644615_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000101818989397337791960000008797144690837756328_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5794.8555" x2="698.4899" y2="5794.8555">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5794.59" style={{fill:"url(#SVGID_00000101818989397337791960000008797144690837756328_)"}} width="87.88" height="0.54"/>
                                    
                                        <linearGradient id="SVGID_00000164488266946903596140000002547284972523934874_" gradientUnits="userSpaceOnUse" x1="610.6095" y1="5803.6694" x2="698.4899" y2="5803.6694">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="610.61" y="5803.4" style={{fill:"url(#SVGID_00000164488266946903596140000002547284972523934874_)"}} width="87.88" height="0.54"/>
                                </g>
                                <g>
                                    
                                        <linearGradient id="SVGID_00000087413977083057797250000013423974411669773697_" gradientUnits="userSpaceOnUse" x1="693.7445" y1="5771.3018" x2="694.2871" y2="5771.3018">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="693.74" y="5733.8" style={{opacity:0.44,fill:'url(#SVGID_00000087413977083057797250000013423974411669773697_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000029735607082635889540000007008345475214603913_" gradientUnits="userSpaceOnUse" x1="689.8538" y1="5773.165" x2="690.3964" y2="5773.165">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="689.85" y="5735.66" style={{opacity:0.44,fill:'url(#SVGID_00000029735607082635889540000007008345475214603913_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000143593979804877534370000010375525832172661409_" gradientUnits="userSpaceOnUse" x1="685.9628" y1="5772.5088" x2="686.5054" y2="5772.5088">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="685.96" y="5735" style={{opacity:0.44,fill:'url(#SVGID_00000143593979804877534370000010375525832172661409_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000077301152198891914120000004106168759956174010_" gradientUnits="userSpaceOnUse" x1="682.0721" y1="5771.3018" x2="682.6147" y2="5771.3018">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="682.07" y="5733.8" style={{fill:"url(#SVGID_00000077301152198891914120000004106168759956174010_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000114759220147271510530000001116971217631618228_" gradientUnits="userSpaceOnUse" x1="678.1812" y1="5773.165" x2="678.7238" y2="5773.165">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="678.18" y="5735.66" style={{opacity:0.44,fill:'url(#SVGID_00000114759220147271510530000001116971217631618228_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000145054658879111961660000000282732623381331355_" gradientUnits="userSpaceOnUse" x1="674.2904" y1="5772.5088" x2="674.8331" y2="5772.5088">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="674.29" y="5735" style={{opacity:0.44,fill:'url(#SVGID_00000145054658879111961660000000282732623381331355_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000022547188916800782400000017681234873305310116_" gradientUnits="userSpaceOnUse" x1="670.3995" y1="5771.3018" x2="670.9421" y2="5771.3018">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="670.4" y="5733.8" style={{fill:"url(#SVGID_00000022547188916800782400000017681234873305310116_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000089546256504049338640000011559797441701644422_" gradientUnits="userSpaceOnUse" x1="666.5088" y1="5773.165" x2="667.0514" y2="5773.165">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="666.51" y="5735.66" style={{fill:"url(#SVGID_00000089546256504049338640000011559797441701644422_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000062908092352729072520000009699364872421218204_" gradientUnits="userSpaceOnUse" x1="662.6179" y1="5772.5088" x2="663.1605" y2="5772.5088">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="662.62" y="5735" style={{fill:"url(#SVGID_00000062908092352729072520000009699364872421218204_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000004542261326035948500000016040441998735249586_" gradientUnits="userSpaceOnUse" x1="658.7271" y1="5771.3018" x2="659.2698" y2="5771.3018">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="658.73" y="5733.8" style={{fill:"url(#SVGID_00000004542261326035948500000016040441998735249586_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000054982601230569987900000007032669464568307380_" gradientUnits="userSpaceOnUse" x1="654.8362" y1="5773.165" x2="655.3788" y2="5773.165">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="654.84" y="5735.66" style={{opacity:0.44,fill:'url(#SVGID_00000054982601230569987900000007032669464568307380_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000073002637976554687410000003268835349085816737_" gradientUnits="userSpaceOnUse" x1="650.9455" y1="5772.5088" x2="651.4881" y2="5772.5088">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="650.95" y="5735" style={{fill:"url(#SVGID_00000073002637976554687410000003268835349085816737_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000014624763316685865110000007935624535304150955_" gradientUnits="userSpaceOnUse" x1="647.0546" y1="5770.4517" x2="647.5972" y2="5770.4517">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="647.05" y="5732.95" style={{opacity:0.44,fill:'url(#SVGID_00000014624763316685865110000007935624535304150955_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000034777287315935450150000011398037053529751179_" gradientUnits="userSpaceOnUse" x1="639.2729" y1="5771.6587" x2="639.8156" y2="5771.6587">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="639.27" y="5734.15" style={{opacity:0.44,fill:'url(#SVGID_00000034777287315935450150000011398037053529751179_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000008146146355038443660000015909233072267056809_" gradientUnits="userSpaceOnUse" x1="635.3821" y1="5770.4517" x2="635.9248" y2="5770.4517">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="635.38" y="5732.95" style={{fill:"url(#SVGID_00000008146146355038443660000015909233072267056809_)"}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000148623484088332629090000001145644933391974792_" gradientUnits="userSpaceOnUse" x1="627.6005" y1="5771.6587" x2="628.1431" y2="5771.6587">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="627.6" y="5734.15" style={{opacity:0.44,fill:'url(#SVGID_00000148623484088332629090000001145644933391974792_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000050652327416977947360000010134949890919191440_" gradientUnits="userSpaceOnUse" x1="623.7096" y1="5770.4517" x2="624.2522" y2="5770.4517">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="623.71" y="5732.95" style={{opacity:0.44,fill:'url(#SVGID_00000050652327416977947360000010134949890919191440_)'}} width="0.54" height="75.01"/>
                                    
                                        <linearGradient id="SVGID_00000002386122720613129710000001966377792963746466_" gradientUnits="userSpaceOnUse" x1="615.928" y1="5771.6587" x2="616.4706" y2="5771.6587">
                                        <stop offset="0" style={{stopColor:'#6C4DA1'}}/>
                                        <stop offset="0.1742" style={{stopColor:"#6C4DA0"}}/>
                                        <stop offset="0.33" style={{stopColor:'#65449C'}}/>
                                        <stop offset="0.5697" style={{stopColor:"#593795"}}/>
                                        <stop offset="0.6895" style={{stopColor:"#522E91"}}/>
                                        <stop offset="1" style={{stopColor:"#522E91"}}/>
                                    </linearGradient>
                                    
                                        <rect x="615.93" y="5734.15" style={{fill:"url(#SVGID_00000002386122720613129710000001966377792963746466_)"}} width="0.54" height="75.01"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <path style={{fill:"#FDD8AD"}} d="M671.37,5737.31c0,3.52-13.93,3.52-13.93,0v-9.12h13.93V5737.31z"/>
                    <path style={{fill:"#FDD8AD"}} d="M649.15,5711.82c0.76,2.84,0.01,5.51-1.69,5.96c-1.7,0.45-3.7-1.49-4.46-4.33     c-0.77-2.84-0.01-5.51,1.69-5.96C646.38,5707.04,648.38,5708.98,649.15,5711.82z"/>
                    <path style={{fill:"#FDD8AD"}} d="M680,5711.82c-0.77,2.84-0.01,5.51,1.69,5.96c1.7,0.45,3.7-1.49,4.46-4.33     c0.76-2.84,0.01-5.51-1.69-5.96C682.76,5707.04,680.77,5708.98,680,5711.82z"/>
                    <ellipse style={{fill:"#FDD8AD"}} cx="664.56" cy="5712.6" rx="17.38" ry="21.65"/>
                    <path style={{fill:"#FDD8AD"}} d="M673.02,5793.17c0.47-2.27-1.34-3.83-3.44-3.95c-0.06,0-0.12-0.01-0.18-0.01     c-1.27-0.08-2.26,0.92-2.89,1.87c-0.36,0.54-1.15,1.36-1.84,1.5c-0.05-0.02-0.1-0.03-0.15-0.06c-0.27-0.1-0.53-0.2-0.79-0.3     c-0.73-0.27-1.45-0.47-2.17-0.68c-0.67-0.19-1.26,0.25-1.6,0.76c-2,3.02,2.18,6.39,4.95,6.53     C668.12,5799.01,672.34,5796.44,673.02,5793.17z"/>
                    <path style={{fill:"#FDD8AD"}} d="M604.18,5768.01c0.23,1.47,1.59,2.49,2.89,3.24c2.57,1.48,5.38,2.56,8.28,3.2     c0.16,0.03,0.33,0.07,0.49,0.02c0.23-0.07,0.37-0.3,0.48-0.52c0.75-1.54,0.96-3.36,0.5-5.02c-0.46-1.66-1.6-3.15-3.14-3.96     c-0.94-0.5-2-0.74-3.05-0.88c-1.46-0.2-3.01-0.21-4.33,0.43C604.98,5765.16,603.96,5766.57,604.18,5768.01z"/>
                    <polygon style={{fill:"#FFFFFF"}} points="670.41,5787.15 675.15,5799.54 680.97,5797.37 676.53,5785.51    "/>
                    <path style={{fill:"#FFFFFF"}} d="M671.59,5743.73c0,0,0.46,18.32-1.43,21.92c-1.89,3.59-6.72,14.72-6.72,14.72s-5-5.65-6.2-11.81     c-1.21-6.16,0.52-28.13,0.52-28.13l6.66-1.4L671.59,5743.73z"/>
                    <polygon style={{fill:"#FFFFFF"}} points="671.59,5733.78 671.59,5746.64 664.41,5739.95 657.44,5747.08 657.49,5734.36      664.41,5739.03    "/>
                    <path style={{fill:"#7558A7"}} d="M664.26,5740.65l4.28,3.15l-3.38,1.14c0,0,1.9,8.22,1.72,15.58c-0.17,7.36-0.48,13.15-0.48,13.15     l-2.96,6.71c0,0-4.77-5.47-5.07-8.22c-0.31-2.75,5.86-27.09,5.86-27.09l-2.16-1.84L664.26,5740.65z"/>
                    <path style={{fill:"#6C4DA1"}} d="M648.83,5880.35l3.06,3.71l-3.16-3.85c0,0-11.04,10.82-12.49,11.54c-1.45,0.72-3.88,1.4-3.88,1.4     s0.49,0.93,0.89,1.3c0.4,0.37,11.54,0.43,13.72-0.91c2.18-1.34,2.58-3.71,2.58-3.71l8.15-3.44l-0.24-5"/>
                    <polygon style={{fill:"#FFFFFF"}} points="612.35,5776.16 617.16,5765.6 621.78,5768.26 616.5,5779.29    "/>
                    <g>
                        <path style={{fill:"#522E91"}} d="M657.96,5713c2.74,0,4.97-1.55,4.97-3.45c0-1.9-2.23-3.45-4.97-3.45s-4.97,1.55-4.97,3.45      C652.99,5711.45,655.22,5713,657.96,5713z M657.96,5706.82c2.34,0,4.24,1.22,4.24,2.73c0,1.5-1.9,2.73-4.24,2.73      s-4.24-1.22-4.24-2.73C653.72,5708.05,655.62,5706.82,657.96,5706.82z"/>
                        <path style={{fill:"#522E91"}} d="M671.16,5713c2.74,0,4.97-1.55,4.97-3.45c0-1.9-2.23-3.45-4.97-3.45s-4.97,1.55-4.97,3.45      C666.19,5711.45,668.42,5713,671.16,5713z M671.16,5706.82c2.34,0,4.24,1.22,4.24,2.73c0,1.5-1.9,2.73-4.24,2.73      c-2.34,0-4.24-1.22-4.24-2.73C666.91,5708.05,668.82,5706.82,671.16,5706.82z"/>
                    </g>
                    <path style={{fill:"#522E91"}} d="M662.82,5709.81c0.58-0.58,1.19-0.86,1.79-0.84c0.96,0.03,1.66,0.81,1.66,0.82l0.55-0.47     c-0.04-0.04-0.9-1.02-2.18-1.07c-0.82-0.03-1.6,0.32-2.34,1.05L662.82,5709.81z"/>
                    <polygon style={{fill:"#522E91"}} points="653.3,5709.91 653.41,5709.2 647.53,5708.25 647.41,5708.96    "/>
                    <polygon style={{fill:"#522E91"}} points="675.94,5709.91 681.82,5708.96 681.7,5708.25 675.82,5709.2    "/>
                </g>
                <g>
                    <polygon style={{fill:"#C83929"}} points="657.92,5875.03 649.49,5874.85 649.79,5872.63 658.08,5872.63    "/>
                    <polygon style={{fill:"#D4772D"}} points="657.8,5876.77 649.22,5876.69 649.55,5874.43 657.96,5874.55    "/>
                    <polygon style={{fill:"#F3D54F"}} points="657.69,5878.52 648.98,5878.38 649.29,5876.23 657.84,5876.3    "/>
                    <polygon style={{fill:"#6AA765"}} points="657.61,5879.83 648.74,5880.12 649.04,5877.99 657.73,5878.01    "/>
                    <polygon style={{fill:"#518EC4"}} points="657.46,5881.39 652.1,5882.73 648.71,5880.27 648.82,5879.53 657.61,5879.81    "/>
                </g>
                <polyline style={{fill:"#583E80"}} points="656.21,5881.75 652.07,5882.96 649.74,5881   "/>
                <g>
                    <polygon style={{fill:"#F3D54F"}} points="679.26,5879.54 672.27,5880.99 671.96,5880.19 671.83,5879.75 679.26,5879.54    "/>
                    <polygon style={{fill:"#6AA765"}} points="679.25,5879.84 672.61,5881.69 672.28,5881.01 671.99,5880.27 679.26,5879.57    "/>
                    <polygon style={{fill:"#518EC4"}} points="679.26,5881.41 673.9,5883.15 673.27,5882.55 672.87,5882.12 672.61,5881.69      679.25,5879.81    "/>
                </g>
                <polyline style={{fill:"#583E80"}} points="679.26,5881.41 676.64,5884.15 673.45,5882.9   "/>
                <path style={{fill:"#6C4DA1"}} d="M670.69,5879.78c-0.07,0.62-0.07,1.35,0.11,2.17c0.55,2.55,1.1,5.72,1.65,9.45    c0.55,3.73,4.67,14.31,6.77,12.37c2.86-2.64,5.04-9,5.04-9l-5-13.37l-2.41,2.32c0,0-3.02-0.14-4.03-1.68    c-0.42-0.65-0.75-1.51-0.99-2.3"/>
            </g>
            <g>
                <g>
                    <g>
                        <path style={{fill:"#63439C"}} d="M841.14,5884.52l-7.85-71.32l-23.38,59.94c10.17,2.83,23.35,6.54,23.35,6.54L841.14,5884.52z"/>
                        <polygon style={{fill:"#522E91"}} points="831.43,5883.34 841.14,5884.52 833.27,5879.69     "/>
                        <path style={{fill:"#7558A7"}} d="M809.92,5873.14l-2.56,7.27l-3.41,9.7l20.25,7.63l7.24-14.41l1.83-3.65      C833.27,5879.69,820.08,5875.97,809.92,5873.14z"/>
                    </g>
                    <polygon style={{fill:"#FFF6CC"}} points="733.23,5806.93 722.11,5887.07 824.2,5897.75 833.73,5808.84    "/>
                </g>
                <polygon style={{fill:"#FFFFFF"}} points="733.23,5806.93 720.46,5877.19 821.54,5886.51 833.73,5808.84   "/>
                <path id="SVGID_x5F_00000155855279929986533050000000666011483338953879_x5F_" style={{fill:"none"}} d="M726.21,5853.8l100.1,6.18"/>
                <text>		<textPath xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#SVGID_x5F_00000155855279929986533050000000666011483338953879_x5F_" startOffset="10.742%">
        <tspan style={{fill:"#EC008C", fontFamily:'Sansa', fontWeight:'bold', fontSize:'37.5421px'}}>2</tspan><tspan style={{fill:'#EC008C', fontFamily:'Sansa', fontWeight:'bold', fontSize:'38.0114px'}}>0</tspan><tspan style={{fill:'#EC008C', fontFamily:'Sansa', fontWeight:'bold', fontSize:'38.3843px'}}>21
        </tspan>		</textPath>
        </text>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="744.28" cy="5811.64" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M746.07,5811.01     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="757.16" cy="5811.64" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M758.95,5811.01     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="770.03" cy="5811.87" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M771.82,5811.23     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="782.91" cy="5812.4" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M784.7,5811.77     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="795.79" cy="5812.4" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M797.58,5811.77     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="808.66" cy="5812.81" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M810.45,5812.18     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
                <g>
                    <circle style={{fill:'#FEEBD6'}} cx="821.54" cy="5812.81" r="1.9"/>
                    <path style={{fill:'none',stroke:'#522E91',strokeWidth:0.8271,strokeMiterlimit:10}} d="M823.33,5812.18     c-0.66,0.26-1.16,0.71-1.79,0.63c-1.52-0.19-2.52-2.14-2.24-4.36c0.28-2.22,1.73-3.86,3.25-3.67c1.33,0.17,2.26,1.68,2.29,3.54"/>
                </g>
            </g>
            <path className="tree-big" style={{fill:'#7BC143'}} d="M167.24,5662.58c-41.43,0-75.02,33.58-75.02,75.02c0,41.43,33.58,75.02,75.02,75.02   c41.43,0,75.02-33.59,75.02-75.02C242.26,5696.16,208.67,5662.58,167.24,5662.58z"/>
            <path className="tree-small" style={{fill:'#A3D47B'}} d="M229.02,5716.97c-32.1,0-58.13,26.02-58.13,58.13c0,32.1,26.02,58.13,58.13,58.13   c32.1,0,58.13-26.02,58.13-58.13C287.14,5743,261.12,5716.97,229.02,5716.97z"/>
            </g>

            <g id="kite">
                <rect x="162.72" y="5809.2" style={{fill:"#9782BD"}} width="9.05" height="56.33"/>
                <rect x="225.51" y="5830.59" style={{fill:"#9782BD"}} width="7.01" height="43.65"/>
                <path id="kite-yarn" style={{fill:'none',stroke:'#FFFFFF',strokeWidth:1.3031,strokeLinecap:'round',strokeMiterlimit:10}}  d="M362 5568C362 5568 448.5 5651.55 475.01 5671.45C475.01 5671.45 554.75 5726.25 578.62 5683.41C578.62 5683.41 586.66 5670.72 574.05 5670.95C559.29 5671.22 573.77 5726.78 607.69 5768.88" />
                <path id="kite-path" fill="transparent" d="M447.5 5648C445.5 5647.17 441.5 5644.6 441.5 5641C441.5 5637.4 431.5 5633.5 426.5 5632C423.166 5630.5 417.2 5625.9 420 5619.5C417.667 5617.5 412.1 5613.7 408.5 5614.5C406.833 5614.67 402.9 5613.6 400.5 5608C398.1 5602.4 396.5 5598.67 396 5597.5C395.833 5596.33 394.1 5593.6 388.5 5592C385.167 5590.83 378.5 5588 378.5 5586C378.5 5583.5 379 5582.5 373 5577C368.2 5572.6 364.333 5571.17 363 5571L361 5567.5C362.333 5567.83 364.8 5568.6 364 5569C364.833 5570.5 366.4 5573.7 366 5574.5C365.6 5575.3 370.167 5579.5 372.5 5581.5L380.5 5584.5L386 5589C386.833 5590.83 388.4 5594.7 388 5595.5C387.6 5596.3 392.833 5600.17 395.5 5602L403.5 5605C404.5 5605.67 407 5607.9 409 5611.5C411 5615.1 412.167 5618 412.5 5619C413 5619.67 415.4 5621.3 421 5622.5C422.667 5623.17 426.3 5625.1 427.5 5627.5C428.167 5628.83 429.7 5631.5 430.5 5631.5L433 5633C433.833 5634 435.6 5636.6 436 5639C436.833 5640.33 438.8 5643 440 5643C441.2 5643 444.167 5643.33 445.5 5643.5L447.5 5648Z" stroke="transparent"/>
                <g id="kite-top">
                    <g>
                        <path style={{fill:"#4B2D90"}} d="M414.16,5495.95l-80.69-9.37c-3-0.35-5.68,2.14-5.99,5.57l-8.21,92.03    c-0.25,2.75,1.12,5.35,3.36,6.4l111.1,51.9c4.01,1.87,8.24-2.06,7.44-6.92L418.97,5501    C418.53,5498.29,416.57,5496.23,414.16,5495.95z"/>
                        <path style={{fill:'none',stroke:'#FFFFFF',strokeWidth:1.6402,strokeLinecap:'round',strokeMiterlimit:10}} d="M331.46,5578.43"/>
                        <path style={{fill:'none',stroke:'#FFFFFF',strokeWidth:1.6402,strokeLinecap:'round',strokeMiterlimit:10}} d="M428.7,5626.58"/>
                    </g>
                    <g>
                        <path style={{fill:"#FFFFFF"}} d="M377.8,5548.18c0.16-0.47,0.19-0.95,0.11-1.45c-0.08-0.5-0.26-0.95-0.54-1.36    c-0.34-0.5-0.73-0.88-1.19-1.16c-0.46-0.28-0.95-0.45-1.46-0.51c-0.52-0.07-1.05-0.03-1.59,0.1c-0.54,0.14-1.06,0.37-1.56,0.71    c-0.46,0.31-0.85,0.69-1.17,1.12c-0.31,0.43-0.53,0.89-0.66,1.39c-0.12,0.5-0.14,1.01-0.05,1.54c0.09,0.53,0.31,1.05,0.66,1.57    c0.34,0.51,0.75,0.88,1.21,1.12c0.47,0.24,0.98,0.37,1.53,0.38l-0.08,1.52c-0.14-0.01-0.35-0.03-0.63-0.08    c-0.28-0.05-0.6-0.16-0.96-0.31c-0.36-0.16-0.73-0.39-1.12-0.69c-0.39-0.3-0.76-0.7-1.1-1.21c-0.47-0.7-0.76-1.42-0.88-2.15    c-0.11-0.74-0.09-1.45,0.08-2.14c0.17-0.69,0.45-1.32,0.85-1.89c0.4-0.58,0.88-1.05,1.44-1.43c0.68-0.46,1.38-0.77,2.11-0.94    c0.73-0.17,1.44-0.19,2.13-0.07c0.69,0.12,1.34,0.38,1.96,0.79c0.61,0.41,1.15,0.95,1.61,1.63c0.39,0.58,0.67,1.23,0.82,1.94    c0.16,0.71,0.12,1.4-0.1,2.07L377.8,5548.18z"/>
                        <path style={{fill:"#FFFFFF"}} d="M383.46,5552.44l0.81,1.19l-6.3,4.26c-0.56,0.38-1.07,0.6-1.54,0.66    c-0.47,0.06-0.89,0.03-1.28-0.09c-0.38-0.12-0.71-0.31-0.99-0.55c-0.28-0.25-0.51-0.49-0.67-0.74c-0.42-0.62-0.62-1.26-0.59-1.9    c0.03-0.64,0.33-1.24,0.9-1.8l1.05,0.99c-0.29,0.29-0.46,0.61-0.51,0.94c-0.05,0.34,0.04,0.67,0.26,1c0.2,0.3,0.42,0.5,0.66,0.6    c0.24,0.1,0.48,0.14,0.74,0.11c0.25-0.03,0.51-0.1,0.77-0.23c0.26-0.13,0.52-0.28,0.77-0.45L383.46,5552.44z"/>
                        <path style={{fill:"#FFFFFF"}} d="M386.76,5565.69l-4.34,2.94c-0.28-0.13-0.56-0.29-0.84-0.5c-0.28-0.21-0.56-0.43-0.83-0.68    c-0.27-0.25-0.52-0.51-0.76-0.79c-0.24-0.28-0.45-0.55-0.63-0.82c-0.5-0.75-0.83-1.5-0.98-2.25c-0.15-0.75-0.15-1.47,0.01-2.16    c0.15-0.69,0.44-1.33,0.85-1.92c0.42-0.59,0.94-1.1,1.56-1.52c0.65-0.44,1.34-0.74,2.06-0.89c0.72-0.15,1.42-0.17,2.11-0.05    c0.69,0.12,1.34,0.38,1.96,0.79c0.62,0.4,1.16,0.94,1.61,1.61c0.55,0.81,0.9,1.55,1.04,2.2c0.14,0.65,0.16,1.27,0.04,1.86    l-1.48-0.23c0.1-0.6,0.08-1.15-0.05-1.65c-0.14-0.5-0.36-0.97-0.66-1.42c-0.34-0.5-0.74-0.9-1.2-1.18    c-0.46-0.28-0.95-0.46-1.46-0.54c-0.51-0.08-1.03-0.05-1.57,0.08c-0.53,0.13-1.04,0.36-1.52,0.69c-0.5,0.34-0.9,0.74-1.21,1.2    c-0.31,0.46-0.52,0.95-0.63,1.48c-0.11,0.52-0.11,1.05-0.01,1.58c0.1,0.54,0.32,1.05,0.65,1.54c0.29,0.42,0.6,0.8,0.93,1.12    c0.33,0.32,0.67,0.57,0.99,0.73l2.45-1.66l-1.32-1.95l1.12-0.76L386.76,5565.69z"/>
                        <path style={{fill:"#FFFFFF"}} d="M391.3,5572.4l1.06,1.57c0.16,0.23,0.33,0.45,0.52,0.65c0.19,0.2,0.4,0.36,0.62,0.47    c0.22,0.11,0.46,0.16,0.72,0.14c0.26-0.02,0.54-0.13,0.84-0.33c0.3-0.2,0.5-0.42,0.62-0.65c0.11-0.23,0.16-0.48,0.14-0.72    c-0.02-0.24-0.09-0.49-0.21-0.75c-0.12-0.25-0.25-0.5-0.41-0.73l-1.06-1.57L391.3,5572.4z M394.37,5568.58l2.1,3.1    c0.38,0.56,0.62,1.08,0.71,1.55c0.09,0.47,0.09,0.89,0,1.26c-0.09,0.37-0.26,0.7-0.5,0.98c-0.24,0.28-0.49,0.52-0.77,0.7    c-0.28,0.19-0.59,0.32-0.92,0.4c-0.33,0.08-0.66,0.09-1,0.05c-0.34-0.04-0.67-0.15-1-0.32c-0.33-0.17-0.62-0.41-0.88-0.72    l-2.33,5.22l-1.01-1.49l2.37-4.87l-0.9-1.33l-3.88,2.63l-0.81-1.19L394.37,5568.58z"/>
                        <path style={{fill:"#FFFFFF"}} d="M396.35,5577.78l0.76,1.12l-5.9,3.99l-0.76-1.12L396.35,5577.78z M398.29,5576.08    c0.22-0.15,0.47-0.2,0.74-0.15c0.27,0.05,0.48,0.19,0.63,0.42c0.15,0.22,0.2,0.47,0.15,0.74c-0.05,0.27-0.19,0.48-0.42,0.63    c-0.22,0.15-0.47,0.2-0.74,0.15c-0.27-0.05-0.48-0.19-0.64-0.42c-0.15-0.22-0.2-0.47-0.15-0.74    C397.93,5576.45,398.07,5576.24,398.29,5576.08"/>
                        <path style={{fill:"#FFFFFF"}} d="M400.18,5578.88c0.22-0.15,0.47-0.2,0.74-0.15c0.27,0.05,0.48,0.19,0.63,0.42    c0.15,0.22,0.2,0.47,0.15,0.74c-0.05,0.27-0.19,0.48-0.42,0.63c-0.22,0.15-0.47,0.2-0.74,0.15c-0.27-0.05-0.48-0.19-0.63-0.41    c-0.15-0.22-0.2-0.47-0.15-0.74C399.82,5579.25,399.96,5579.04,400.18,5578.88 M399,5581.69l-6.75,4.56    c-0.15,0.1-0.35,0.22-0.61,0.37c-0.26,0.14-0.54,0.24-0.85,0.28c-0.3,0.04-0.62,0-0.95-0.13c-0.33-0.13-0.65-0.42-0.95-0.87    c-0.08-0.12-0.16-0.25-0.22-0.38c-0.06-0.13-0.11-0.27-0.15-0.41l1.12-0.58c0.02,0.08,0.05,0.17,0.08,0.27    c0.04,0.1,0.08,0.18,0.13,0.26c0.21,0.31,0.45,0.45,0.73,0.44c0.28-0.01,0.6-0.14,0.98-0.4l6.69-4.53L399,5581.69z"/>
                        <path style={{fill:"#FFFFFF"}} d="M400.1,5583.31l0.76,1.12l-0.91,0.61l0.02,0.02c0.41-0.07,0.83,0,1.26,0.22    c0.43,0.22,0.8,0.55,1.11,1.01c0.19,0.28,0.33,0.58,0.42,0.9c0.09,0.32,0.11,0.64,0.06,0.96c-0.05,0.32-0.18,0.63-0.39,0.94    c-0.21,0.31-0.51,0.6-0.9,0.86l-3.79,2.57l-0.76-1.12l3.48-2.36c0.27-0.19,0.48-0.38,0.63-0.59c0.14-0.21,0.23-0.41,0.27-0.62    c0.04-0.21,0.03-0.41-0.03-0.61c-0.05-0.2-0.14-0.38-0.26-0.56c-0.16-0.23-0.34-0.42-0.55-0.57c-0.21-0.15-0.45-0.24-0.71-0.28    c-0.26-0.04-0.55-0.02-0.85,0.07c-0.31,0.09-0.63,0.24-0.97,0.47l-3.03,2.05l-0.76-1.12L400.1,5583.31z"/>
                        <path style={{fill:"#FFFFFF"}} d="M404.68,5590.1l0.71,1.04l-0.92,0.62l0.02,0.02c0.1-0.03,0.25-0.04,0.44-0.02    c0.2,0.02,0.4,0.07,0.62,0.16c0.22,0.09,0.44,0.21,0.67,0.38c0.23,0.17,0.43,0.38,0.61,0.65c0.3,0.44,0.45,0.87,0.48,1.29    c0.02,0.42-0.09,0.85-0.33,1.29c0.49-0.06,0.95,0.03,1.37,0.25c0.42,0.23,0.75,0.52,0.99,0.87c0.31,0.46,0.49,0.88,0.53,1.27    c0.05,0.39,0,0.75-0.13,1.08c-0.13,0.33-0.33,0.63-0.6,0.91c-0.27,0.27-0.57,0.52-0.9,0.74l-3.43,2.32l-0.76-1.12l3.29-2.22    c0.22-0.15,0.43-0.31,0.62-0.48c0.19-0.17,0.33-0.35,0.43-0.55c0.1-0.2,0.14-0.41,0.12-0.64c-0.02-0.23-0.12-0.48-0.3-0.76    c-0.36-0.54-0.79-0.81-1.28-0.82c-0.49-0.01-0.99,0.17-1.52,0.52l-3.22,2.18l-0.76-1.12l3.09-2.09c0.28-0.19,0.53-0.38,0.74-0.56    c0.21-0.19,0.37-0.38,0.48-0.58c0.11-0.2,0.16-0.42,0.15-0.64c-0.01-0.23-0.11-0.48-0.3-0.75c-0.13-0.2-0.3-0.37-0.51-0.5    c-0.21-0.13-0.43-0.22-0.69-0.25c-0.25-0.04-0.53-0.01-0.83,0.07c-0.3,0.08-0.62,0.24-0.95,0.46l-3.06,2.07l-0.76-1.12    L404.68,5590.1z"/>
                        <path style={{fill:"#FFFFFF"}} d="M409.39,5603.41c-0.3,0.2-0.54,0.43-0.73,0.7c-0.19,0.26-0.32,0.54-0.39,0.82    c-0.07,0.29-0.07,0.58-0.01,0.9c0.06,0.31,0.19,0.61,0.39,0.91c0.2,0.3,0.44,0.53,0.7,0.7c0.27,0.17,0.55,0.28,0.84,0.32    c0.29,0.04,0.59,0.03,0.91-0.05c0.31-0.08,0.62-0.22,0.92-0.42c0.3-0.2,0.54-0.43,0.74-0.7c0.19-0.26,0.32-0.54,0.39-0.82    c0.07-0.29,0.07-0.59,0.01-0.9c-0.06-0.31-0.19-0.61-0.39-0.91c-0.2-0.3-0.44-0.53-0.7-0.7c-0.27-0.17-0.55-0.28-0.84-0.32    c-0.29-0.05-0.59-0.03-0.91,0.05C410,5603.07,409.69,5603.21,409.39,5603.41 M408.58,5602.22c0.43-0.29,0.89-0.48,1.37-0.57    c0.48-0.09,0.96-0.09,1.43,0c0.47,0.09,0.92,0.27,1.34,0.54c0.42,0.27,0.78,0.63,1.08,1.07c0.3,0.44,0.49,0.91,0.59,1.4    c0.1,0.49,0.1,0.97,0.01,1.45c-0.09,0.47-0.27,0.92-0.53,1.33c-0.26,0.41-0.61,0.77-1.04,1.06c-0.43,0.29-0.89,0.48-1.38,0.58    c-0.49,0.09-0.96,0.09-1.43,0c-0.47-0.09-0.91-0.28-1.33-0.55c-0.42-0.27-0.78-0.63-1.08-1.07c-0.3-0.44-0.49-0.9-0.59-1.4    c-0.1-0.49-0.1-0.97-0.01-1.44c0.09-0.47,0.26-0.91,0.53-1.33C407.8,5602.87,408.15,5602.51,408.58,5602.22"/>
                        <path style={{fill:"#FFFFFF"}} d="M416.63,5607.75l0.76,1.12l-0.91,0.61l0.02,0.02c0.41-0.07,0.83,0,1.26,0.22    c0.43,0.22,0.8,0.55,1.11,1.01c0.19,0.28,0.33,0.58,0.42,0.9c0.09,0.32,0.11,0.64,0.06,0.96c-0.05,0.32-0.18,0.63-0.39,0.94    c-0.21,0.31-0.51,0.6-0.9,0.86l-3.79,2.57l-0.76-1.12l3.48-2.36c0.27-0.18,0.48-0.38,0.63-0.59c0.14-0.21,0.23-0.41,0.27-0.62    c0.04-0.21,0.03-0.41-0.03-0.61c-0.05-0.2-0.14-0.38-0.26-0.56c-0.16-0.23-0.34-0.42-0.55-0.57c-0.21-0.15-0.45-0.24-0.71-0.28    c-0.26-0.04-0.55-0.02-0.85,0.07c-0.31,0.09-0.63,0.24-0.97,0.47l-3.03,2.05l-0.76-1.12L416.63,5607.75z"/>
                        <path style={{fill:"#FFFFFF"}} d="M418.12,5620.74c0.2,0.3,0.44,0.53,0.7,0.7c0.27,0.17,0.55,0.28,0.84,0.32    c0.29,0.05,0.59,0.03,0.91-0.05c0.31-0.08,0.62-0.22,0.92-0.42c0.3-0.2,0.54-0.43,0.74-0.7c0.19-0.26,0.32-0.54,0.39-0.83    c0.07-0.29,0.07-0.58,0.01-0.9c-0.06-0.31-0.19-0.61-0.39-0.91c-0.2-0.3-0.44-0.53-0.7-0.7c-0.27-0.17-0.55-0.28-0.84-0.32    c-0.29-0.05-0.59-0.03-0.91,0.05s-0.62,0.22-0.92,0.42c-0.3,0.2-0.54,0.43-0.74,0.7c-0.19,0.26-0.32,0.54-0.38,0.82    c-0.07,0.29-0.07,0.59-0.01,0.9C417.79,5620.14,417.92,5620.44,418.12,5620.74 M419.29,5624.41l-0.76-1.12l0.85-0.57l-0.02-0.03    c-0.46,0-0.91-0.13-1.34-0.39c-0.43-0.26-0.78-0.59-1.05-0.99c-0.31-0.46-0.51-0.92-0.6-1.4c-0.09-0.47-0.09-0.94,0.02-1.39    c0.1-0.45,0.3-0.87,0.58-1.27c0.28-0.39,0.63-0.74,1.07-1.04c0.44-0.3,0.9-0.5,1.37-0.62c0.47-0.12,0.94-0.13,1.39-0.06    c0.45,0.07,0.89,0.24,1.29,0.5c0.41,0.26,0.77,0.62,1.07,1.08c0.29,0.42,0.47,0.88,0.54,1.38c0.07,0.49,0.02,0.94-0.15,1.35    l0.02,0.03l4.36-2.95l0.76,1.12L419.29,5624.41z"/>
                    </g>
                    <g>
                        <path style={{fill:"#FFFFFF"}} d="M355.72,5521.47c-0.21-2.15,0.35-3.68,1.73-4.62c1.39-0.94,2.95-0.93,4.02-0.73    c3.59,0.69,5.72,3.26,6.34,4.19c0.48,0.73,0.91,1.55,1.31,2.56c0.82,2.1,4.83,2.24,7.47,0.46c2.24-1.52,3.38-4.32,2.65-7.19    c-0.25-0.96-0.68-1.87-1.24-2.7c-2.45-3.63-7.66-6.18-11.37-3.67c-3.32,2.25-2.18,5.35-1.93,6.82c0.04,0.23-0.02,0.37-0.22,0.26    c-7.56-7.96-14.05-9.18-16.61-7.44c-1.62,1.09-2.5,5.08-2.93,6.9c-0.12,0.5,0.14,0.48,0.62,0.59c1.7,0.37,5.4,1.38,8.73,4.19    C354.55,5521.31,355.75,5522.4,355.72,5521.47z M369.49,5526.6c-0.16-0.3-0.36-0.42-0.48-0.04c-0.19,0.57-0.51,1.56-1.51,2.24    c-1.01,0.68-2.23,0.76-3.44,0.53c-2.7-0.52-4.44-2.35-5.76-3.57c0.86,0.99,1.48,2.17,1.61,3.44c0.91,2.46,1.44,5.24,0.99,7.56    c-0.46,2.31-1.66,3.77-4.67,4.44c-0.61,0.13-1.67,0.25-1.96,0.44c-0.09,0.06-0.09,0.14-0.04,0.21c0.19,0.28,0.52,0.5,1.25,0.64    c4.05,0.77,8.32-1.16,9.98-2.27C370.26,5536.97,370.69,5528.82,369.49,5526.6z"/>
                        <path style={{fill:"#FFFFFF"}} d="M353.77,5537.97c0.71-3.6-1.02-5.53-0.9-6.17c0.01-0.02,0.01-0.04,0.03-0.05    c0.02-0.01,0.05-0.02,0.09-0.01c0.32,0.06,1.45,1.24,3.07,1.55c0.77,0.15,1.6,0.1,2.37-0.41c1.78-1.2,1.51-3.49,1.49-3.69    c-0.13-1.27-0.76-2.44-1.61-3.44c-1.7-1.98-4.39-3.46-6.59-1.97c-1.45,0.98-1.95,2.45-1.79,3.33c0.03,0.21,0.35,1.07-0.11,0.75    c-0.34-0.25-0.64-0.57-0.96-0.85c-0.28-0.24-4.99-4.45-8.11-2.34c-1.71,1.15-1.78,4.14-1.82,4.39c-0.03,0.2-0.14,0.46-0.26,0.54    c-0.22,0.15-0.55,0.37-1.22,0.82c-2.21,1.5-2.95,9.35-0.41,13.11c0.97,1.44,3.06,0.82,3.12,0.81c1.19-0.23,2.61-1.27,3.25-1.67    c0.64-0.39,1.03-0.62,1.81-0.64c0.51-0.01,1.81,0.31,3.35,0.15c1.13-0.12,2.28-0.41,3.23-1.05    C352.97,5540.35,353.54,5539.18,353.77,5537.97z"/>
                    </g>
                </g>
                <path style={{fill:"#EC008C"}} d="M442.22,5651.46l5.61-1.32c0.35-0.08,0.65-0.34,0.81-0.69l2.7-5.76c0.41-0.88,1.49-0.98,2.02-0.17   l2.21,3.37c0.53,0.8,0.14,1.95-0.72,2.16l-5.61,1.33c-0.35,0.08-0.65,0.34-0.81,0.69l-2.7,5.76c-0.41,0.88-1.49,0.98-2.02,0.17   l-2.21-3.37C440.98,5652.81,441.37,5651.66,442.22,5651.46z"/>
                <path style={{fill:"#EC008C"}} d="M452.31,5660.58l5.33-2.37c0.33-0.15,0.59-0.45,0.7-0.84l1.82-6.19c0.28-0.95,1.33-1.24,1.96-0.55   l2.67,2.91c0.63,0.69,0.43,1.9-0.39,2.26l-5.33,2.37c-0.33,0.15-0.59,0.45-0.7,0.84l-1.82,6.19c-0.28,0.95-1.33,1.24-1.96,0.55   l-2.67-2.91C451.29,5662.15,451.5,5660.94,452.31,5660.58z"/>
                <path style={{fill:"#EC008C"}} d="M464.08,5670.75l4.98-3.21c0.31-0.2,0.53-0.55,0.59-0.94l1.03-6.42c0.16-0.98,1.16-1.45,1.87-0.87   l3.01,2.44c0.71,0.58,0.66,1.81-0.11,2.3l-4.98,3.21c-0.31,0.2-0.53,0.55-0.59,0.94l-1.03,6.42c-0.16,0.98-1.16,1.45-1.87,0.87   l-3.01-2.44C463.26,5672.47,463.32,5671.24,464.08,5670.75z"/>
                <path style={{fill:"#EC008C"}} d="M475.96,5679.57l4.48-4.07c0.28-0.25,0.44-0.63,0.45-1.04l0.13-6.53c0.02-1,0.94-1.64,1.73-1.19   l3.31,1.87c0.79,0.44,0.9,1.67,0.21,2.29l-4.48,4.07c-0.28,0.25-0.44,0.63-0.45,1.04l-0.13,6.53c-0.02,1-0.94,1.64-1.73,1.19   l-3.31-1.87C475.39,5681.42,475.27,5680.19,475.96,5679.57z"/>
            </g>
        </>
    )
}


