import React, { useEffect} from "react";
import { gsap } from "gsap";

export default function Section7Figures() {
  useEffect(() => {
    function thousandDelimeter(n) {
			return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");	
		}

    gsap.set('.rainbow-7-section', {
      x: -30
    })
    gsap.set('.cloud-1', {
      x: -30
    })


    gsap.from('.lj-numbers', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.lj-numbers',
    })

    gsap.from('.lj-numbers-thousand', {
			textContent: 0,
			duration: 2,
			ease: "power3.inOut",
			snap: { textContent: 10 },
			scrollTrigger: '.lj-numbers-thousand',
			onUpdate: function() {
				this.targets().forEach(target => {
					const val = gsap.getProperty(target, "textContent");
					target.textContent = thousandDelimeter(val);
				});
			}
		})
  }, [])

  useEffect(() => {
    gsap.set('#flag', {rotation:'-=30', transformOrigin: 'bottom', y: -10, x: -20})
    gsap.timeline({repeat:-1})
   .to('#flag', 
      { duration: 3, transformOrigin: 'bottom center',  rotation:"+=60", ease:'none'}   
    )
   .to('#flag', 
      { duration: 3, transformOrigin: 'bottom center',  rotation:"-=60", ease:'none'  }   
    )
  })

  return (
      <>
      <g>
      <path className="cloud-1" id="cloud-111"
        style={{
          fill: "#e0d9f4",
        }}
        d="M895.64 5034.38c0-19.94-18.5-36.11-41.33-36.11h-.16c-1.79-18.5-19.53-33.03-41.17-33.03-13.9 0-26.19 6-33.68 15.19-5.97-13.52-20.98-23.12-38.56-23.12-22.82 0-41.33 16.17-41.33 36.11 0 1.68.14 3.33.4 4.95-.97-.06-1.94-.1-2.93-.1-22.82 0-41.33 16.17-41.33 36.11 0 19.94 18.5 36.11 41.33 36.11h159.44v-.04c21.9-.93 39.32-16.72 39.32-36.07z"
      />
      <defs>
        <path
          id="a"
          d="m812.19 5074.24-82.08-264.99 153.45-58.17 98.94 255.73z"
        />
      </defs>
      <clipPath id="b">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#a"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g id='flag'>
        <path
          style={{
            fill: "#9ea6ad",
          }}
          d="m685.45 4894.36-2.62-.02-.65-76.35 2.63.02z"
        />
        <path
          style={{
            fill: "#ff3d32",
          }}
          d="M713.09 4811.47c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85c-.01 0-8.94 5.21-30.01-.23z"
        />
        <path
          style={{
            fill: "#fc853a",
          }}
          d="M713.15 4818.3c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85c-.01 0-8.94 5.21-30.01-.23z"
        />
        <path
          style={{
            fill: "#f5d44e",
          }}
          d="M713.2 4825.13c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85s-8.93 5.21-30.01-.23z"
        />
        <path
          style={{
            fill: "#068866",
          }}
          d="M713.26 4831.96c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85c0 .01-8.93 5.21-30.01-.23z"
        />
        <path
          style={{
            fill: "#6b9bda",
          }}
          d="M713.32 4838.8c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85c-.01 0-8.94 5.21-30.01-.23z"
        />
        <path
          style={{
            fill: "#5041a4",
          }}
          d="M713.38 4845.63c-21.08-5.44-30.96-.24-30.96-.24l.06 6.85s9.89-5.2 30.96.24c21.08 5.44 30.01.23 30.01.23l-.06-6.85c-.01 0-8.94 5.21-30.01-.23z"
        />
        <path d="m684.91 4810.23 15.67 20.76-14.64 20.07 4.12-.74 14.25-19.3-16.23-21.48s-2.37.41-3.17.69z" />
        <path
          style={{
            fill: "#613915",
          }}
          d="m685.94 4851.07 14.66-20.11-15.69-20.73-2.78 1 14.73 19.71-14.42 19.8z"
        />
        <path
          style={{
            fill: "#74d7ee",
          }}
          d="m682.44 4850.74-.04-5.16 10.83-14.67-11.08-14.93-.02-4.75 14.74 19.71z"
        />
        <path
          style={{
            fill: "#ffafc8",
          }}
          d="m682.35 4840.55.05 5.03 10.83-14.67-11.08-14.93.04 5.03 7.38 9.88z"
        />
        <path
          style={{
            fill: "#fff",
          }}
          d="m682.38 4840.55 7.22-9.67-7.38-9.87z"
        />
        <path
          style={{
            fill: "#613915",
          }}
          d="m682.47 4852.24-.03-1.5 3.5.32c0 .01-2.51.67-3.47 1.18z"
        />
      </g>

      
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M597.28 4861.12v139.92h101.37v-139.92"
      />
      <path
        style={{
          fill: "#80d6f7",
        }}
        d="M630.22 4921.15h28.55v14.54h-28.55zM661.38 4921.15h28.55v14.54h-28.55zM603.43 4921.15h13.92v14.54h-13.92zM630.22 4872.18h28.55v14.54h-28.55zM661.38 4872.18h28.55v14.54h-28.55zM603.43 4872.18h13.92v14.54h-13.92zM603.43 4896.66h21.26v14.54h-21.26zM627.3 4896.66h28.55v14.54H627.3zM665.99 4896.66h28.55v14.54h-28.55zM603.43 4945.63h21.26v14.54h-21.26zM627.3 4945.63h28.55v14.54H627.3zM665.99 4945.63h28.55v14.54h-28.55z"
      />
     
      <path
        style={{
          fill: "none",
        }}
        d="M232.03 4789.07h169.09v67.29H232.03z"
      />
      <text transform="translate(238.806 4830.316)">
        <tspan
          x={0}
          y={0}
          className="lj-numbers-thousand"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 55,
          }}
        >
          {"1000"}
        </tspan>
        <tspan
          x={3.59}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"dagen Kansrijke Start"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M696.96 4899.5h169.09v67.29H696.96z"
      />
      <text transform="translate(773.696 4918.252)">
        <tspan
          x={0}
          y={0}
          className="lj-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"5"}
        </tspan>
        <tspan
          x={-16.77}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"oktober"}
        </tspan>
        <tspan
          x={-49.77}
          y={34}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Diversity Day"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M459.48 5060.07h189.46v67.29H459.48z"
      />
      <text transform="matrix(.9431 0 0 1 496.49 5070.57)">
        <tspan
          x={0}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Brandbrief Jeugd "}
        </tspan>
        <tspan
          x={-33.14}
          y={17}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"overhandigd aan Blokhuis"}
        </tspan>
      </text>
      <path
        style={{
          fill: "#522e91",
        }}
        d="M333.52 5042.73h18.52v8.23h-18.52v-8.23M317.66 5050.96h50.25v5.51h-50.25zM431.11 5042.73v-105.35a6.41 6.41 0 0 0-6.41-6.41H264.23a6.41 6.41 0 0 0-6.41 6.41v105.35h173.29z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M370.69 4939.97H265.81v95.09h104.88v-.03l.01.03h52.45v-95.09z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M265.81 4939.97h157.34v95.08H265.81z"
      />
      <defs>
        <path id="g" d="M344.16 4987.52h78.67v47.54h-78.67z" />
      </defs>
      <clipPath id="h">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#g"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath: "url(#h)",
        }}
      >
        <path
          style={{
            fill: "#dcd5e9",
          }}
          d="M393.93 5071.26c1.07-1.08 2.57-.67 3.4.42.02.03.05.06.07.1.5.66.32 1.56.02 2.25-.17.39-.33 1.12-.15 1.55.02.02.05.04.09.06.15.11.3.21.45.32.41.3.78.61 1.15.93.35.29.32.77.16 1.15-.93 2.19-4.28 1.15-5.36-.3-1.25-1.7-1.37-4.92.17-6.48z"
        />
      </g>
      <defs>
        <path id="i" d="M266.01 4988.07h49.47v46.96h-49.47z" />
      </defs>
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M316.58 5021.92c0-13.79 12.75-24.97 28.49-24.97 15.73 0 28.49 11.18 28.49 24.97"
      />
      <path
        style={{
          fill: "#552725",
        }}
        d="m351.54 4998.36-14.13 2.86c-5.77 0-9.69-3.04-9.69-8.82s4.73-16.57 10.5-16.57h15.91c5.78 0 11.97 9.56 11.97 15.34l-2.24 10.77c.01 5.79-6.33-2.62-12.32-3.58z"
      />
      <path
        style={{
          fill: "#fcce98",
        }}
        d="M337.98 4999.97c0 3.82 10.36 3.82 10.36 0v-9.9h-10.36v9.9z"
      />
      <path
        style={{
          fill: "#fcce98",
        }}
        d="M340.09 4995.24c.31.08.62.15.93.22 8.65 1.78 16.82-4.72 17.44-13.52l1.16-14.23c0-8.47-6.73-15.51-15.2-15.65-.31-.01-.62-.01-.93-.01-8.51.01-15.27 6.9-15.11 15.41.11 5.71-3.21 10.75-2.8 15.68.52 6.16 8.53 10.55 14.51 12.1z"
      />
      <path
        style={{
          fill: "#552725",
        }}
        d="M335.05 4947.83c-7.89-2.59-13.14 5.51-15.19 11.91-3.68 11.46-3.92 32.98 1.52 43.71.31.62 1.87 1.8 2.45 2.17.73.47 1.57.81 2.43 1 2.84.64 5.72 1.1 8.62 1.37-4.32-6.89-6.45-24.18-6.28-32.29.18-8.59 2.56-14.88 8.43-21.04.14-.14 5.48 2.44 6.04 2.69 3.12 1.34 5.89 3.38 8.58 5.42 2.33 1.77 3.5 3 4.56 5.77.95 2.48 1.59 5.09 1.92 7.73.32 2.64.33 5.32.01 7.96-.22 1.84-.11 3.67-.35 5.48-.1.79-.27 16.82-.85 17.05 2.74-1.09 5.37 1.27 7.83-.34 1.24-.81 2.47-1.72 3.2-3 .91-1.61.04-17.73-.02-19.58-.25-7.86-.8-15.86-3.69-23.17-3.76-9.49-11.62-16.93-22.23-16.63-2.8.07-5.39 1.48-6.98 3.79z"
      />
      <path
        style={{
          fill: "#552725",
        }}
        d="M327.26 5006.62c2.84.64 5.72 1.1 8.62 1.37-4.32-6.89-6.45-24.18-6.28-32.29.18-8.59 2.56-14.88 8.43-21.04l-13.01.06"
      />
      <ellipse
        transform="rotate(-87.725 360.613 4975.12)"
        style={{
          fill: "#fcce98",
        }}
        cx={360.51}
        cy={4974.97}
        rx={5.86}
        ry={2.75}
      />
      <path
        style={{
          fill: "#f9b2dc",
        }}
        d="M383.76 5011.22h39.39v23.8h-39.39z"
      />
      <path
        style={{
          fill: "#a896c8",
        }}
        d="M265.81 5011.22h39.39v23.8h-39.39z"
      />
      <path
        style={{
          fill: "#fcce98",
        }}
        d="M344.37 5011.22h39.39v23.8h-39.39z"
      />
      <path
        style={{
          fill: "#a3d47b",
        }}
        d="M304.98 5011.22h39.39v23.8h-39.39z"
      />
      <defs>
        <path id="j" d="M307.32 5014.8h30.33v20.26h-30.33z" />
      </defs>
      <clipPath id="k">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#j"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath: "url(#k)",
        }}
      >
        <path
          style={{
            fill: "#7558a7",
          }}
          d="M324.17 5028.32h-4.31a2.85 2.85 0 0 1-2.84-2.84 2.85 2.85 0 0 1 2.84-2.84h4.31a2.85 2.85 0 0 1 2.84 2.84c.01 1.56-1.27 2.84-2.84 2.84z"
        />
        <path
          style={{
            fill: "#a896c8",
          }}
          d="M313.8 5045.35c-.44.05-.9-.02-1.32-.18-.02-.01-.04-.01-.06-.02h-.01c-.04 0-.07 0-.11-.01l-.34 2.2c-.08.53.17 1.06.63 1.34l.28.17c.37.22.84-.04.85-.48l-.12-2.55.2-.47z"
        />
        <path
          style={{
            fill: "#fff",
          }}
          d="M320.26 5030.44c0 .98 2.66.98 2.66 0v-2.54h-2.66v2.54z"
        />
        <path
          style={{
            fill: "#a896c8",
          }}
          d="m320.37 5028.92.25.06c2.34.48 4.56-1.28 4.72-3.66l.31-3.85c0-2.29-1.82-4.2-4.12-4.24h-.25c-2.31 0-4.13 1.87-4.09 4.17.03 1.55-.87 2.91-.76 4.25.15 1.66 2.32 2.85 3.94 3.27z"
        />
        <path
          style={{
            fill: "#fff",
          }}
          d="M328.19 5033.07c-.26-1.12-1.81-3.17-3.26-3.85-.05.02-.09.02-.14.01l-3.38-.72-4.05.53c-.15.02-.31.06-.48.11-1.27.43-2.15 1.6-2.27 2.94l-1.09 7.01-1.2 5.89-.06.37h1.58l1.93-4.71.32-1.41 12.09-.17"
        />
        <path
          style={{
            fill: "#7558a7",
          }}
          d="M319.28 5016.08c-2.14-.7-3.56 1.49-4.11 3.22-1 3.1-.72 6.59.75 9.5.09.17.18.34.32.46.18.16.42.22.66.27.77.17 1.55.3 2.33.37-1.17-1.87-1.75-4.08-1.7-6.28.05-2.33.69-4.03 2.28-5.7.04-.04 1.48.66 1.64.73.85.36 1.59.92 2.32 1.47.63.48.95.81 1.24 1.56a8.887 8.887 0 0 1 .52 4.25c-.06.5-.03.99-.09 1.48-.03.21-.3.72-.46.78.74-.3 1.45.34 2.12-.09.34-.22.67-.46.87-.81.25-.44.24-.97.22-1.47-.07-2.13-.22-4.29-1-6.27-1.02-2.57-3.15-4.58-6.02-4.5-.76.03-1.46.41-1.89 1.03z"
        />
        <path
          style={{
            fill: "#a896c8",
          }}
          d="M319.97 5029.77c0 1.03 2.81 1.03 2.81 0v-2.68h-2.81v2.68z"
        />
        <path
          style={{
            fill: "#e8811a",
          }}
          d="M330 5029.81c3.24-.22 3.86-.96 4.95-.84 1.35.14 1.74.36 2.62 1.26 1.42 1.45 1.67 3.36.19 4.83-1.97 1.95-5.02 1.65-6.07 1.53-1.83-.2-4.01-.29-5.71-.63-.5-.1-.91-1.91-.98-2.32-.19-1.02 1.77-1.79 2.19-2.67.13-.28 1.5-1.07 2.81-1.16z"
        />
        <path
          style={{
            fill: "#f99d31",
          }}
          d="M322.48 5030.62c0 .22 1.13.96 1.13.96s.09.57.13.76c.06.26.15.54.28.79.5.93 2.1 1.53 3.05 1.71 1.36.25 1.74-.71 2.17-1.94.2-.58.17-1.19-.02-1.75-.17-.51-.6-.91-.68-1.44-.09-.6-.11-1.68-.37-1.79-.26-.11-1.08 1.73-1.08 1.73s-.28.05-.73.23c-.69.27-1.47.42-2.16.65-.23-.07-1.72-.13-1.72.09z"
        />
        <path
          style={{
            fill: "none",
            stroke: "#e6e6e6",
            strokeWidth: 0.342,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
          }}
          d="M325.55 5033.84c.19-.14.83-.13 1.32.14"
        />
        <path
          style={{
            fill: "none",
            stroke: "#e6e6e6",
            strokeWidth: 0.3528,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
          }}
          d="M328.39 5032.68c-.35.21-.54.54-.53.94"
        />
        <path
          style={{
            fill: "#fab15a",
          }}
          d="M337.75 5035.05c-1.97 1.95-5.02 1.65-6.07 1.53-1.83-.2-4.01-.29-5.71-.63-.5-.1-3.05-1.14-2.2-1.92.38-.35 1.34.12 2.6.42 1.28.3 2.69.7 4.26.71 3.96.07 7.91-.88 7.12-.11z"
        />
      </g>
      <path
        style={{
          fill: "#fde5f3",
        }}
        d="m366.27 5030.65-3.4-.72-3.2.41v2.91h6.6z"
      />
      <path
        style={{
          fill: "#fde5f3",
        }}
        d="M360.6 5030.07c0 1.15 4.51 1.15 4.51 0v-2.97h-4.51v2.97zM367.79 5023.1c-.25.93 0 1.79.55 1.94.55.15 1.2-.48 1.44-1.41.25-.93 0-1.79-.55-1.94-.55-.14-1.2.49-1.44 1.41zM357.8 5023.1c.25.93 0 1.79-.55 1.94-.55.15-1.2-.48-1.44-1.41-.25-.93 0-1.79.55-1.94.55-.14 1.2.49 1.44 1.41z"
      />
      <ellipse
        style={{
          fill: "#fde5f3",
        }}
        cx={362.75}
        cy={5022.02}
        rx={5.62}
        ry={7.05}
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M362.37 5014.64c-.85.07-2.05.51-2.53 1.21h.42c-1.98-.65-3.69-.04-3.46 3.41.07 1.11.6 4.43.6 4.43l1.08-3 .85-2.18c-.05-.74.43-1.5.84-2.08.01-.02.04 0 .03.02-.35.5-.45 1.09-.37 1.69l6.34 1.24c.76.15 1.31.81 1.31 1.59l.81 3.83.81-4.13c1.47-7.14-3.28-6.3-6.73-6.03z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M369.87 5035.06v-.32l.08-.11-.18-2.05c-.62-1.03-1.53-1.86-2.16-2.89-1.09.18-2.01.38-2.01.38l-.52-.96-2.24 3.3-2.32-3.49-.51.96s-3.22.35-4.84 1.05c-.12.64-1.32 2.95-1.77 4.13h16.47z"
      />
      <defs>
        <path id="l" d="M362.75 5020.17h17.65v14.81h-17.65z" />
      </defs>
      <clipPath id="m">
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref="#l"
          style={{
            overflow: "visible",
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath: "url(#m)",
        }}
      >
        <path
          style={{
            opacity: 0.3,
            fill: "#fff",
          }}
          d="M374.94 5025.74c-1.14.07-1.68 1.3-1.6 1.98.11.93.96 1.31 1.35 1.67.23.21.71 1.76 0 2.45 0 0 1.76-1.09 1.13-2.56-.32-.76-.6-1.67.15-1.46.9.26 1.15-2.22-1.03-2.08z"
        />
        <path
          style={{
            opacity: 0.6,
            fill: "#fff",
          }}
          d="M374.18 5027.46c-1.75-.11-1.54 1.87-.83 1.66.61-.17.38.56.12 1.17-.5 1.18.91 2.05.91 2.05-.57-.55-.19-1.79 0-1.96.31-.29.99-.59 1.08-1.33.06-.55-.37-1.53-1.28-1.59z"
        />
        <path
          style={{
            fill: "#fff",
          }}
          d="M376.36 5032.91h-.02l.09-.96c.02-.2-.14-.36-.33-.36h-3.18c-.2 0-.35.17-.33.36l.46 5.19h2.92l.13-1.5c.09.02.17.03.26.03.76 0 1.37-.62 1.37-1.37s-.62-1.39-1.37-1.39zm0 2.15c-.07 0-.14-.01-.21-.03l.13-1.51h.08c.43 0 .77.35.77.77s-.35.77-.77.77z"
        />
      </g>
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M291.8 5035.07v-2.02c-.28-1.19-1.92-3.36-3.46-4.09-.05.02-.1.03-.15.02l-3.58-.76-4.3.56c-.16.02-.33.06-.51.12-1.35.46-2.28 1.7-2.41 3.12l-.47 3.06h14.88z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M283.06 5016.46c-1.17-.03-2.34.21-3.41.7-.48.22-.96.52-1.18 1s-.06 1.16.44 1.33c-.86-.08-1.73.41-2.12 1.18-.38.77-.24 1.77.34 2.4-.69-.02-1.36.53-1.47 1.21s.34 1.42 1 1.63c-.61.34-.77 1.28-.32 1.8.46.53 1.4.5 1.83-.05.24.75 1.25.9 2.01.69s1.46-.67 2.24-.65c.56.01 1.1.25 1.65.23.44-.02.87-.22 1.32-.18.72.06 1.32.71 2.04.68.55-.02 1.05-.44 1.58-.34.37.06.65.36.98.54 1.05.57 2.55-.34 2.54-1.53 0-.42-.14-.9.15-1.21.11-.12.28-.19.4-.31.26-.28.18-.75-.05-1.05s-.58-.5-.88-.74c-.1-.08-.2-.17-.24-.3-.04-.13 0-.28.03-.41.2-.77.27-1.57.2-2.36-.02-.19-.05-.39-.18-.53-.13-.13-.31-.18-.47-.27-.76-.45-.46-1.64-.87-2.43-.5-.95-1.81-1-2.79-1.45-.45-.21-.85-.52-1.32-.69-.47-.17-1.05-.17-1.39.19-.38-.36-.78-.72-1.27-.9-.49-.18-1.1-.14-1.45.24-.18.19-.29.45-.53.55-.2.09-.43.04-.64.05-.72.03-1.29.86-1.06 1.54"
      />
      <path
        style={{
          fill: "#fcce98",
        }}
        d="m283.5 5028.63.27.06c2.49.51 4.84-1.35 5.01-3.89l.33-4.09c0-2.43-1.93-4.46-4.37-4.5h-.27c-2.45 0-4.39 1.98-4.34 4.43.03 1.64-.92 3.09-.8 4.51.15 1.78 2.45 3.04 4.17 3.48z"
      />
      <path
        style={{
          fill: "#fcce98",
        }}
        d="M283.08 5029.53c0 1.1 2.98 1.1 2.98 0v-2.85h-2.98v2.85z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M289.35 5021.73c.24-1.23.01-2.55-.65-3.62a5.126 5.126 0 0 0-2.93-2.23c-.73-.21-1.5-.25-2.26-.22-1.07.05-2.18.28-3.08.88-.92.61-1.47 1.65-1.48 2.76 0 .87.52 1.49 1.31 1.8.56.22 1.17.3 1.77.39.74.11 1.49.19 2.24.25 1.51.12 3.03.13 4.54.03.17-.01.36-.02.54-.04z"
      />
      <path
        style={{
          fill: "#dcd5e9",
        }}
        d="M410.44 5022.95c0 3.75-.44 6.53-4.66 6.53-4.08 0-5.09-3.04-5.09-6.8 0-3.75 2.28-6.8 5.09-6.8 2.81.01 4.66 3.32 4.66 7.07z"
      />
      <path
        style={{
          fill: "#baabd3",
        }}
        d="M401.07 5022.71c.23.86 0 1.67-.51 1.81-.51.14-1.11-.45-1.35-1.31-.23-.86 0-1.67.51-1.81.52-.14 1.12.45 1.35 1.31zM410.38 5022.71c-.23.86 0 1.67.51 1.81.51.14 1.12-.45 1.35-1.31.23-.86 0-1.67-.51-1.81s-1.12.45-1.35 1.31z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M408.08 5015.94c1.84-.6 2.88-.04 2.88 3.18l-.22 5.15-1.01-3.82-.95-2.44-6.21 1.21c-.71.14-1.22.76-1.22 1.48l-.76 3.57-.76-3.85c-1.36-6.64 3.07-5.86 6.28-5.61.8.07 1.52.48 1.97 1.13z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M410.21 5018.16v1.67h-.2l.01 4.52c0 1.15-.31 2.28-.9 3.27l-1.75.15-1.23.08h-.8l-.8.03-1.81-.09-1.49-1.68v-6.28h-.62c-.02.2-.04.39-.04.6v6.44c0 1.18.8 2.21 1.94 2.5 2.13.54 4.22.53 6.28.01a2.57 2.57 0 0 0 1.93-2.5v-6.45a4.8 4.8 0 0 0-.52-2.27z"
      />
      <path
        style={{
          fill: "#a3d47b",
        }}
        d="M399.51 5035.05c0-2.98 2.76-5.4 6.16-5.4 3.4 0 6.16 2.42 6.16 5.4"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m415.31 5040.7-1.66-51.66 38.35-1.23 1.67 51.66"
      />
      <path
        style={{
          fill: "none",
        }}
        d="m460.47 4989.28-1.74 21.75 4.34-3.61zM404.63 5013.83l3.36 1.27-1.32-17.64z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="m452.9 5120.04 3.47-73.53c-4.39 1.65-11.67 3.92-15.5 2.58-2.81-.98-3.99-5.06-4.47-9.07l-5.77.19c-1.43 3.62-3.63 7.15-6.92 8.19-4.2 1.33-9.85.51-13.97-.45l2.17 74.3c.52-.61.82-.97.82-.97l4.24 6.23 7.47-4.33.18 4.41h.03l7.32-73.21 3.72 5.6 4.96 62.31 7.47 3.61 4.78-5.86z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M456.77 5035.36h-.09v4.81l-.3 6.35c2.16-.81 3.63-1.47 3.63-1.47l-1.58-13.39-1.46 1.16-.2 2.54z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="m456.68 5035.35-2.79-.09-.34.27.13 3.93-17.26.55c.48 4.01 1.66 8.09 4.47 9.07 3.82 1.33 11.1-.94 15.5-2.58l.3-6.35v-4.8zM409.39 5034.24l-3.27-1.06-1.82 13.2s2.27.83 5.45 1.57l-.36-12.23v-1.48z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M423.72 5048.4c3.29-1.04 5.49-4.57 6.92-8.19l-15.33.49-.15-4.6-5.77-1.86v1.48l.36 12.23c4.12.96 9.77 1.78 13.97.45z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="m441.38 4952.48-10.79-1.12-7.6-2.08.03 2.32 9.32 6.86z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M456.68 5019.51v2.99l.64-.28.3 2.55 1.11-13.74-5.82 4.83.11 3.48z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M441.33 4956.59v.96l.23 11.44c.33 5.65.67 13.07.65 19.13l9.79-.31.9 28.05 5.82-4.83 1.75-21.74 2.6 18.13-4.34 3.61-1.1 13.74.81 6.88 19.03-15.13s-4.84-44.79-12.22-51.96c-4.35-4.23-21.83-8.34-21.83-8.34l-2.04-3.74v3.74c0 .13-.02.25-.05.37zM409.39 5017.29l5.03.24-6.43-2.43.9 12.12.5.07z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m408.89 5027.22-.9-12.12-3.36-1.27 2.04-16.37 1.32 17.64 6.43 2.43.14.01-.92-28.49 10.13-.33c-.76-5.65-.85-14.47-.81-19.73l.06-12.12v-5.27l-2.05 3.86s-20.48 3.93-25.27 9.1c-7.49 8.08-4.82 63.71-4.82 63.71l15.24 4.92.86-6.26 1.91.29zM456.68 5022.5v-2.99l-3.66-.17.15 4.66z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m414.56 5017.54-.14-.01.14.05z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m409.39 5027.29 5.51.86-.34-10.57-.14-.05-5.03-.24zM456.68 5022.5v10.55l.3-.24.64-8.04-.3-2.55z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m456.98 5032.81 1.45-1.16-.81-6.88z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="m456.68 5035.35.09.01.21-2.55-.3.24z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="m453.88 5035.27 2.8.08v-2.3z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M456.68 5033.05v-10.55l-3.51 1.5.36 11.26.35.01"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m453.54 5035.54.34-.27-.35-.01zM408.89 5027.22l.5 6.66v-6.59z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m409.39 5033.88-.5-6.66-1.91-.3-.86 6.26 3.27 1.06zM414.9 5028.15l-5.51-.86v6.59l5.7.18z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M409.39 5033.88v.36l5.77 1.86-.07-2.04zM441.33 4956.59c.03-.12.05-.24.05-.37v-3.74l-.06-.11.01 4.22z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M454.76 4924.62c0 3.84-.61 7.41-1.72 10.61.37.4.8.69 1.3.83 2.21.59 4.8-1.94 5.79-5.66.99-3.71.01-7.2-2.2-7.79-.97-.26-2.01.08-2.96.86-.09.46-.17.8-.22 1.02.01.05.01.09.01.13zM409.64 4924.5c-.05-.19-.11-.48-.19-.86-1-.89-2.11-1.3-3.14-1.03-2.21.59-3.19 4.08-2.2 7.79.99 3.71 3.59 6.24 5.79 5.66.55-.15 1.03-.48 1.42-.95-1.08-3.17-1.68-6.7-1.68-10.5v-.11z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M441.38 4944.3v4.93c5.26-2.66 9.46-7.59 11.67-14-1.24-1.37-1.66-4.09-.89-6.96.46-1.71 1.26-3.17 2.21-4.19l-1-2.11c.31-5.62-1.21-6.43-1.21-6.43-3.03-2.72-3.35-6.56-3.35-6.56-1.63-9.46-12.05-9.16-12.05-9.16h-9.1s-11.11.14-12.05 9.16c0 0-.31 3.84-3.35 6.56 0 0-1.52.8-1.21 6.43l-1.06 2.22c.9 1.01 1.66 2.43 2.11 4.08.74 2.78.38 5.44-.78 6.84 2.24 6.58 6.56 11.62 11.98 14.26v-5.07h18.08z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M441.38 4952.48v-8.18H423.3v5.07c2.73 1.33 5.74 2.05 8.9 2.05 3.27 0 6.37 2.48 9.18 1.06zM454.76 4924.5c-.04.18-.07.28-.07.28l-.33-.7c-.95 1.02-1.75 2.48-2.21 4.19-.77 2.87-.35 5.59.89 6.96 1.11-3.2 1.72-6.77 1.72-10.61v-.12zM412.1 4928.27c-.44-1.65-1.2-3.07-2.11-4.08l-.28.6s-.03-.1-.07-.28v.11c0 3.79.6 7.32 1.68 10.5 1.15-1.41 1.52-4.07.78-6.85z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M432.33 4959.67h.01-.01zM423.3 4951.8l-.28-.2.28.24zM440.99 4960.3s.17 2.14.38 5.4M433.51 4958.71l2.94.62M433.51 4958.71l-1.17-.25-.79.52.79.69z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m431.55 4958.98-.96.63c.57.04 1.16.06 1.74.06l.01-.01-.79-.68z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M471.84 5137.66c-2.2-1.1-18.94-17.62-18.94-17.62l-.36 7.56h-11.48l-.42-5.3-.97-.47-.37 7.63 12.35 5.25s.61 3.61 3.91 5.66c3.3 2.05 20.18 1.97 20.79 1.4.61-.57 1.36-1.99 1.36-1.99s-3.67-1.02-5.87-2.12z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="m452.54 5127.6.36-7.56-4.79 5.87-7.46-3.61.42 5.3z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M432.2 4896.32c12.21 0 22.16 12.17 22.55 27.37.08-.07.16-.15.24-.21 1.2-6 4.51-30.7-22.79-30.7-27.55 0-23.92 25.17-22.75 30.86.07.06.14.12.2.18.34-15.26 10.3-27.5 22.55-27.5z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M454.76 4924.5c.05-.22.13-.57.22-1.02l-.24.21c.01.27.02.54.02.81zM409.65 4923.82c-.07-.06-.14-.12-.2-.18.08.38.14.67.19.86 0-.22.01-.45.01-.68z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M454.75 4923.69c-.39-15.2-10.33-27.37-22.55-27.37-12.25 0-22.21 12.24-22.55 27.5.12.11.23.23.34.36l1.06-2.22c-.31-5.62 1.21-6.43 1.21-6.43 3.03-2.72 3.35-6.56 3.35-6.56.94-9.02 12.05-9.16 12.05-9.16h9.1s10.42-.3 12.05 9.16c0 0 .31 3.84 3.35 6.56 0 0 1.52.8 1.21 6.43l1 2.11c.12-.13.24-.26.38-.38z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M454.76 4924.5c0-.27-.01-.54-.02-.81-.13.12-.26.25-.39.39l.33.7c.01 0 .04-.1.08-.28zM409.65 4923.82c0 .23-.01.45-.01.68.04.18.07.28.07.28l.28-.6c-.11-.12-.22-.24-.34-.36z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M411.91 5122.26c-3.13 3.68-14.27 16.72-15.94 17.72-1.95 1.17-5.21 2.26-5.21 2.26s.66 1.5 1.2 2.11c.54.61 15.48.69 18.41-1.48 2.92-2.17 3.46-6.01 3.46-6.01l10.94-5.57-.15-3.68h-12.56l-.15-5.35z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="m416.97 5127.52-4.24-6.23s-.31.36-.82.97l.16 5.34h12.56l-.18-4.41-7.48 4.33z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m414.9 5028.15.19 5.91 5.52.17 1.52-4.97zM441.37 4958.41l.88 31.53-19.44.6.25-31.85"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m441.41 4952.3-9.04 5.98-9.31-6.86-.04 9.66 18.42-.18"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M406.2 5028.73c-.23-3.14 2.37-5.67 5.1-6.2.08-.01.16-.03.24-.05 1.65-.32 2.77.93 3.41 2.17.37.71 1.26 1.74 2.13 1.83.06-.03.13-.06.21-.11.36-.19.72-.37 1.08-.56.99-.51 1.95-.92 2.91-1.33.89-.38 1.59.14 1.94.82 2.08 3.96-3.88 9.45-7.48 10.12-4.19.78-9.2-2.17-9.54-6.69zM444.74 5024.7c2.8-1.19 5.8-1.94 8.84-2.23.17-.02.35-.03.5.04.23.1.34.36.43.59.58 1.67.58 3.55-.1 5.18-.67 1.64-2.02 3.01-3.69 3.64-1.02.39-2.13.5-3.22.52-1.52.02-3.1-.17-4.37-.98 0 0-5.07-3.92 1.61-6.76z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m416.13 5019.04-6.05 15.7-7.43-2.75 5.67-15.04z"
      />
    </g>
      </>
  )
}