import React from "react";

export default function Section3() {
    return (
        <g id="cjg">
            <path
                style={{
                fill: "#7bc143",
                }}
                d="M646.87 2298.71H595c21.27-2.82 37.78-23.09 37.78-47.72 0-26.56-19.18-48.09-42.85-48.09H247.65c-23.66 0-42.85 21.53-42.85 48.09s19.18 48.09 42.85 48.09h30.9c27.08 3.27 48.17 28.99 48.17 60.25 0 33.48-24.18 60.61-54 60.61H251.7c-16.95 0-30.7 15.42-30.7 34.45s13.75 34.45 30.7 34.45h226.08c16.95 0 30.69-15.42 30.69-34.45s-13.74-34.45-30.69-34.45H648.4c29.82 0 52.47-27.14 52.47-60.61 0-33.49-24.18-60.62-54-60.62z"
            />
            <path
                style={{
                fill: "#7bc143",
                }}
                d="M88.75 2400.87h56.58c-23.2 2.59-41.21 21.2-41.21 43.81 0 24.39 20.92 44.15 46.74 44.15h373.35c25.81 0 46.74-19.77 46.74-44.15s-20.92-44.15-46.74-44.15h-33.7c-29.54-3-52.54-26.61-52.54-55.31 0-30.73 26.37-55.65 58.91-55.65h22.92c18.49 0 33.48-14.16 33.48-31.63s-14.99-31.63-33.48-31.63H273.19c-18.49 0-33.48 14.16-33.48 31.63s14.99 31.63 33.48 31.63H87.09c-32.53 0-57.24 24.91-57.24 55.65s26.37 55.65 58.9 55.65z"
            />	

            <path
        style={{
          fill: "#522e91",
        }}
        d="M502.51 2196.91h135.22v164.97H502.51z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m570.11 2157.88-67.6 39.03h135.2z"
      />
       <path
        style={{
          fill: "#f799d1",
        }}
        d="m488.75 2194.6 4.3 8.25 82.51-47.57-5.45-9.45zM486.75 2348.71h165.48v13.01H486.75z"
      />
        <path
        transform="rotate(-60 611.625 2176.175)"
        style={{
          fill: "#f799d1",
        }}
        d="M606.17 2125.02h10.91v102.18h-10.91z"
      />
     
      <path
        style={{
          fill: "#856abf",
        }}
        d="M532.19 2209.99h31.77v31.77h-31.77zM575.02 2209.99h31.77v31.77h-31.77zM575.02 2252.82h31.77v31.77h-31.77zM532.19 2252.82h31.77v31.77h-31.77zM575.02 2295.65h31.77v31.77h-31.77zM532.19 2295.65h31.77v31.77h-31.77z"
      />	
      </g>
    )
}