import React from "react";

// Component
import Drawing from './components/Drawing';

const App = () => {
 
  return (
   <>
      <div className="app" id="app">
        <div className="app__topbar"></div>
        <div className="app__hero"></div>
        <div className="app__content">
          <Drawing />
        </div>
      </div>
      <div className="green-footer">
        <div className="green-grass"></div>
      </div>
    </>
  );
}

export default App;
