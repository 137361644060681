import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Section4Figures() {
  useEffect(() => {
    function addPercentage(n) {
			return `${n}e`
		}
    gsap.from('.scholen-numbers', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.scholen-numbers',
    })
    gsap.from('.scholen-numbers-with-e', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.scholen-numbers-with-e',
      onUpdate: function() {
				this.targets().forEach(target => {
					const val = gsap.getProperty(target, "textContent");
					target.textContent = addPercentage(val);
				});
			}
    })

  }, [])

  useEffect(() => {
    gsap.set('#hidden-boy', {x: -40})
    gsap.timeline({defaults: {duration: 1, opacity: 1}, repeat: -1})

    .to('#hidden-boy', {
      x: 0,
      duration: 4
    })
    .to('#hidden-boy', {
      x: -40,
      duration: 2
    }, '=2')
  }, [])

  return (
      <>
      <g >
      <path
        style={{
          fill: "#fff",
        }}
        d="m636.71 2924.47-140.7.02 3.34-7.13 133.48-.02zM644.74 2942.29l-155.69.03 4.71-9.93 145.49-.03zM655.53 2962.55l-175.64.02 6.29-12.52 161.96-.03z"
      />
      <path
        style={{
          fill: "none",
        }}
        d="M665.33 2925.06h169.09v67.29H665.33z"
      />
      <text transform="translate(715.503 2966.306)">
        <tspan
          x={0}
          y={0}
          className="scholen-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 55,
          }}
        >
          {"35"}
        </tspan>
        <tspan
          x={-24.71}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"deelnemers PATS "}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M380.45 3021.59h234.51v67.29H380.45z"
      />
      <text transform="translate(482.178 3040.344)">
        <tspan
          x={0}
          y={0}
          className="scholen-numbers-with-e" 
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"2"}
        </tspan>
        <tspan
          x={-93.05}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"contactmoment basisonderwijs"}
        </tspan>
      </text>
      <path
        style={{
          fill: "#9381bd",
        }}
        d="m302.81 2924.23-5.82-30.41-36.85-1.43-8.24 25.24c1.97 34.09 11.17 71.44 15.42 101.42h6.87l5.43-93.74-2.19 91.6h7.24c7.63-30.83 15.25-60.5 18.14-92.68z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M287.06 3007.26c-.79 3.2-1.59 6.41-2.39 9.63h-7.24l.23-9.63h9.4zM274.87 3007.26l-.68 11.78h-6.87c-.54-3.81-1.17-7.75-1.84-11.78h9.39z"
      />
      <path
        style={{
          fill: "#9381bd",
        }}
        d="M243.76 2918.95h-4.93c-1.27 0-2.52-.07-3.76-.2l-.69 3.39-1.9 12.38a6.38 6.38 0 0 0 3.05 6.45l1.35.8c1.79 1.06 4.06-.21 4.1-2.29l-.58-12.28 3.36-8.25zM316.6 2926.39l-1.92-5.31c-2.23.37-4.53.46-6.87.23l2.57 5.35c-.7 1.02-.99 2.35-.57 3.49-2.34.41-3.1 3.79-2.04 5.69 1.28 2.29 4.19 2.78 6.53 2.13 2.21-.61 4.08-2.32 4.64-4.57.6-2.44-.61-4.49-1.89-6.44-.14-.2-.29-.39-.45-.57z"
      />
      <path
        style={{
          fill: "#ec008c",
        }}
        d="m234.37 2922.13 5.76-28.32 5.23-33.73c.58-6.43 4.79-12.06 10.91-14.14.79-.27 1.57-.46 2.3-.55l19.5-2.53 14.47 1.68c3.25.69 7.58 4.99 8.31 8.23l4.63 42.95 9.39 25.93h-6.92l-10.82-22.59-.15-5.25-41.72.64.08-12.61-4.36 19.41-9.27 22.66h-7.62l.28-1.78z"
      />
      <path
        style={{
          fill: "#9381bd",
        }}
        d="M270.43 2848.62c0 5.67 15.37 5.67 15.37 0v-14.69h-15.37v14.69z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m300.57 2981.99 2.78-63.46-6.36-24.71-41.72.64-6.16 24.09 6.16 62.58z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M267.36 3017.96c-.5.61-.96 1.27-1.41 1.84-1.84 2.35-4 4.47-6.07 6.64-.36.38-.72.79-1.01 1.23-.4.61-.64 1.28-.48 1.97.19.82.91 1.43 1.71 1.68.79.26 1.65.22 2.49.12 1.82-.21 3.64-.68 5.21-1.62 2.34-1.4 2.03-3.39 4.08-4.8.34 1.03.88 7.13 2.72 5.89.63-.43 1.23-11.01.05-12.97h-7.29zM298.51 3026.79c-2.15-2.81-6.06-3.45-9.07-5.32-2.04-1.27-3.52-3.06-4.69-4.94h-7.4c-.62 1.5-.59 3.41-.24 4.8.27 1.06.82 2.05 1.63 2.79.7.64.93 6.71 2.11 6.69 1.97-.03 2.31-6.3 2.24-6.69 2.09 1.33 3.21 2.07 5.47 3.09 2.06.93 4.34 1.36 6.6 1.34.83-.01 1.66-.08 2.47-.21.5-.08 1.11-.29 1.16-.79.06-.27-.1-.54-.28-.76z"
      />
      <path
        style={{
          fill: "#9381bd",
        }}
        d="M279.36 2858.37c.98.22 2.02.38 2.99.11.98-.27 1.8-.95 2.53-1.67 3.27-3.23 5.34-7.66 5.73-12.24a7.948 7.948 0 0 0-5.64-1.18c.07 3.68-1.55 5.34-4.1 7.99-3.59-2.26-7.02-4.89-9.22-8.52-3.33.83-6.63 1.29-9.96 2.13 4.02 5.77 10.79 11.87 17.67 13.38z"
      />
      <path
        style={{
          fill: "none",
        }}
        d="M274.43 2832.73c1.42 2.57 5.27 3.16 8.6 1.32M271.65 2819.56c0 1.78-.86 3.23-1.92 3.23s-1.92-1.45-1.92-3.23.86-3.23 1.92-3.23 1.92 1.45 1.92 3.23zM284.62 2819.56c0-1.78.86-3.23 1.92-3.23s1.92 1.45 1.92 3.23-.86 3.23-1.92 3.23-1.92-1.45-1.92-3.23z"
      />
      <path
        style={{
          fill: "none",
        }}
        d="M270.59 2822.19c0 .47-1.01.85-2.25.85s-2.25-.38-2.25-.85 1.01-.85 2.25-.85 2.25.38 2.25.85zM290.31 2822.19c0 .47-1.01.85-2.25.85-1.25 0-2.25-.38-2.25-.85s1.01-.85 2.25-.85 2.25.38 2.25.85z"
      />
      <path
        style={{
          fill: "#9381bd",
        }}
        d="M272.63 2841.43c.43.11.85.21 1.27.3 11.89 2.44 23.14-6.48 23.99-18.6l1.59-19.56c0-11.65-9.26-21.33-20.9-21.52-.43-.01-.85-.01-1.28-.01-11.71.01-21 9.48-20.77 21.19.15 7.85.76 13.49 1.33 20.28.69 8.48 6.53 15.79 14.77 17.92z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M299.31 2789.45c-5.42-6.93-14.24-9.83-22.81-9.6-1.72.05-3.42.5-4.94 1.33-1.31.72-2.55 2.77-3.78 3.44-.11.06-.21.1-.32.14-1.44.48-2.95-.69-4.42-.51-1.91.24-3.89 1.77-5.26 3.02-4.9 4.5-6.78 11.4-7.71 17.99-.36 2.52-.22 10.97.08 20.25 3.92-.16 7.82-.44 11.73-.77-.12-6.38.16-13.25.58-16.1 1.45-9.83 5.59-16.54 7.79-15.52 4.22 1.97 8.39 3.86 12.73 5.47 5.2 1.92 8.98 6.74 10.79 11.85 1.63 4.6 2.19 9.82 1.81 14.87 3.04.26 6.11.31 9.17.47.21-2.77.43-5.53.59-8.32.59-9.73.23-19.99-6.03-28.01z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M361.64 2860.24c0 1.63-1.68 2.96-3.75 2.96-2.07 0-3.75-1.33-3.75-2.96 0-1.64 1.68-2.96 3.75-2.96 2.07 0 3.75 1.32 3.75 2.96z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M350.69 3022.67c-.46-.17-.82-.56-1.05-.99-.79-1.44-.31-3.32.72-4.6 1.03-1.28 2.52-2.1 3.96-2.89 1.7-.93 3.41-1.87 5.26-2.46 1.85-.59 3.85-.82 5.72-.31.71 1.18.46 2.76-.29 3.92-.75 1.16-1.91 1.98-3.08 2.71-2.9 1.83-6.02 3.32-9.26 4.43-.64.21-1.34.42-1.98.19zM392.81 3022.67c.46-.17.82-.56 1.05-.99.79-1.44.31-3.32-.72-4.6-1.03-1.28-2.52-2.1-3.96-2.89-1.7-.93-3.41-1.87-5.26-2.46-1.85-.59-3.85-.82-5.73-.31-.71 1.18-.46 2.76.29 3.92.75 1.16 1.92 1.98 3.08 2.71 2.9 1.83 6.02 3.32 9.26 4.43.65.21 1.35.42 1.99.19z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M352.75 3017.36c-.74-.24-1.47-.42-2.16-.55-.08.09-.16.17-.23.27-1.03 1.28-1.51 3.16-.72 4.6.24.43.59.82 1.05.99.64.23 1.34.03 1.98-.19.84-.29 1.67-.6 2.48-.94-.46-1.75-1.11-3.3-2.4-4.18zM390.33 3019.26c-.87.64-1.49 1.42-2.02 2.25.83.34 1.67.67 2.52.97.64.22 1.34.43 1.98.19.46-.17.82-.56 1.05-.99.62-1.13.45-2.51-.14-3.68-1.22.32-2.5.6-3.39 1.26z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M356.98 3019.39c-2.25 1.17-4.76 2.39-7.26 2.41.23.38.56.72.98.87.64.23 1.34.03 1.98-.19 3.24-1.12 6.35-2.61 9.26-4.43 1.17-.73 2.33-1.55 3.08-2.71.53-.81.8-1.83.68-2.77-2.08 3.08-5.48 5.13-8.72 6.82zM391.3 3021.27c-2.55-.73-4.9-2.15-7.2-3.45-2.09-1.19-4.92-2.67-6.31-4.79 0 .8.27 1.62.7 2.3.75 1.16 1.92 1.98 3.08 2.71 2.9 1.83 6.02 3.32 9.26 4.43.64.22 1.34.43 1.98.19.46-.17.82-.56 1.05-.99.06-.1.09-.22.14-.33-.81.21-1.74.21-2.7-.07z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m384.09 2942-2.11-15.4-31.69-1.65v13.74l7.33 73.61H367l-2.72-70.37 5.47-1.5 7.16 71.87h8.56z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m380.25 2874.11-13.53-3.62-12 2.89.73 10.33h24.65z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M408.15 2933.06c-.08.48-.22.96-.42 1.4-.68 1.51-1.9 2.7-3.12 3.81-4.31 3.91-6.41.4-6.51-4.04-.09-4.22 1.76-7.69 6.3-6.18 1.1.37 2.24.82 2.98 1.72.75.9.96 2.12.77 3.29zM361.92 2875.21c0 3.77 12.33 3.77 12.33 0v-9.78h-12.33v9.78z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M385.57 2846.59c3.48 10.79-2.03 22.22-12.31 25.54-10.28 3.32-21.44-2.73-24.92-13.52-3.48-10.79 2.03-22.22 12.31-25.54 10.28-3.32 21.44 2.73 24.92 13.52z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M350.69 2832.92c-2.03-.01-4.11-.2-6.03.62-.4.17-.86.53-.71.94.94.24 1.86.55 2.76.92-1.09-.16-2.2.35-2.96 1.15-.76.8-1.2 1.85-1.47 2.91-.88 3.5 0 7.21 1.41 10.53 1.06 2.51 2.42 4.87 3.6 7.32 1.04 2.15 1.83 4.48 3.02 6.55.47.83 1.02 1.65 1.8 2.2.79.54 1.88.74 2.7.27-.62-1.16-1.23-2.32-1.85-3.48.82.56 1.98.57 2.81.02-1.58-.71-2.75-2.12-3.52-3.67-.76-1.55-1.16-3.25-1.55-4.94-.39-1.71-.8-3.17-.64-4.9.1-1.05.13-2.12.19-3.17.11-1.81 1.35-2.55 1.46-4.36.01-.2 6.64-1.73 7.24-1.79 1.25-.12 2.54-.23 3.73.17s2.25 1.47 2.23 2.73c-.01.48-.15 1.06.21 1.39.52.47 1.32-.19 1.56-.85.4-1.08.31-2.33-.23-3.35 2.44-.45 4.95-.89 7.39-.4 2.43.49 4.8 2.14 5.38 4.56.39 1.64-.07 3.38.27 5.03 1.27-.3 2.16-1.69 1.92-2.97 1.03 1.08 1.81 2.92 2.56 4.23.72 1.26 1.08 2.89 2.11 3.91.67.67 1.66 1.07 2.58.87.22-.05.44-.13.58-.31.32-.41-.01-1-.33-1.41-.45-.58-.9-1.15-1.35-1.73.42.11.85.21 1.27.1.41-.12.77-.54.65-.96-.07-.24-.28-.42-.49-.57-.98-.74-.73-1.59-1.49-2.56-.94-1.2-1.57-1.43-1.18-2.94.05-.18-1.09-1.44-.92-1.5.22-.07.44.13.67.12.35-.01.51-.44.56-.79.38-3.04-.88-6.24-3.25-8.19.44-.15.87-.29 1.31-.44-.23-1.85-1.55-3.38-2.94-4.63-2.47-2.23-5.37-4.04-8.56-4.97-3.2-.93-6.7-.94-9.8.27.62-.72 1.23-1.44 1.85-2.16-1.48-.61-3.19-.21-4.66.42-2.54 1.09-4.25 2.78-6.19 4.66-1.59 1.58-3.02 2.88-3.7 5.15z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M383.98 2890.12c-2.08-3.11-6.69-8.51-9.06-11.36-1.6 2.21-4 3.66-7.1 3.44-2.95-.22-5.21-1.79-6.98-3.93-.05.03-.1.07-.16.11l-9.62 10.19c-.27 1.09-.08 1.51-.16 2.06l-.55 41.03 32.85 3.13.52-18.02-.04-.05.3-26.6z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M402.85 2926.83 391 2908.47l-.58-31.65-13.39-3.57c-.28 1.99-1.01 3.92-2.14 5.48.01.01.02.02.02.03 2.38 2.85 6.98 8.25 9.06 11.36l-.3 26.59.04.05 14.26 16.77 4.88-6.7zM359.96 2877.18c-.2-.29-.4-.58-.59-.88-.76-1.23-1.07-2.43-1.37-3.68l-12.53 2.91-15.05 30.23 13.89 19.19 4.49-4.38c.03-.44.06-.89.1-1.33l-8.67-14.21 10.64-16.28 1.19-1.26 8.72-9.24c-.29-.34-.56-.7-.82-1.07z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M359.9 2924.98c-.26.91-.98 1.73-1.84 2.19-4 2.15-9.94 1.74-11.99-2.88-.07-.15-.13-.32-.1-.48.02-.13.1-.24.18-.34l2.34-3.24c.3-.42.61-.84 1.01-1.16 2.43-1.92 9.8 1.91 10.44 4.67.1.41.07.84-.04 1.24z"
      />
      <g id="hidden-boy">
      <path className="school-boy"
        style={{
          fill: "#4b2d90",
        }}
        d="m707.99 2886.38 8.04-8.47 19.53 12.52-5.51 8.75-17.21 17.55-5.73-4.12 12.99-17.58-2.68-3.32-16.35 16.56-5.21-3.76z"
      />
      <path className="school-boy"
        style={{
          fill: "#00aeef",
        }}
        d="M712.41 2881.72c-.37-2.31 4.64-5.22 5.93-7.2 1.91-2.92 2.74-3.76 6-7.8.1-.13.21-.27.22-.44.01-.16-.08-.3-.17-.44-.5-.78-.99-1.55-1.49-2.33-.09-.14-.18-.32-.1-.47.05-.09.15-.13.24-.17 1.15-.49 2.31-.97 3.46-1.46 1.62-.68 3.28-1.38 5.04-1.45 1.35-.06 2.66.27 3.94.66 1.15.35 1.98.2 3.16.29 2.33.19 4.56 1.11 6.2 2.81 1.73 1.79 1.82 3.77 2.63 5.91.93 2.43 3.3 4.77 5.28 6.36a34.656 34.656 0 0 1-5.8 6.18l-2.91-2.64c-.1-.09-9.92 14.12-11.28 15.12"
      />
      <path className="school-boy"
        style={{
          fill: "#fde5f3",
        }}
        d="M752.16 2876.76c.09.05.17.11.26.15 1.87.98 5.72 2.41 7.44.24.12-.16.23-.33.34-.49.17-.27.34-.54.58-.74.45-.38 1.09-.46 1.65-.31 1.15.3 2.37 1.33 2.59 2.53.21 1.15-.34 2.43-1.32 3.12-.88.62-1.77.6-2.76.5-1-.11-1.77.04-2.83.13-2.19.2-4.4-.15-6.49-.71-.88-.23-1.83-.5-2.61-.85l3.15-3.57z"
      />
      <ellipse className="school-boy"
        transform="rotate(-54.275 748.446 2854.422)"
        style={{
          fill: "#fde5f3",
        }}
        cx={748.59}
        cy={2854.49}
        rx={17.64}
        ry={16.81}
      />
      <path
      className="school-boy"
        style={{
          fill: "#4b2d90",
        }}
        d="M754.36 2859.45c.4-.56.28-1.35-.29-1.75-.56-.4-1.35-.28-1.75.29-.4.56-.28 1.35.29 1.75.56.4 1.34.27 1.75-.29zM744.42 2852.3c.4-.56.28-1.35-.29-1.75-.56-.4-1.35-.28-1.75.29-.4.56-.28 1.35.28 1.75.57.4 1.35.27 1.76-.29z"
      />
      <path
      className="school-boy"
        style={{
          fill: "none",
          stroke: "#4b2d90",
          strokeMiterlimit: 10,
        }}
        d="M745.71 2865.83c-2.1 1-4.61.1-5.61-2"
      />
      <path
      className="school-boy"
        style={{
          fill: "#fde5f3",
        }}
        d="M761.14 2864.87c-1.98 1.62-2.8 3.88-1.84 5.05.96 1.17 3.34.81 5.32-.8 1.97-1.62 2.8-3.88 1.84-5.05-.97-1.17-3.35-.81-5.32.8zM736.41 2847.09c-.91 2.39-2.79 3.89-4.21 3.35s-1.84-2.91-.93-5.3c.9-2.39 2.79-3.89 4.21-3.35s1.83 2.91.93 5.3z"
      />
      <path className="school-boy"
        style={{
          fill: "#f99d31",
        }}
        d="M762.6 2834.38c-1.13-1.92-2.8-3.53-4.78-4.55-2.63-1.35-5.67-1.66-8.63-1.63-4.21.05-8.75.92-11.57 4.04-1.39 1.53-1.78 3.51-2.94 5.12-.31.43-3.15 3-3.68 2.42.9.99 2.4 1.39 3.67.99-.56.18-.7 4.44-.66 4.97.01.13.02.27.09.38.29.44.95.01 1.28-.39 2.06-2.5 5.01-4.24 8.19-4.84 3.18-.6 6.57-.04 9.39 1.54-.88-.17-1.8.29-2.38.98-.59.68-.91 1.55-1.17 2.41.87.76 2.18.06 3.32-.13 1.84-.31 3.57.84 5.33 1.45 1.85.64 3.9.7 5.79.17-.26.83-2 10.39-3.06 10.26.95.13 1.95-.26 2.57-.98a23.07 23.07 0 0 1-3.89 10.16c-.13.19-.27.48-.09.64 1.92-.89 3.79-2.73 5.84-3.18.5-.11 1.08-.03 1.45.34 1.39-1.93 2.71-3.92 4.1-5.83 1.23-1.7 2.48-3.49 2.91-5.58.46-2.23-.25-4.41-1.08-6.46-.42-1.04-.83-1.97-1.62-2.81-.18-.19-2.64-1.92-2.67-1.67.45-3.75-1.61-7.68-4.95-9.46.38 1.15.43 2.41.13 3.58-.23-.7-.53-1.34-.89-1.94z"
      />
      <path className="school-boy"
        style={{
          fill: "#fde5f3",
        }}
        d="M728.16 2870.46c-1.01 1.49-1.94 3.04-2.62 4.7-.62 1.51-.46 2.18.28 3.48.71 1.26.56 2.88-.69 3.77-.98.69-2.4.66-3.37-.05-2.11-1.56-.63-3.51-.53-5.5.1-2.01.53-4.07 1.48-5.85.75-1.41 1.68-2.93 2.8-4.28.84 1.33 1.65 2.65 2.65 3.73z"
      />
      </g>
      <path
        style={{
          fill: "#522e91",
        }}
        d="M725.37 2914.87c0 1.24-1.01 2.25-2.26 2.25h-97.98c-1.24 0-2.26-1.02-2.26-2.25v-85.53a2.27 2.27 0 0 1 2.26-2.26h97.98c1.25 0 2.26 1.02 2.26 2.26v85.53z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M622 2827.28c0 1.24 1.02 2.25 2.26 2.25h105.43c1.25 0 1.53-.7.62-1.56l-22.2-21.12c-.9-.86-2.65-1.56-3.89-1.56h-79.95c-1.24 0-2.26 1.02-2.26 2.25v19.74z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M401.81 2914.87c0 1.24 1.02 2.25 2.26 2.25h95.18c1.25 0 2.26-1.02 2.26-2.25v-84.86c0-1.25-1.01-2.26-2.26-2.26h-95.18c-1.24 0-2.26 1.01-2.26 2.26v84.86z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M502.09 2827.28c0 1.24-1.02 2.25-2.26 2.25H396.78c-1.24 0-1.52-.7-.62-1.56l22.2-21.12c.9-.86 2.66-1.56 3.9-1.56h77.57c1.24 0 2.26 1.02 2.26 2.25v19.74z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M628.76 2917.12H497.7V2779.6c0-1.24.91-2.69 2.04-3.23l60.1-28.57c1.12-.53 2.96-.55 4.09-.03l62.79 28.64c1.13.51 2.04 1.96 2.04 3.19v137.52z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="m634.72 2775.71-71.24-32.56c-1.13-.52-2.97-.5-4.09.04l-69.62 33.27a1.98 1.98 0 0 0-.84 2.88l2.09 3.33c.66 1.05 2.11 1.48 3.24.94l65.24-31.18c1.12-.54 2.96-.55 4.08-.04l66.8 30.55c1.13.51 2.58.07 3.22-.99l2.02-3.36c.63-1.08.23-2.37-.9-2.88z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M441.54 2883.18c0 1.24-1.02 2.26-2.25 2.26l-22.54-.01a2.27 2.27 0 0 1-2.26-2.26l.01-33.4c0-1.24 1.01-2.26 2.25-2.26l22.55.01a2.27 2.27 0 0 1 2.26 2.26l-.02 33.4zM483.76 2883.18c0 1.24-1.01 2.26-2.26 2.26l-22.54-.01a2.27 2.27 0 0 1-2.26-2.26l.01-33.4c0-1.24 1.02-2.26 2.25-2.26l22.54.01a2.27 2.27 0 0 1 2.26 2.26v33.4zM530.84 2883.18a2.27 2.27 0 0 1-2.26 2.26l-22.54-.01a2.27 2.27 0 0 1-2.26-2.26v-33.4a2.27 2.27 0 0 1 2.26-2.26l22.55.01a2.27 2.27 0 0 1 2.26 2.26l-.01 33.4zM620.71 2883.18a2.27 2.27 0 0 1-2.26 2.26l-22.55-.01a2.27 2.27 0 0 1-2.26-2.26l.02-33.4c0-1.24 1.01-2.26 2.25-2.26l22.55.01c1.23 0 2.25 1.02 2.25 2.26v33.4zM667.91 2883.18c0 1.24-1.03 2.26-2.26 2.26l-22.54-.01a2.27 2.27 0 0 1-2.26-2.26l.01-33.4c0-1.24 1.02-2.26 2.25-2.26l22.55.01a2.27 2.27 0 0 1 2.26 2.26l-.01 33.4zM710.11 2883.18c0 1.24-1.01 2.26-2.26 2.26l-22.53-.01a2.27 2.27 0 0 1-2.26-2.26v-33.4a2.27 2.27 0 0 1 2.26-2.26l22.55.01c1.24 0 2.25 1.02 2.25 2.26l-.01 33.4z"
      />
      <path
        style={{
          fill: "#f4b3dd",
        }}
        d="M574.04 2773.55c0-6.55-5.33-11.85-11.86-11.85-6.55 0-11.85 5.3-11.85 11.85s5.3 11.86 11.85 11.86c6.53 0 11.86-5.31 11.86-11.86z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M562.18 2760.68c-7.12 0-12.87 5.76-12.87 12.87 0 7.11 5.75 12.87 12.87 12.87 7.1 0 12.88-5.76 12.88-12.87 0-7.11-5.78-12.87-12.88-12.87zm0 23.71a10.849 10.849 0 0 1-10.84-10.84c.02-5.99 4.85-10.83 10.84-10.84 5.97.01 10.83 4.85 10.84 10.84-.01 5.99-4.87 10.83-10.84 10.84z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M567.65 2775.74c-1.25-.46-5-1.82-5-1.82l4.19-6.62c.29-.47.15-1.1-.32-1.39-.47-.31-1.09-.16-1.4.3l-4.38 6.98c-.77 1.18-.21 2.12.71 2.45 1.38.5 4.12 1.5 5.5 2 .11.05.23.06.35.06.42 0 .8-.25.96-.66.19-.53-.08-1.11-.61-1.3z"
      />
      <path
        style={{
          fill: "#f4b3dd",
        }}
        d="M538.95 2858.78h46.65v58.34h-46.65z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M583.57 2846.08c-.35-1.19-1.66-2.16-2.9-2.16h-36.8c-1.24 0-2.55.97-2.91 2.16l-4.12 13.87c-.35 1.19.38 2.16 1.61 2.16h47.62c1.24 0 1.96-.98 1.62-2.16l-4.12-13.87z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M452.74 2815.98c0 15.4-1.8 26.79-19.13 26.79-16.71 0-20.89-12.48-20.89-27.88 0-15.4 9.35-27.88 20.89-27.88 11.53.01 19.13 13.57 19.13 28.97zM462.44 2929.92c2.02-2.05 4.88-1.27 6.46.8.05.06.09.12.14.18.95 1.25.6 2.97.05 4.26-.32.74-.62 2.12-.29 2.94.05.03.1.07.17.12l.85.61c.78.56 1.49 1.16 2.19 1.76.66.56.61 1.47.31 2.18-1.76 4.15-8.12 2.18-10.17-.57-2.39-3.21-2.62-9.32.29-12.28zM398.9 2924.79c2.02-2.05 4.88-1.27 6.46.8.05.06.09.12.14.18.95 1.25.6 2.97.05 4.26-.32.74-.62 2.12-.29 2.94.05.03.1.07.17.12l.85.61c.78.56 1.49 1.16 2.19 1.76.66.56.61 1.47.31 2.18-1.76 4.15-8.12 2.18-10.17-.57-2.39-3.21-2.63-9.32.29-12.28z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M454.71 3034.51c.59-.22 1.04-.71 1.35-1.26 1.01-1.85.4-4.24-.92-5.88-1.32-1.64-3.21-2.69-5.06-3.7-2.17-1.19-4.36-2.38-6.72-3.14-2.36-.75-4.93-1.05-7.32-.4-.91 1.51-.59 3.52.37 5s2.45 2.53 3.94 3.47a57.7 57.7 0 0 0 11.83 5.67c.82.27 1.72.54 2.53.24zM404.7 3031.49c-1.02-.53-1.12-1.35-1.15-2.88-.02-.84.03-1.72.45-2.44.26-.44.63-.79 1.01-1.13 2.35-2.17 4.56-3.52 7.66-4.51 2.36-.75 4.93-1.05 7.32-.4 1.89.51-.46 6.5-1.11 7.27-.77.85-1.68 1.57-2.7 2.12-.53.29-1.09.53-1.65.76-1.16.49-2.33.98-3.56 1.26-1.57.35-4.18.72-5.74.18-.2-.08-.37-.15-.53-.23z"
      />
      <path
        style={{
          fill: "#80d6f7",
        }}
        d="m410.01 2905.65 45.05-2.12v17.56l-3.3 100.17h-17.15l-1.8-83.82-2.06 6.08-9.12 77.74h-14.64z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="m416.97 2844.5 16.23-3.45 15.28 1.96v13.91h-31.51z"
      />
      <path
        style={{
          fill: "#a896c8",
        }}
        d="m433.2 2852.09 8.88-13.33 1.96 2.19s16.75 5.12 21.1 9.62c7.66 7.91 8.57 37.74 8.57 37.74l-2.93 43.49h-9.87l-1.13-33.5-4.7-17.95v24.19l-45.14 1.41.58-5.18-3.37 8.83-1.4 20.41-10.37-8.11 3.84-32.27s-2.01-30.3 4.33-38.53c3.59-4.65 19.1-9.43 19.1-9.43l2-2.18 8.55 12.6z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M465.13 2850.57c-3.97-4.1-18.89-9.3-18.89-9.3l-2.58 13.41-2.28-1.79-8.18 10.84-7.57-11.11-2.61 2.06-3.88-12.01s-12.56 4.5-15.59 8.43c-6.34 8.22-4.33 38.53-4.33 38.53l-3.28 26.9 10.38 5 .82-11.93 3.37-8.83-.58 5.18 45.14-1.41v-24.19l4.7 17.95.96 28.46h10.39l2.59-38.44c-.01-.01-.92-29.83-8.58-37.75z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M414.32 2814.98c.95 3.54.01 6.86-2.09 7.42-2.1.56-4.57-1.85-5.52-5.39-.95-3.54-.01-6.86 2.09-7.42 2.1-.56 4.57 1.85 5.52 5.39zM452.48 2814.98c-.95 3.54-.01 6.86 2.09 7.42 2.1.56 4.57-1.85 5.52-5.39.94-3.54.01-6.86-2.09-7.42-2.1-.56-4.57 1.85-5.52 5.39z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M451.81 2796.32v6.86H451l.04 18.54c.01 4.72-1.27 9.36-3.69 13.42l-7.16.61-11.61.45-7.41-.38-6.1-6.88v-25.76h-2.56c-.09.8-.15 1.62-.15 2.44v26.4c0 4.84 3.29 9.07 7.98 10.25 8.73 2.21 17.31 2.18 25.77.02 4.66-1.19 7.91-5.42 7.91-10.23v-26.44c-.02-3.34-.81-6.5-2.21-9.3z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M443.07 2787.24c7.56-2.48 11.83-.18 11.83 13.04l-.9 21.14-4.15-15.68-3.91-10-25.48 4.97c-2.9.57-5 3.11-5 6.07l-3.11 14.65-3.1-15.8c-5.57-27.25 12.58-24.02 25.77-23.01 3.24.24 6.2 1.94 8.05 4.62z"
      />
      <path
        style={{
          fill: "#fbfbfb",
        }}
        d="M589.25 2971.7s2.35 4.07 0 9.82l56.89-.67.57-.2v-7.8l-1.41-1.14h-56.05z"
      />
      <path
        style={{
          fill: "#858582",
        }}
        d="M644.39 2971.86s2.22 3.43 0 9.18c0 0 2.64-.74 2.62-4.27-.01-3.53-1.14-4.8-2.62-4.91z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M644.23 2972.29h-55.32c-.55 0-1-.45-1-1s.45-1 1-1h55.32c2.25 0 4.07 2.25 4.07 5.01v2.05c0 2.79-1.78 4.99-4.04 5.01l-55.35.5c-.55.01-1-.44-1.01-.99-.01-.55.44-1 .99-1.01l55.35-.5"
      />
      <path
        style={{
          fill: "#fbfbfb",
        }}
        d="M582.74 2996.3s2.35 4.07 0 9.82l56.89-.67.57-.2v-7.8l-1.41-1.14h-56.05z"
      />
      <path
        style={{
          fill: "#858582",
        }}
        d="M637.88 2996.47s2.22 3.43 0 9.18c0 0 2.64-.74 2.62-4.27-.02-3.53-1.14-4.81-2.62-4.91z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M637.72 2996.9H582.4c-.55 0-1-.45-1-1s.45-1 1-1h55.32c2.25 0 4.07 2.25 4.07 5.01v2.05c0 2.79-1.78 4.99-4.04 5.01l-55.35.5c-.55 0-1-.44-1.01-.99-.01-.55.44-1 .99-1.01l55.35-.5"
      />
      <path
        style={{
          fill: "#fbfbfb",
        }}
        d="M568.77 2983.82s2.9 4.13 0 9.97l69.96-.68.7-.2v-7.92l-1.74-1.16h-68.92z"
      />
      <path
        style={{
          fill: "#858582",
        }}
        d="M636.88 2984s2.47 3.48 0 9.32c0 0 2.94-.75 2.92-4.33s-1.27-4.89-2.92-4.99z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M636.38 2984.43h-68.04c-.68 0-1.23-.45-1.23-1.01s.55-1.01 1.23-1.01h68.04c2.76 0 5.01 2.28 5.01 5.09v2.08c0 2.83-2.18 5.06-4.97 5.09l-68.07.51c-.68.01-1.23-.44-1.24-1-.01-.56.54-1.02 1.22-1.02l68.07-.51"
      />
      <path
        style={{
          fill: "#fbfbfb",
        }}
        d="M563.43 3008.37s2.9 4.07 0 9.82l69.96-.67.7-.2v-7.8l-1.74-1.14h-68.92z"
      />
      <path
        style={{
          fill: "#858582",
        }}
        d="M631.43 3017.84s2.94-.74 2.92-4.27-1.27-4.81-2.92-4.91"
      />
      <path
        style={{
          fill: "#ec008c",
        }}
        d="M631.05 3008.96h-68.04c-.68 0-1.23-.45-1.23-1s.55-1 1.23-1h68.04c2.76 0 5.01 2.25 5.01 5.01v2.05c0 2.79-2.18 4.99-4.97 5.01l-68.07.5c-.68.01-1.23-.44-1.24-.99-.01-.55.54-1 1.22-1.01l68.07-.5"
      />
      <path
        style={{
          fill: "#fbfbfb",
        }}
        d="M576.39 3020.21s2.9 5.03 0 12.13l69.96-.83.7-.25v-9.64l-1.74-1.41h-68.92z"
      />
      <path
        style={{
          fill: "#858582",
        }}
        d="M644.38 3020.5s2.47 4.42 0 11.84c0 0 2.94-.95 2.92-5.51-.01-4.55-1.27-6.2-2.92-6.33z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M644 3021.25h-68.04c-.68 0-1.23-.55-1.23-1.22 0-.68.55-1.22 1.23-1.22H644c2.76 0 5.01 2.76 5.01 6.15v2.51c0 3.42-2.18 6.12-4.97 6.15l-68.07.62c-.68.01-1.23-.54-1.24-1.21-.01-.68.54-1.23 1.22-1.23l68.07-.61"
      />
    </g>
      </>
  )
}