import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Background() {
  useEffect(() => {
    // extra-numbers-thousand
    function thousandDelimeter(n) {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");	
    }
    
    gsap.from('.extra-numbers-top', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.extra-numbers-top'
    })

    gsap.from('.extra-numbers-lower', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.extra-numbers-lower'
    })

    gsap.from('.extra-numbers-thousand-mid', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 10 },
      scrollTrigger: '.extra-numbers-thousand-mid',
      onUpdate: function() {
        this.targets().forEach(target => {
          const val = gsap.getProperty(target, "textContent");
          target.textContent = thousandDelimeter(val);
        });
      }
    })

    gsap.from('.extra-numbers-thousand-lower', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 10 },
      scrollTrigger: '.extra-numbers-thousand-lower',
      onUpdate: function() {
        this.targets().forEach(target => {
          const val = gsap.getProperty(target, "textContent");
          target.textContent = thousandDelimeter(val);
        });
      }
    })

    gsap.from('.extra-numbers-thousand-bottom', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 10 },
      scrollTrigger: '.extra-numbers-thousand-bottom',
      onUpdate: function() {
        this.targets().forEach(target => {
          const val = gsap.getProperty(target, "textContent");
          target.textContent = thousandDelimeter(val);
        });
      }
    })

  }, [])

  useEffect(() => {
    gsap.set('#cloud-endpoint', {x:18})
    gsap.set('.move-back-forth', {x: -100});
    gsap.timeline({defaults: {duration: 1, opacity: 1}, repeat: -1})
    .to('.move-back-forth', {x: 30, duration: 7, opacity: .7}, '=3')
    .to('.move-back-forth', {x: -100, duration: 9})
  }, [])


  useEffect(() => {
  gsap.timeline({defaults: {duration: 1, opacity: .2}, repeat: -1})
  .to('.rotate-sun', {
    rotation: -390,
    transformOrigin: 'center',
    duration: 30,
    ease: 'none'
  })

  }, [])

  useEffect(()=> {
    gsap.timeline({repeat:-1})
    .to('.facebook',
       { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=40", ease:'none'},       
     )
    .to('.facebook',
       { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=40", ease:'none'}      
     );
     
    gsap.timeline({repeat:-1})
    .to('.linkedin',
       { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=30", ease:'none'}        
     )
    .to('.linkedin',
       { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=30", ease:'none'}      
     )
  }, [])

  useEffect(() => {
    gsap.set('#plane', {scale: 0.4});
    gsap.set('#plane-path', {y: -400, x:-150, scaleX: 1.4, scaleY: 1.2});
    gsap.timeline({
        defaults:{duration:110, ease:'none'},
        repeat: -1
    })
    .to('#plane', {
        motionPath:{
            path: '#plane-path',
            align: '#plane-path',
            alignOrigin: [0, 1],
            autoRotate: true
        }
    })
  }, []);

  return (
      <>
        <g id="path">
          <path style={{fill:"#A3D47B"}} d="M733.88,3083.83"/>
          <g>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M369.23,1659.66"/>
            </g>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M204.16,1371.92"/>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M382.37,1371.75"/>
            </g>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M470.79,2520.41"/>
            </g>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M422.08,1944.69"/>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M600.29,1944.53"/>
            </g>
            <g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M670.99,1228.7"/>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M549.04,3088.15"/>
              </g>
            </g>
            <g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M793.36,3975.12"/>
              </g>
            </g>
            <g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M391.92,3882.92"/>
              </g>
            </g>
            <g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M287.08,4921.86"/>
              </g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M711.4,4633.48"/>
              </g>
            </g>
            <g>
              <g>
                <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M718.21,5310.41"/>
              </g>
            </g>
            <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M753.64,1230.29    c0-78.93-63.95-142.83-142.89-142.83l-2.87,0l-82.65-1.59l-260.19,1.7c-78.93,0-142.92-63.99-142.92-142.92    c0-78.93,63.99-142.92,142.92-142.92h4.31c78.93,0,142.92-63.99,142.92-142.92l-0.22-17.35c-2.02-78.9,60.3-144.51,139.21-146.53    l4.31-0.11h43.72"/>
            <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M622.18,1752.64"/>
            <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M777.25,2471.21"/>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M753.64,1230.29     c0,78.93-63.99,142.92-142.92,142.92l-63.7-0.54l-82.87-0.91H231.54l-24.92,0.14c-78.93,0-142.92,63.99-142.92,142.92     c0,78.93,63.99,142.92,142.92,142.92l0.99,0h397.85l15.34-0.13l1.37,95.04l10.37,0c78.93,0.6,142.93,64.94,142.93,143.87     c0,78.93-63.99,142.92-142.92,142.92L495,2039.57h-10.28l-69.09,0.14c-78.93,0-142.92,63.99-142.92,142.92     c0,78.93,63.99,142.92,142.92,142.92h168.78c0,0,67.63-4.64-18.53,34.34c-86.16,38.98-143.83,52.88-143.83,52.88     s-76.13,22.66,0,28.65l61.79,0.02c78.93,0.6,142.12,66.68,142.12,145.61"/>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M423.72,3158.19"/>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M802.15,3832.73"/>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M625.97,2587.05     c0,78.93-63.99,142.92-142.92,142.92h-5.63l-98.39-0.75l-56.01-0.02l-8.53-0.04h-49.09c-78.93,0-142.92,63.99-142.92,142.92     c0,78.93,63.99,142.92,142.92,142.92l0.99,0l289.64,0.14h9.22h3.21c83.57,0.3,144.89,66.93,144.89,143.21"/>
            </g>
            <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M797.51,3734.54"/>
            <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M469.58,3705.74"/>
            <g>
              <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M614.48,3848.94     c0-78.93-63.99-142.92-144.89-143.21l-40.76-118.52H195.37c-78.93,0-142.92-63.99-142.92-142.92     c0-78.93,63.99-142.92,142.92-142.92l357.01-0.28l3.71,0.14h14.36c78.93,0,142.92-63.99,142.92-142.92"/>
            </g>
          </g>
          <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M614.48,3848.94   c0,78.93-63.99,142.92-142.92,142.92h-18.32l-192.11,0.97c-78.93,0-142.92,63.99-142.92,142.92c0,78.93,63.99,142.92,142.92,142.92   c0,0,32.37-3.83,48,12.3c15.63,16.13,51.34,40.6,108.11,39.71h1.87c80.8,0.63,144.78,64.62,144.78,143.55   c0,78.93-63.99,142.92-142.92,142.92h-3.74l-113.85,0.37l-2.67-0.15c-78.81-4.38-146.25,55.95-150.63,134.76   c-4.38,78.81,55.95,146.25,136.58,151.37l258.79,3.17c0,0,101.42,3.72,99.84,84.64l-3.07,157.27   c-0.38,87.27-65.37,142.29-144.3,141.52l-4.79,0.02h-2.67h-2.32l-3.5-0.01c-78.93,0-142.89,64.12-142.89,143.05   c0,78.93,63.99,142.92,142.92,142.92l197.81,0.75l215.1,0.82h61.15"/>
          <path style={{fill:'none', stroke:'#EC008C', strokeWidth:8, strokeMiterlimit:10}} d="M474.01,4329.79"/>
        </g>
        <g id="plane">
            <g>
              <g style={{opacity:0.2}}>
                <path style={{fill:'#522E91'}} d="M218.53,1189.51l2.64,94.48l5.05-2.03l17-105.26c0,0,46.76-21.9,57.43-28.86      c3.84-2.97,19.52-14.86,15.61-24.02c-0.02-0.03-0.03-0.07-0.05-0.1c-0.02-0.03-0.03-0.07-0.05-0.1      c-4.99-8.62-23.91-3.2-28.59-1.91c-11.97,4.36-57.54,28.65-57.54,28.65l-94.43-49.52l-4.65,2.83l74.03,58.77      c0,0-22.05,13.27-33.16,20.49c-11.11,7.22-36.15,25.23-36.15,25.23l-30.92-12.52l-0.53,2.09l23.12,20.46l3.41,26.89l1.99,0.83      l7.63-28.37c0,0,29.43-9.23,41.87-13.8C194.69,1199.2,218.53,1189.51,218.53,1189.51z"/>
              </g>
            </g>
        </g>
        <path id="plane-path" fill="transparent" d="M174.494 1627.7C94.0651 1657.24 76.7014 1701.92 78.0733 1720.56C78.0733 1771.58 163.52 1809.32 206.243 1821.82C288.083 1853.59 423.386 1861.54 480.808 1861.54C594.632 1866.01 725.388 1836.92 776.538 1821.82C880.955 1790.04 904.316 1741.07 902.944 1720.56C903.885 1655.67 783.79 1613.34 723.624 1600.29C632.847 1578.36 494.918 1575.86 437.301 1577.35C324.418 1577.35 215.062 1610.92 174.494 1627.7Z" stroke="transparent"/>
        <g  id="background">
        </g>
        <g id="sun">
          <g style={{opacity:0.2}} className="rotate-sun">
              <ellipse transform="matrix(0.3859 -0.9225 0.9225 0.3859 -2427.0762 2722.0791)" style={{fill:'#522E91'}} cx="831.06" cy="3184.06" rx="47.35" ry="47.35"/>
            <g>
              <polygon styl={{fill:'#522E91'}} points="831.06,3096.29 837,3128.36 825.13,3128.36    "/>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="874.95,3108.05 864.06,3138.79 853.77,3132.85     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="907.07,3140.17 882.27,3161.35 876.33,3151.06     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="918.83,3184.05 886.77,3189.99 886.76,3178.12     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="907.08,3227.93 876.34,3217.05 882.27,3206.76     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="874.96,3260.06 853.78,3235.26 864.07,3229.32     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="831.08,3271.83 825.14,3239.76 837.01,3239.76     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon styl={{fill:'#522E91'}} points="787.2,3260.08 798.08,3229.34 808.37,3235.27     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon style={{fill:'#522E91'}} points="755.07,3227.97 779.86,3206.78 785.81,3217.07     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon style={{fill:'#522E91'}} points="743.3,3184.09 775.36,3178.14 775.37,3190.02     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon style={{fill:'#522E91'}} points="755.04,3140.2 785.78,3151.08 779.85,3161.37     "/>
              </g>
            </g>
            <g>
              <g>
                <polygon style={{fill:'#522E91'}} points="787.15,3108.07 808.34,3132.86 798.05,3138.8     "/>
              </g>
            </g>
          </g>
         
        </g>
        <g id="clouds">
          <path id="XMLID_00000089542071515361638180000012227052148281000343_" className="move-back-forth" style={{fill:'#E0D9F4'}} d="M455.84,315.22   c-0.03,0-0.06,0-0.09,0c-1.01-10.85-11.01-19.38-23.2-19.38c-7.83,0-14.76,3.52-18.98,8.91c-3.36-7.93-11.82-13.56-21.73-13.56   c-12.01,0-21.89,8.27-23.15,18.89c-3.16-8.28-11.79-14.24-21.98-14.24c-7.83,0-14.76,3.52-18.98,8.91   c-3.36-7.93-11.82-13.56-21.73-13.56c-12.86,0-23.29,9.48-23.29,21.18c0,0.99,0.08,1.95,0.22,2.9c-0.55-0.03-1.1-0.06-1.65-0.06   c-12.86,0-23.29,9.48-23.29,21.18s10.43,21.18,23.29,21.18h85.84h4.02h85.84v-0.03c12.33-0.54,22.15-9.81,22.15-21.16   C479.13,324.7,468.7,315.22,455.84,315.22z"/>
          <path id="XMLID_1055_" style={{fill:'#E0D9F4'}} d="M248.94,1083.95c0-15.05-13.96-27.25-31.18-27.25c-0.04,0-0.08,0-0.12,0   c-1.35-13.96-14.74-24.92-31.06-24.92c-10.49,0-19.76,4.53-25.41,11.46c-4.5-10.2-15.83-17.45-29.1-17.45   c-17.22,0-31.18,12.2-31.18,27.25c0,1.27,0.11,2.51,0.3,3.73c-0.73-0.04-1.47-0.08-2.21-0.08c-17.22,0-31.18,12.2-31.18,27.25   c0,15.05,13.96,27.25,31.18,27.25h120.31v-0.03C235.8,1110.47,248.94,1098.56,248.94,1083.95z"/>
          <path id="XMLID_00000111891635146161182700000013815750727432016284_" className="move-back-forth" style={{fill:'#E0D9F4'}} d="M207.68,1441.32   c0-14.79-13.19-26.79-29.45-26.79c-0.04,0-0.08,0-0.11,0c-1.27-13.73-13.92-24.51-29.34-24.51c-9.91,0-18.66,4.45-24,11.27   c-4.26-10.03-14.95-17.15-27.48-17.15c-16.27,0-29.45,11.99-29.45,26.79c0,1.25,0.1,2.47,0.28,3.67c-0.69-0.04-1.38-0.07-2.09-0.07   c-16.27,0-29.45,11.99-29.45,26.79c0,14.79,13.19,26.79,29.45,26.79h113.64v-0.03C195.27,1467.4,207.68,1455.68,207.68,1441.32z"/>
          <path id="gemeenten-cloud" className="move-back-forth" style={{fill:'#E0D9F4'}} d="M872.41,4067.06   c-0.03,0-0.06,0-0.09,0c-1.01-10.43-11.01-18.62-23.2-18.62c-7.83,0-14.76,3.38-18.98,8.56c-3.36-7.62-11.82-13.03-21.73-13.03   c-12.01,0-21.89,7.94-23.15,18.15c-3.16-7.96-11.79-13.68-21.98-13.68c-7.83,0-14.76,3.38-18.98,8.56   c-3.36-7.62-11.82-13.03-21.73-13.03c-12.86,0-23.29,9.11-23.29,20.35c0,0.95,0.08,1.88,0.22,2.79c-0.55-0.03-1.09-0.06-1.65-0.06   c-12.86,0-23.29,9.11-23.29,20.35s10.43,20.35,23.29,20.35h85.84h4.02h85.84v-0.02c12.33-0.52,22.15-9.42,22.15-20.33   C895.7,4076.17,885.27,4067.06,872.41,4067.06z"/>
          <path id="XMLID_00000017511876018859780850000009868497146980649887_" style={{fill:'#E0D9F4'}} d="M243.1,4752.49   c0-14.76-13.16-26.73-29.39-26.73c-0.04,0-0.08,0-0.11,0c-1.27-13.69-13.89-24.45-29.27-24.45c-9.88,0-18.62,4.44-23.95,11.25   c-4.25-10.01-14.92-17.11-27.42-17.11c-16.23,0-29.39,11.97-29.39,26.73c0,1.24,0.1,2.47,0.28,3.66c-0.69-0.04-1.38-0.07-2.08-0.07   c-16.23,0-29.39,11.97-29.39,26.73s13.16,26.73,29.39,26.73h113.37v-0.03C230.71,4778.5,243.1,4766.81,243.1,4752.49z"/>
          <path id="XMLID_00000120537530982541785050000003629818586468256901_" className="move-back-forth" style={{fill:'#E0D9F4'}} d="M353.36,2590.01   c-0.03,0-0.06,0-0.09,0c-1.01-10.85-11.01-19.38-23.2-19.38c-7.83,0-14.76,3.52-18.98,8.91c-3.36-7.93-11.82-13.56-21.73-13.56   c-12.01,0-21.89,8.27-23.15,18.89c-3.16-8.28-11.79-14.24-21.98-14.24c-7.83,0-14.76,3.52-18.98,8.91   c-3.36-7.93-11.82-13.56-21.73-13.56c-12.86,0-23.29,9.48-23.29,21.18c0,0.99,0.08,1.95,0.22,2.9c-0.55-0.03-1.1-0.06-1.65-0.06   c-12.86,0-23.29,9.48-23.29,21.18s10.43,21.18,23.29,21.18h85.84h4.02h85.84v-0.03c12.33-0.54,22.15-9.8,22.15-21.16   C376.65,2599.49,366.23,2590.01,353.36,2590.01z"/>
          <path id="XMLID_00000155843288587074163400000012117417906499529885_" className="move-back-forth" style={{fill:'#E0D9F4'}} d="M831.06,3230.49   c0-21.03-18.74-38.08-41.87-38.08c-0.05,0-0.11,0-0.16,0c-1.81-19.51-19.79-34.83-41.71-34.83c-14.08,0-26.53,6.33-34.12,16.02   c-6.05-14.26-21.25-24.38-39.07-24.38c-23.12,0-41.87,17.05-41.87,38.08c0,1.77,0.14,3.51,0.4,5.22c-0.98-0.06-1.97-0.11-2.97-0.11   c-23.12,0-41.87,17.05-41.87,38.08s18.74,38.08,41.87,38.08h161.53v-0.05C813.42,3267.55,831.06,3250.9,831.06,3230.49z"/>
          <path className="move-back-forth" opacity="0.38" d="M269.39 5252.79C269.39 5236.93 254.48 5224.07 236.08 5224.07C236.04 5224.07 235.99 5224.07 235.95 5224.07C234.51 5209.36 220.21 5197.8 202.77 5197.8C191.57 5197.8 181.66 5202.57 175.62 5209.88C170.81 5199.12 158.71 5191.49 144.54 5191.49C126.14 5191.49 111.23 5204.35 111.23 5220.21C111.23 5221.55 111.34 5222.86 111.55 5224.15C110.77 5224.1 109.98 5224.07 109.19 5224.07C90.7899 5224.07 75.8799 5236.93 75.8799 5252.79C75.8799 5268.65 90.7899 5281.51 109.19 5281.51H237.7V5281.47C255.34 5280.74 269.39 5268.18 269.39 5252.79Z" fill="#E0D9F4"/>
        </g>
        <g id="extra">
          <g id="faces">
            <path style={{fill:'#FFFFFF'}} d="M722.32,1947.96h7.47c1.29,0.27,2.27,1.4,2.27,2.77c0,1.54-1.22,2.79-2.76,2.84    c0.03,0,0.06,0,0.08,0h0.21c-6.91,0.09-12.49,5.72-12.49,12.66c0,7,5.67,12.67,12.67,12.67h47.95c7,0,12.67-5.67,12.67-12.67    c0-6.94-5.58-12.56-12.49-12.66c0.03,0,0.05,0,0.08,0c-1.53-0.04-2.75-1.3-2.75-2.84c0-1.37,0.97-2.51,2.27-2.77h47.75    c7,0,12.67-5.67,12.67-12.67c0-7-5.67-12.67-12.67-12.67h-14.05c-1.3-0.26-2.29-1.4-2.29-2.78c0-1.54,1.23-2.79,2.76-2.84    c-0.03,0-0.05,0-0.08,0h-0.21c6.91-0.1,12.49-5.72,12.49-12.66c0-6.99-5.67-12.67-12.67-12.67h-62.85c-7,0-12.66,5.67-12.66,12.67    c0,7,5.67,12.67,12.66,12.67h14.03c1.5,0.07,2.7,1.31,2.7,2.83c0,1.38-0.98,2.52-2.29,2.78h-40.45c-6.99,0-12.67,5.67-12.67,12.67    C709.65,1942.29,715.32,1947.96,722.32,1947.96z"/>
            <g id="_x33__x5F_werken_x5F_bij_00000093162179920091644490000001419216049036246937_">
              <g>
                <g>
                  <path fill="#9381BD" d="M825.4,1946.96c-0.88,3.31-0.01,6.42,1.96,6.95c1.97,0.53,4.28-1.73,5.16-5.04       c0.89-3.31,0.01-6.42-1.96-6.95C828.6,1941.4,826.29,1943.65,825.4,1946.96z"/>
                  <path fill="#9381BD" d="M792.44,1946.96c0.89,3.31,0.01,6.42-1.96,6.95c-1.97,0.53-4.28-1.73-5.17-5.04       c-0.89-3.31-0.01-6.42,1.96-6.95C789.24,1941.4,791.56,1943.65,792.44,1946.96z"/>
                  <path fill="#9381BD" d="M804.27,1968.4c0.39,0.1,0.77,0.19,1.16,0.27c10.78,2.21,20.98-5.88,21.75-16.86l1.33-16.27       c0-10.56-8.28-19.46-18.84-19.63c-0.39-0.01-0.77-0.01-1.16-0.01c-10.62,0.01-19.04,7.25-18.84,17.87       c0.14,7.12,0.69,12.23,1.2,18.39C791.51,1959.84,796.81,1966.47,804.27,1968.4z"/>
                  <path fill="#99DFF9" d="M787.38,1966.1c1.46,0.4,3.05,0.59,4.82,0.51c2.5-0.11,4.9-0.64,7.29-1.25       c-4.57-3.79-6.55-9.81-5.91-18.19l10.88-17.9l19.34,9.67l1.24,20.06c-1.17,1.79-2.39,3.26-3.62,4.47c2.4,0.1,4.8,0.2,7.19,0.18       c1.21-0.01,2.87,0.02,4.62-0.09c-0.82-6.42-1.78-12.48-2.23-16.68c-3.75-35.05-13.06-33.23-23.22-32.94       c-3.17,0.09-6.1,0.31-7.9,2.93c-7.08-2.32-13.78,4.17-12.49,28.7V1966.1z"/>
                  <path fill="#4B2D90" d="M800.75,1944.82c0-0.79,0.64-1.43,1.43-1.43c0.79,0,1.43,0.64,1.43,1.43       c0,0.79-0.64,1.43-1.43,1.43C801.39,1946.25,800.75,1945.61,800.75,1944.82z"/>
                  <path fill="#4B2D90" d="M814.68,1944.82c0-0.79,0.64-1.43,1.43-1.43c0.79,0,1.43,0.64,1.43,1.43       c0,0.79-0.64,1.43-1.43,1.43C815.32,1946.25,814.68,1945.61,814.68,1944.82z"/>
                  
                    <linearGradient id="SVGID_00000140735899043546043170000015679815390090610109_" gradientUnits="userSpaceOnUse" x1="806.3546" y1="1957.7753" x2="811.467" y2="1957.7753">
                    <stop offset="0" style={{stopColor:'#664DA1'}}/>
                    <stop offset="0.1721" style={{stopColor:'#5E449C'}}/>
                    <stop offset="0.4433" style={{stopColor:'#4B2D90'}}/>
                    <stop offset="0.8284" style={{stopColor:'#4B2D90'}}/>
                  </linearGradient>
                  
                    <path style={{fill:'none',stroke:'url(#SVGID_00000140735899043546043170000015679815390090610109_)', strokeWidth:0.5, strokeMiterlimit:10}} d="       M806.54,1957.4c1.07,1.24,3.2,1.16,4.76-0.19"/>
                </g>
                <g>
                  <g>
                    <path fill="#F466BA" d="M740.25,1922.99c0,8.61-6.65,15.59-14.86,15.59c-8.21,0-14.86-6.98-14.86-15.59        s6.65-15.59,14.86-15.59C733.59,1907.4,740.25,1914.38,740.25,1922.99z"/>
                    <path fill="#4B2D90" d="M718.69,1923.58c0-0.61,0.5-1.11,1.11-1.11c0.61,0,1.11,0.5,1.11,1.11        c0,0.61-0.5,1.11-1.11,1.11C719.18,1924.69,718.69,1924.19,718.69,1923.58z"/>
                    <path fill="#4B2D90" d="M729.51,1923.58c0-0.61,0.5-1.11,1.11-1.11c0.61,0,1.11,0.5,1.11,1.11        c0,0.61-0.5,1.11-1.11,1.11C730.01,1924.69,729.51,1924.19,729.51,1923.58z"/>
                    
                      <linearGradient id="SVGID_00000038402022001233415300000009044438039207516344_" gradientUnits="userSpaceOnUse" x1="721.1012" y1="1933.1504" x2="728.4" y2="1933.1504">
                      <stop offset="0" style={{stopColor:'#664DA1'}}/>
                      <stop offset="0.1721" style={{stopColor:'#5E449C'}}/>
                      <stop offset="0.4433" style={{stopColor:'#4B2D90'}}/>
                      <stop offset="0.8284" style={{stopColor:'#4B2D90'}}/>
                    </linearGradient>
                    
                      <path style={{fill:'none',stroke:'url(#SVGID_00000038402022001233415300000009044438039207516344_)',strokeWidth:0.5,strokeMiterlimit:10}} d="        M721.32,1932.12c1.15,2.08,4.26,2.56,6.96,1.07"/>
                    <path fill="#99DFF9" d="M715.64,1918.27l5.24-5.85l13.73,6.42l-0.08,21.77h4.04c5.24-1,6.22-7.79,5.35-14.02        c-2.86-20.65-13.39-21.69-19.59-21.52c-1.93,0.05-3.72,1.03-4.82,2.63l0,0c-4.32-1.42-11.48,1.76-12.96,17.84v4.28        c0,5.05,4.09,10.31,9.14,10.27"/>
                  </g>
                </g>
                <g>
                  <path fill="#FDE5F3" d="M768.1,1919.77c0.31,0.08,0.61,0.15,0.92,0.21c8.58,1.76,16.7-4.68,17.31-13.42l1.15-14.12       c0-8.41-6.68-15.39-15.09-15.53c-0.31,0-0.62-0.01-0.92-0.01c-8.45,0.01-15.15,6.84-14.99,15.29       c0.11,5.67-3.18,10.67-2.78,15.56C754.21,1913.88,762.16,1918.23,768.1,1919.77z"/>
                  <g>
                    
                      <linearGradient id="SVGID_00000093888183520409743740000001439620639808810169_" gradientUnits="userSpaceOnUse" x1="768.9373" y1="1909.8453" x2="777.5375" y2="1909.8453">
                      <stop offset="0" style={{stopColor:'#664DA1'}}/>
                      <stop offset="0.1721" style={{stopColor:'#5E449C'}}/>
                      <stop offset="0.4433" style={{stopColor:'#4B2D90'}}/>
                      <stop offset="0.8284" style={{stopColor:'#4B2D90'}}/>
                    </linearGradient>
                    
                      <path style={{fill:'none',stroke:'url(#SVGID_00000093888183520409743740000001439620639808810169_)',strokeWidth:0.5,strokeMiterlimit:10}} d="        M769.16,1909.11c1.06,1.93,4.76,1.93,8.26,0"/>
                  </g>
                  <g>
                    <path fill="#4B2D90" d="M767.04,1897.66c0,0.88-0.65,1.6-1.46,1.6c-0.81,0-1.46-0.71-1.46-1.6        c0-0.88,0.65-1.6,1.46-1.6C766.38,1896.06,767.04,1896.78,767.04,1897.66z"/>
                    <path fill="#4B2D90" d="M776.92,1897.66c0-0.88,0.66-1.6,1.46-1.6c0.81,0,1.46,0.71,1.46,1.6c0,0.88-0.66,1.6-1.46,1.6        C777.58,1899.26,776.92,1898.54,776.92,1897.66z"/>
                  </g>
                  <path style={{fill:'#522E91'}} d="M783.34,1893.27c0.95,2.46,1.58,5.05,1.9,7.67c0.32,2.62,0.33,5.28,0.01,7.9       c-0.05,0.39-0.07,0.79-0.1,1.18c1.37-0.74,2.72-1.5,4.04-2.31c1.87-1.14,3.73-2.19,5.63-3.14c-0.34-6.53-1.11-13.06-3.5-19.12       c-3.73-9.42-11.54-16.8-22.06-16.51c-2.78,0.08-5.35,1.48-6.93,3.78c-7.83-2.57-13.04,5.47-15.08,11.82       c-2.47,7.69-2.75,16.03-1.04,23.92c2.9,1.43,5.55,2.25,9.44,3.13c0.54,0.12,1.08,0.22,1.61,0.32       c-0.97-3.76-1.43-7.65-1.34-11.53c0.18-8.52,2.54-14.77,8.36-20.88c0.13-0.14,5.44,2.43,6,2.67c3.1,1.33,5.84,3.35,8.51,5.38       C781.13,1889.31,782.28,1890.52,783.34,1893.27z"/>
                </g>
              </g>
            </g>
            <g>
              <path style={{fill:'#866DB2'}} d="M746.75,1983.81c0.29,0.07,0.57,0.14,0.85,0.2c7.96,1.63,15.48-4.34,16.05-12.44l1.07-13.09     c0-7.79-6.19-14.27-13.99-14.4c-0.29-0.01-0.57-0.01-0.86-0.01c-7.84,0.01-14.05,6.35-13.9,14.18c0.1,5.25,0.51,9.03,0.89,13.57     C737.33,1977.49,741.24,1982.38,746.75,1983.81z"/>
              <path style={{fill:'#866DB2'}} d="M754.38,1986.07c0,1.47-7.58,1.47-7.58,0v-5.56h7.58V1986.07z"/>
              <path style={{fill:'#00AEEF'}} d="M738.59,1964.07c0.63,2.34,0,4.54-1.39,4.91c-1.39,0.37-3.03-1.22-3.65-3.56     c-0.63-2.34-0.01-4.54,1.39-4.91C736.33,1960.13,737.97,1961.73,738.59,1964.07z"/>
              <path style={{fill:'#00AEEF'}} d="M763.85,1964.07c-0.63,2.34-0.01,4.54,1.39,4.91c1.39,0.37,3.03-1.22,3.65-3.56     c0.63-2.34,0.01-4.54-1.39-4.91C766.11,1960.13,764.48,1961.73,763.85,1964.07z"/>
              <path style={{fill:'#522E91'}} d="M757.62,1945.72L757.62,1945.72c5-1.64,13.31,2.04,15.02,20.67v4.96     c0,5.86-7.12,11.52-12.97,11.47l-5.16-0.67c5.54-2.29,8.16-7.2,7.56-15.02l-19.37-12.46l-3.2,6.64l0.3,14.59     c2.3,3.53,4.97,5.15,7,6.15v6.12l-6.78,1.49l0.32,11.88l-3.05-0.49c-6.07-1.15-6.58-27.87-5.57-35.09     c3.32-23.93,13.13-23.5,20.32-23.29C754.28,1942.73,756.35,1943.87,757.62,1945.72z"/>
              <path style={{fill:'#522E91'}} d="M743.71,1967.64c0-0.58,0.47-1.05,1.05-1.05c0.58,0,1.05,0.47,1.05,1.05s-0.47,1.05-1.05,1.05     C744.18,1968.69,743.71,1968.22,743.71,1967.64z"/>
              <path style={{fill:'#522E91'}} d="M753.99,1967.64c0-0.58,0.47-1.05,1.05-1.05c0.58,0,1.05,0.47,1.05,1.05s-0.47,1.05-1.05,1.05     C754.46,1968.69,753.99,1968.22,753.99,1967.64z"/>
              <path style={{fill:'none',stroke:'#522E91',strokeMiterlimit:10}} d="M746.21,1975.75c1.09,1.98,4.05,2.43,6.61,1.02"/>
            </g>
          </g>

          <g id="mobile-move-right-top">
       
              <path style={{fill:"#7558A7"}} d="M547.59,1894.42v13.76c0,0.91-0.74,1.65-1.65,1.65h-23.13c-0.91,0-1.65-0.74-1.65-1.65v-48.74     c0-0.91,0.74-1.65,1.65-1.65h23.13c0.91,0,1.65,0.74,1.65,1.65v5.58"/>
              <polyline style={{fill:'#00AEEF'}} points="544.64,1894.42 544.64,1902.4 524.1,1902.4 524.1,1864.82 544.64,1864.82    "/>
              <polyline style={{fill:'#66CEF5'}} points="524.1,1902.4 524.1,1864.82 544.64,1864.82"/>
              <path style={{fill:'#7BC143'}} d="M533.3,1905.91c0-0.59,0.48-1.08,1.08-1.08s1.08,0.48,1.08,1.08c0,0.59-0.48,1.08-1.08,1.08    S533.3,1906.5,533.3,1905.91z"/>
              <circle style={{fill:'#522E91'}} cx="555.29" cy="1881.49" r="17.98"/>
              <path id="f_00000170964719484373613340000005370056303851645568_" style={{fill:'#FFFFFF'}} d="M562.42,1893l-4.77-11.24l3.77-1.6    l-1.29-4.62l-4.34,1.84l-1.19-2.8c-0.54-1.27-0.55-2.28,1.27-3.05l2.32-0.99l-1.66-3.92c-0.42,0.12-1.85,0.58-3.45,1.26    c-3.34,1.42-4.77,4.43-3.17,8.18l1.37,3.23l-3.78,1.6l1.86,4.38l3.78-1.6l4.77,11.24L562.42,1893z"/>
              <rect x="381.95" y="1921.95" style={{fill:'none'}} width="303.54" height="101.6"/>
              <text transform="matrix(1 0 0 1 441.0686 1932.4525)"><tspan x="0" y="0" style={{fontFamily:'Sansa', fontSize:'14px'}} className="text-responsiveness">Nieuwe Facebookgroep: </tspan><tspan x="-18.57" y="17" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">Ouders met basisschoolkinderen</tspan><tspan x="49.22" y="34" fill="#522E91" className="extra-numbers-top text-responsiveness" style={{fontFamily:'Sansa',fontWeight:'bold', fontSize:'14px'}} >300</tspan><tspan x="89.19" y="34" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">leden </tspan></text> 

          </g> 

          <g id="mobile-move-right-mid">
          <rect x="451" y="2544.07" style={{fill:'none'}} width="173.52" height="74.35"/>
          <text transform="matrix(1 0 0 1 486.7898 2562.8201)"><tspan x="0" y="0" className="extra-numbers-thousand-mid" style={{fill:'#522E91', fontFamily:'Sansa',fontWeight:'bold', fontSize:'25px'}}>786000 </tspan><tspan x="-14.13" y="17" style={{fontFamily:'Sansa', fontSize:'12px'}}> </tspan><tspan x="-10.94" y="17" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">mensen bereikt via </tspan><tspan x="7.67" y="34" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">social media</tspan></text>
            <g id="_x33__x5F_social_x5F_media_00000045582996057057817610000018375781643042912403_">
              <path style={{fill:'#FFFFFF'}} d="M640.16,2628.06h-5.65c-0.98-0.2-1.72-1.06-1.72-2.1c0-1.17,0.93-2.11,2.09-2.15     c-0.02,0-0.04,0-0.06,0h-0.16c5.24-0.07,9.46-4.33,9.46-9.59c0-5.3-4.29-9.59-9.59-9.59h-36.31c-5.3,0-9.59,4.29-9.59,9.59     c0,5.25,4.22,9.52,9.46,9.59c-0.02,0-0.04,0-0.06,0c1.16,0.03,2.09,0.98,2.09,2.15c0,1.04-0.74,1.9-1.72,2.1h-36.16     c-5.3,0-9.59,4.29-9.59,9.59c0,5.3,4.29,9.59,9.59,9.59h10.64c0.99,0.2,1.73,1.06,1.73,2.1c0,1.17-0.93,2.11-2.09,2.15     c0.02,0,0.04,0,0.06,0h0.16c-5.23,0.07-9.46,4.33-9.46,9.59c0,5.3,4.29,9.59,9.59,9.59h47.6c5.3,0,9.59-4.3,9.59-9.59     c0-5.3-4.29-9.59-9.59-9.59h-10.62c-1.14-0.06-2.04-0.99-2.04-2.14c0-1.04,0.74-1.91,1.73-2.1h30.63c5.3,0,9.59-4.29,9.59-9.59     C649.76,2632.35,645.46,2628.06,640.16,2628.06z"/>
              <g>
                <polygon fill="#816CB2" points="648.68,2587.56 653.02,2590.19 645.35,2592.81 642.69,2588.1 647.97,2581.24     "/>
                <path fill='#33BEF2' d="M610.87,2604.61c23.91,20.15,35.39,0.71,36.78-3.55c1.66-5.07,2.09-13.03-5.02-16.9      c-7.41-4.03-13.85,0.42-15.66,5.43C625.71,2593.07,625.67,2604.77,610.87,2604.61z"/>
                <path fill="#80D6F7" d="M612.97,2591.14c0,0,9.55-1.72,13.58,0.23c4.02,1.95,6.02,6.02,4.45,9.09      c-1.57,3.07-6.1,3.97-10.13,2.02C616.84,2600.52,612.97,2591.14,612.97,2591.14z"/>
                <path style={{fill:'#FFFFFF'}} d="M636.14,2590.08c0-1.51,1.22-2.73,2.73-2.73c1.51,0,2.73,1.22,2.73,2.73      c0,1.51-1.22,2.73-2.73,2.73C637.37,2592.81,636.14,2591.59,636.14,2590.08z"/>
              </g>
            </g>
            <g>
              <g>
                <path style={{fill:"#7558A7"}} d="M599.72,2690.07v13.76c0,0.91-0.74,1.65-1.65,1.65h-23.13c-0.91,0-1.65-0.74-1.65-1.65v-48.74      c0-0.91,0.74-1.65,1.65-1.65h23.13c0.91,0,1.65,0.74,1.65,1.65v5.58"/>
                <polyline style={{fill:'#00AEEF'}} points="596.77,2690.07 596.77,2698.05 576.23,2698.05 576.23,2660.47 596.77,2660.47     "/>
                <polyline style={{fill:'#66CEF5'}} points="576.23,2698.05 576.23,2660.47 596.77,2660.47     "/>
              </g>
              <path style={{fill:'#7BC143'}} d="M585.42,2701.56c0-0.59,0.48-1.08,1.08-1.08c0.59,0,1.08,0.48,1.08,1.08     c0,0.59-0.48,1.08-1.08,1.08C585.91,2702.64,585.42,2702.15,585.42,2701.56z"/>
            </g>
            <g className="linkedin">
              <path style={{fill:'#522E91'}} d="M536.3,2633.57c0.35,10.02,8.76,17.87,18.78,17.52c10.03-0.35,17.87-8.76,17.52-18.78     c-0.35-10.02-8.76-17.87-18.78-17.52C543.8,2615.14,535.95,2623.55,536.3,2633.57z"/>
              <path style={{fill:'#FFFFFF'}} d="M552.5,2640.54l-3.13,1.33l-4.28-10.08l3.13-1.33L552.5,2640.54 M546.07,2629.75     c-1,0.43-2.16-0.04-2.59-1.05c-0.43-1,0.04-2.16,1.05-2.59c1-0.43,2.16,0.04,2.59,1.05     C547.54,2628.16,547.07,2629.32,546.07,2629.75z M564.24,2635.56l-3.13,1.33l-2.08-4.9c-0.5-1.17-1.16-2.67-2.76-1.98     c-1.63,0.69-1.34,2.07-0.78,3.39l2.12,4.99l-3.13,1.33l-4.28-10.08l3.01-1.28l0.58,1.38l0.04-0.02c0.08-0.97,0.75-2.24,2.27-2.89     c3.17-1.35,4.65,0.49,5.8,3.21L564.24,2635.56z"/>
            </g>
            <g className="facebook">
              <g>
                <circle style={{fill:'#522E91'}} cx="621.35" cy="2675.39" r="17.98"/>
              </g>
              <path id="f_00000023985834286614999090000004315397907161045125_" style={{fill:'#FFFFFF'}} d="M628.48,2686.9l-4.77-11.24l3.77-1.6     l-1.29-4.62l-4.34,1.84l-1.19-2.8c-0.54-1.27-0.55-2.28,1.27-3.05l2.32-0.99l-1.66-3.92c-0.42,0.12-1.85,0.58-3.45,1.26     c-3.34,1.42-4.77,4.43-3.17,8.18l1.37,3.23l-3.78,1.6l1.86,4.38l3.78-1.6l4.77,11.24L628.48,2686.9z"/>
            </g>
          </g>
          
          <rect x="259.13" y="3230.98" style={{fill:'none'}} width="169.09" height="67.29"/>
          <text transform="matrix(1 0 0 1 320.2388 3249.7346)"><tspan x="0" y="0" className="extra-numbers-lower" style={{fill:'#522E91', fontFamily:'Sansa',fontWeight:'bold', fontSize:'25px'}}>606 </tspan><tspan x="-26.62" y="16" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">aanmeldingen </tspan><tspan x="-28.89" y="32" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">Puberchallenge</tspan></text>
          <rect x="66.74" y="3197.34" style={{fill:'none'}} width="169.09" height="67.29"/>
          <text transform="matrix(1 0 0 1 100.315 3216.0918)"><tspan x="0" y="0" className="extra-numbers-thousand-lower" style={{fill:'#522E91', fontFamily:'Sansa',fontWeight:'bold', fontSize:'25px'}}>297000</tspan><tspan x="-10.21" y="16" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">bezoekers website </tspan><tspan x="11.14" y="32" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">voor ouders</tspan></text>
          <g>
            <g>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M224.27,3185.52c0,0-11.37,15.59,4.14,28.73     c15.51,13.14,13.13-0.98,13.13-0.98S235.68,3187.95,224.27,3185.52z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M270.62,3296.99h-58.96c-2.41,0-4.36-1.95-4.36-4.36v-4.18     c0-2.41,1.95-4.36,4.36-4.36h58.96c2.41,0,4.36,1.95,4.36,4.36v4.18C274.98,3295.04,273.03,3296.99,270.62,3296.99z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M287.13,3350.85L287.13,3350.85c0.75-0.3,1.11-1.15,0.8-1.89     l-26.42-65.42c-0.3-0.75-1.15-1.11-1.89-0.8l0,0c-0.75,0.3-1.11,1.15-0.8,1.89l26.42,65.42     C285.54,3350.79,286.39,3351.15,287.13,3350.85z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M231.6,3350.85L231.6,3350.85c-0.75-0.3-1.11-1.15-0.8-1.89     l26.42-65.42c0.3-0.75,1.15-1.11,1.89-0.8l0,0c0.75,0.3,1.11,1.15,0.8,1.89l-26.42,65.42     C233.2,3350.79,232.35,3351.15,231.6,3350.85z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M197.63,3350.85L197.63,3350.85     c-0.75-0.3-1.11-1.15-0.8-1.89l26.42-65.42c0.3-0.75,1.15-1.11,1.89-0.8l0,0c0.75,0.3,1.11,1.15,0.8,1.89l-26.42,65.42     C199.23,3350.79,198.38,3351.15,197.63,3350.85z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M251.99,3350.85L251.99,3350.85c0.75-0.3,1.11-1.15,0.8-1.89     l-26.42-65.42c-0.3-0.75-1.15-1.11-1.89-0.8l0,0c-0.75,0.3-1.11,1.15-0.8,1.89l26.42,65.42     C250.4,3350.79,251.25,3351.15,251.99,3350.85z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M231.22,3256.79c0,0-2.32,17.3-1.92,20.54     c0.75,6.08,1.32,9.64,1.32,9.64l24.37,0.28c6.96,0.08,12.08-7.12,10.76-15.13c-0.01-0.04-0.01-0.08-0.02-0.11     c-0.13-0.76-0.24-1.53-0.34-2.3c-0.48-3.6-3.43-9.77-3.43-9.77c-7.47,5.56-19.64,2.99-31.72-1.03L231.22,3256.79z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M212.31,3342.72c-0.1-1.58-7.9-51.71-7.34-55.31     c0.63-4.07,3.52-7.06,6.92-8.75l18.32-12.5l28.23,16.71l-38.87,10.8c3.71,11.9-1.08,33.06-1.7,49.09L212.31,3342.72z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M229.31,3341.5c-0.48-1.3-6.28-33.77-5.53-46.54     c0.21-3.53,2.15-6.75,5.41-8.99l26.02-17.84l3.94,18.22l-19.83,11.94c3.14,11.9-2.38,28.35-5.16,43.96L229.31,3341.5z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M261.97,3234.45c-0.87-8.87,5.88-13.22,0.49-17.6     c-3.88-1.66-13.21-3.86-13.21-3.86l-2.68-0.23c0,0-0.4-0.07-1.01-0.18c-3.24-0.57-7.37,0.7-12.02,3.99     c-6.63,4.07-0.3,5.78-4.66,14.44c-3.69,7.32,2.82,14.17,2.82,14.17l-0.67,22.5c0,0,36.16,9.48,33.57-3.01L261.97,3234.45z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M266.88,3229.34l4.38,24.24c1.67,3.73-0.22,8.53-4.29,10.95     l-17.19,9.47l-2.14-4.08l14.13-11.68l-0.97-21.83C260.81,3236.41,262.57,3231.94,266.88,3229.34z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M263.91,3218.43c0.3,0.63,7.82,23.83,7.82,23.83     s-11.92,9.06-13.55,4.17s-1.1-22.83-1.1-22.83L263.91,3218.43z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M212.31,3342.72l-5.27,3.88c-1.02,0.92-2.27,1.46-3.57,1.56     l-1.32,0.1c-0.59,0.04-1.1,0.49-1.28,1.1l0,0c-0.24,0.84,0.25,1.69,1.05,1.82l14.58-0.62c0,0,3.46-0.82,1.38-7.79L212.31,3342.72     z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M239.26,3204.22c0,0,1.3,7.35,2.37,13.44     c0.29,1.62,1.42,2.96,2.98,3.51c1.55,0.55,3.28,0.21,4.52-0.87c0.41-0.36,0.8-0.7,1.17-1.03c1.7-1.49,2.39-3.82,1.76-6     c-1.59-5.57-4.49-15.71-4.49-15.71L239.26,3204.22L239.26,3204.22z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M248.45,3187.36c-0.79-3.38-4.17-5.48-7.55-4.69     c-3.61,0.84-8.15,1.91-11.76,2.75c-3.38,0.79-4.94,4.09-4.69,7.55c0.3,4.08,2.83,12.12,5.36,17.72c1.43,3.16,4.77,5.6,8.15,4.81     c3.61-0.84,13.61-6.81,13.15-13.5C250.76,3196.91,249.62,3192.33,248.45,3187.36L248.45,3187.36z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M259.13,3193.75c0,0-3.31-12.99-10.03-15.64     c-9.03-3.56-19.16-3.39-25.43,8.46C223.67,3186.57,229.97,3206.21,259.13,3193.75L259.13,3193.75z"/>
              
                <ellipse transform="matrix(0.1185 -0.993 0.993 0.1185 -2955.4993 3069.5847)" style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} cx="251.11" cy="3199.39" rx="5.16" ry="4.27"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#522E91'}} d="M229.31,3341.5c0,0-1.6,4.61-1.9,7.52     c-0.05,0.5,0.15,1.05,0.54,1.47c0.39,0.43,0.91,0.68,1.41,0.68c1.07,0,2.3-0.01,3.06-0.01c0.47,0,0.9-0.17,1.2-0.47     c0.18-0.18,0.41-0.4,0.64-0.63c1.07-1.05,0.79-2.28,0.44-4.01c-0.36-1.79-0.53-3.81-0.53-3.81     C231.75,3340.08,229.31,3341.5,229.31,3341.5z"/>
              <polygon fill='#9A85D4' points="237.1,3283.78 203.59,3279.33 222.35,3268.94 256.12,3273.89    "/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M230.73,3267.29c0,0-1.29-0.9-4.02-0.06     c-3.54,1.09-3.26,0.48-3.15,3.67c0.03,0.89,1.92,2.64,1.92,2.64l5.02,1c0,0,5.33,3.07,6.48-0.75     C237.8,3271.1,230.73,3267.29,230.73,3267.29z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M231.7,3233.47l-7.28-1.84l-3.96,17.55     c-0.63,1.62-0.77,3.4-0.38,5.1l2.84,12.59c0,0,7.95,3.85,7.95,1.26c0-11.21-1.61-14.7-1.61-14.7l7.26-23.74L231.7,3233.47z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#33BEF2'}} d="M240.55,3226.52c-0.21,0.69-0.84,19.36-5.24,18.67     s-15.3-3.15-14.32-8.21c2.51-13.03,10.03-18.32,10.03-18.32L240.55,3226.52z"/>
              <path fill='#33BEF2' d="M232.91,3238.9c0,0-7.62,4.57-7.62,14.28c0,0,0.69,17.23,16.27,18.12L232.91,3238.9z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#7558A7'}} d="M242.59,3269.86c0,0-1.57,0.11-3.16,2.48     c-2.07,3.07-2.23,2.42-0.14,4.84c0.58,0.68,3.15,0.84,3.15,0.84l4.53-2.38c0,0,6.07-0.97,4.57-4.66     C250.48,3268.38,242.59,3269.86,242.59,3269.86z"/>
              <path style={{fill:'#FFFFFF'}} d="M237.1,3283.78l-33.52-4.45l-4.84-21.19c-0.39-1.72,1.29-3.28,3.24-3l25.33,3.54     c2.52,0.35,4.52,2.05,5.02,4.26L237.1,3283.78z"/>
              <path style={{fillRule:'evenodd',clipRule:'evenodd',fill:'#9A85D'}}  d="M219.92,3269.03c0.53,1.88-0.92,3.4-3.25,3.4     s-4.64-1.52-5.17-3.4c-0.53-1.88,0.92-3.4,3.25-3.4S219.39,3267.15,219.92,3269.03z"/>
            </g>
            <path style={{fill:'#522E91'}} d="M232.01,3247.5c3.06-6.67,9.32,1.98,9.32,1.98s6.26-8.65,9.32-1.98    c3.27,7.12-9.32,11.24-9.32,11.24S228.74,3254.62,232.01,3247.5z"/>
          </g>
          <g id="mobile-move-right-bottom">
            <rect x="365.83" y="3852.4" style={{fill:'none'}} width="207.19" height="95.42"/>
            <text transform="matrix(1 0 0 1 434.0698 3874.8999)"><tspan x="0" y="0" className="extra-numbers-thousand-bottom" style={{fill:'#522E91', fontFamily:'Sansa',fontWeight:'bold', fontSize:'25px'}}>1510</tspan><tspan x="70.7" y="0" fill="#EC008C" style={{ fontFamily:'Sansa',fontWeight:'bold', fontSize:"25px"}}> </tspan><tspan x="77.35" y="0" fill="#EC008C" style={{ fontFamily:'Sansa',fontWeight:'bold', fontSize:"30px"}}> </tspan><tspan x="-34.35" y="16" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">mensen bekeken ons</tspan><tspan x="-2.6" y="32" style={{fontFamily:'Sansa',  fontSize:'14px'}} className="text-responsiveness">jaarverslag </tspan></text>
            <g>
              <g>
                <g>
                  <path style={{fill:'#522E91'}} d="M415.6,3982.27v-73.37c0-2.47-2-4.47-4.47-4.47H299.39c-2.47,0-4.47,2-4.47,4.47v73.37      L415.6,3982.27z"/>
                  <path fill="#99DFF9" d="M425.54,3989.72H284.99c-4.12,0-7.45-3.34-7.45-7.45l0,0h155.46l0,0      C432.99,3986.39,429.66,3989.72,425.54,3989.72z"/>
                </g>
                <polygon fill="#D9F3FD" points="373.53,3910.71 357.45,3910.71 337.01,3910.71 337,3910.71 300.49,3910.71      300.49,3932.78 300.49,3954.85 300.49,3976.93 337,3976.93 337.01,3976.93 373.53,3976.93 373.53,3976.9 373.54,3976.93      410.06,3976.93 410.06,3954.85 410.06,3932.78 410.06,3910.71    "/>
              </g>
            </g>
            <rect x="291.36" y="3919.9" fill="none" width="128.54" height="44.89"/>
            <text transform="matrix(1 0 0 1 312.4684 3938.6541)" className="extra-numbers-thousand-bottom" style={{fill:'#522E91', fontFamily:'Sansa',fontWeight:'bold', fontSize:'25px'}}>64000</text>
            <rect x="293.92" y="3945.82" fill="none" width="124.96" height="35.11"/>
            <text transform="matrix(1 0 0 0.99 327.8601 3954.8162)"><tspan x="0" y="0" style={{fontFamily:'Sansa', fontSize:'12px'}}>bezoekers </tspan><tspan x="-14.51" y="14.14" style={{fontFamily:'Sansa', fontSize:'12px'}}>cjgrijnmond.nl</tspan></text>
            <image xmlns="http://www.w3.org/2000/svg" overflow='visible' width="48" height="48" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAACDElE QVRoge3Yz4uMcRzA8ddi1B5EfqSwJ8UFJ6WUE+WkXCSl/AcuDk5aN8rBSTmoPThIuYmTFDmtHJRy kd/hgNJabXbt12Fmamb2+Tkzvl9T865vMzWfeXq/m2ee5+nLmDFRmcY3XMXqxC598Quhte5gbVqd +oSe9RDrkhrVpDcgYBabUkrVISsg4AW2JfSqTF5AwGvsTKdWjaKAgE/Yk8yuAmUBQfMyeyCVYBlV AgLmcDiRYyFVAwIWcDyNZj51AgIWcSaJaQ51AwKWcTaFbBb9BLTXdALfFQwSEHAZE9GtOxg0IOA6 VsUWbzOMgIBbaER2p0/ZvHUPk3H1hxsQ8BjrRzkg4Bm2jHJAwEtMjXJAwFvsGuWAgFdqXp2SXY9z mFJzo2DNPxKpwxzea55CNzBf58sxA5ZwRVP2A961Xn9EdBj4HD8aUzaLQQPux1fupkzwZ8nny9gd 3bqDIrmb2Is/JXPXolt3kCW0iPMdM3dz5tprHhvjKXfTK/MFh3pmjmTM9a5zkXxX0Lk7PYsdGTMT mluNRQFvDGl7vu5BGtiHGZzC95y5JRwrOM4GPNd8iPsvmcRXxb/Co2R2Fbmk/L+wP5ldBbbjt+KA mWR2FbmtOGABW5PZVeCg8tPoQjK7ijxVHPAgnVo1Tiu+K59Ip1aNBj7qFv+Mi9ic0KsWJzXvC09a 75Psyo0Zk8FfACvt4KI0hHIAAAAASUVORK5CYII=" transform="matrix(0.2746 0 0 0.2746 392.6967 3941.2327)"></image>
          </g>
        </g>
        {/* Rainbow 7 section */}
        <g  id="rainbow" className="rainbow-7-section">
          <g id="Group_122">
          <g id="Group_123">
          <path id="Vector_429" d="M925.97 4884.57C899.69 4822.56 825.99 4794.49 761.36 4821.88C696.73 4849.27 665.65 4921.75 691.94 4983.76C718.22 5045.77 791.92 5073.84 856.55 5046.45C921.18 5019.06 952.26 4946.58 925.97 4884.57ZM704.66 4978.37C681.23 4923.09 708.94 4858.49 766.54 4834.08C824.14 4809.67 889.83 4834.68 913.26 4889.96C936.69 4945.24 908.98 5009.84 851.38 5034.25C793.78 5058.66 728.09 5033.65 704.66 4978.37Z" fill="#ED068F"/>
          <path id="Vector_430" d="M913.261 4889.96C889.831 4834.68 824.141 4809.66 766.541 4834.08C708.941 4858.49 681.231 4923.1 704.661 4978.37C728.091 5033.65 793.781 5058.67 851.381 5034.25C908.981 5009.84 936.691 4945.23 913.261 4889.96ZM718.661 4972.43C698.381 4924.58 722.361 4868.65 772.231 4847.51C822.101 4826.37 878.971 4848.03 899.251 4895.89C919.531 4943.74 895.551 4999.67 845.681 5020.81C795.821 5041.95 738.951 5020.29 718.661 4972.43Z" fill="#F77D46"/>
          <path id="Vector_431" d="M899.26 4895.89C878.98 4848.04 822.11 4826.38 772.24 4847.51C722.37 4868.65 698.39 4924.57 718.67 4972.43C738.95 5020.28 795.82 5041.94 845.69 5020.81C895.55 4999.68 919.54 4943.75 899.26 4895.89ZM735.57 4965.27C719.08 4926.37 738.58 4880.92 779.11 4863.74C819.64 4846.56 865.86 4864.17 882.35 4903.06C898.84 4941.96 879.34 4987.41 838.81 5004.59C798.28 5021.77 752.05 5004.16 735.57 4965.27Z" fill="#7EC348"/>
          <path id="Vector_432" d="M882.35 4903.06C865.86 4864.16 819.64 4846.56 779.11 4863.74C738.58 4880.92 719.08 4926.38 735.57 4965.27C752.06 5004.17 798.28 5021.77 838.81 5004.59C879.34 4987.41 898.84 4941.95 882.35 4903.06ZM751.24 4958.62C738.28 4928.03 753.61 4892.28 785.48 4878.77C817.36 4865.26 853.71 4879.1 866.67 4909.69C879.63 4940.28 864.3 4976.03 832.43 4989.54C800.56 5003.06 764.21 4989.21 751.24 4958.62Z" fill="#522E91"/>
          </g>
          </g>
        </g>
        
      {/* End of the rainbow */}
        <g id="bird">
          <path id="XMLID_00000106146235917724775100000005778020504906705806_" style={{opacity:0.2,fill:'#522E91'}} d="M820.85,4477   c-7.7-0.98-15.46-1.97-22.93-4.01c0.31-0.42,0.61-0.83,0.86-1.29c0.25-0.45,0.81-1.48,0.71-2c0.45-0.19,0.88-0.43,1.27-0.72   c-0.48-0.17-1-0.21-1.5-0.1c0.41-0.45,0.82-0.9,1.24-1.35c-0.6,0.06-1.2,0.11-1.8,0.17c-0.52-2.46-4.52-1.58-6.12-1.14   c-3.1,0.86-6.35,1.62-9.12,3.34c-0.68,0.42-1.32,0.91-1.9,1.46c-0.55,0.52-1.17,0.87-1.78,1.33c-1.56,1.19-3.1,2.42-4.6,3.69   c-4.49,3.8-10.57,5.74-16.4,6.15c2.55,0.25,5.1,0.5,7.65,0.75c-2.47,1.36-4.45,3.58-5.53,6.18c0.22-0.53,3.62-1.4,4.26-1.67   c1.53-0.65,3.04-1.35,4.52-2.1c3.23-1.63,5.04-2.84,8.78-2.17c-2.41,1.84,1.54,2.52,1.08,4.52c0.23-0.2,0.62-0.3,0.84-0.5   c-0.14,0.73-0.16,1.51,0.07,2.23c0.26-0.28,0.52-0.56,0.78-0.85c-0.4,1.31-0.28,2.78,0.33,4c0.24-0.35,0.48-0.71,0.73-1.06   c0.28,0.51,0.19,1.13,0.07,1.7c-0.12,0.57-0.26,1.17-0.05,1.71c0.21,0.54,0.94,0.91,1.39,0.55c0.27-0.22,0.33-0.6,0.46-0.93   c0.13-0.33,0.44-0.66,0.77-0.55c-0.8,0.49-0.53,1.95,0.38,2.18c0.65,0.16,1.29-0.33,1.5-0.95c-0.03,1.53-0.06,3.06-0.09,4.59   c0.73-0.93,1.47-1.85,2.2-2.78c0.32,1.76,0.64,3.51,0.96,5.27c2.04-4.01,2.78-8.58,2.72-13.08c-0.03-2.46-0.3-4.91-0.68-7.34   c-0.08-0.51-0.12-1.22-0.19-1.98c0.29-0.04,0.57-0.09,0.85-0.15c0.01-0.47,0.01-0.93,0.02-1.4c0.63,0.53,1.32,1.09,2.14,1.16   c0.82,0.06,1.74-0.65,1.56-1.45c0.72,0.6,1.63,0.96,2.57,1.01c-0.03-0.54-0.06-1.08-0.08-1.62c0.84,0.85,1.99,1.38,3.18,1.46   c0.03-0.55,0.06-1.11,0.09-1.66c0.91,1.01,2.37,1.48,3.7,1.19c-0.25-0.66-0.5-1.32-0.76-1.99c2.02,1.04,4.26,1.66,6.53,1.81   c-0.39-0.53-0.77-1.07-1.16-1.6C813.77,4478,817.45,4478,820.85,4477z"/>
          <path id="XMLID_74_" style={{opacity:0.2,fill:'#522E91'}} d="M213.99,5353.9c-10.85-1.38-21.77-2.77-32.3-5.65   c0.43-0.58,0.85-1.17,1.2-1.81c0.35-0.63,1.14-2.08,1-2.82c0.63-0.27,1.23-0.61,1.79-1.01c-0.67-0.24-1.41-0.29-2.11-0.14   c0.58-0.64,1.16-1.27,1.74-1.91c-0.85,0.08-1.69,0.16-2.54,0.23c-0.73-3.46-6.36-2.23-8.61-1.6c-4.37,1.21-8.94,2.28-12.84,4.7   c-0.96,0.6-1.85,1.28-2.67,2.05c-0.77,0.74-1.65,1.22-2.51,1.87c-2.2,1.68-4.36,3.41-6.47,5.2c-6.32,5.36-14.89,8.08-23.09,8.66   c3.59,0.35,7.18,0.7,10.77,1.05c-3.48,1.91-6.27,5.04-7.78,8.71c0.31-0.75,5.1-1.97,5.99-2.35c2.15-0.92,4.28-1.91,6.37-2.96   c4.55-2.29,7.1-4,12.36-3.05c-3.4,2.59,2.16,3.55,1.52,6.36c0.33-0.28,0.87-0.42,1.19-0.7c-0.2,1.03-0.22,2.12,0.1,3.14   c0.37-0.4,0.73-0.79,1.1-1.19c-0.57,1.85-0.39,3.91,0.47,5.64c0.34-0.5,0.68-1,1.02-1.49c0.4,0.71,0.26,1.59,0.09,2.39   c-0.17,0.8-0.37,1.65-0.07,2.41c0.3,0.76,1.32,1.28,1.96,0.77c0.38-0.31,0.47-0.85,0.65-1.31c0.18-0.46,0.62-0.93,1.09-0.77   c-1.12,0.7-0.74,2.75,0.53,3.07c0.91,0.23,1.81-0.47,2.11-1.34c-0.04,2.15-0.09,4.31-0.13,6.46c1.03-1.3,2.07-2.61,3.1-3.91   c0.45,2.47,0.9,4.94,1.35,7.42c2.88-5.64,3.91-12.09,3.83-18.42c-0.05-3.47-0.42-6.92-0.96-10.34c-0.11-0.71-0.17-1.72-0.26-2.78   c0.4-0.05,0.8-0.12,1.2-0.21c0.01-0.66,0.02-1.31,0.03-1.97c0.89,0.75,1.86,1.54,3.02,1.63c1.16,0.09,2.46-0.91,2.19-2.04   c1.01,0.84,2.3,1.35,3.61,1.43c-0.04-0.76-0.08-1.52-0.12-2.29c1.18,1.19,2.81,1.94,4.48,2.06c0.04-0.78,0.08-1.56,0.12-2.34   c1.28,1.42,3.34,2.08,5.21,1.67c-0.36-0.93-0.71-1.86-1.07-2.8c2.85,1.47,6,2.34,9.2,2.54c-0.54-0.75-1.08-1.5-1.63-2.26   C204.03,5355.3,209.21,5355.31,213.99,5353.9z"/>
          <path id="XMLID_82_" style={{opacity:0.2,fill:'#522E91'}} d="M908.21,5279.57c0.5-0.55,1-1.09,1.49-1.64   c-0.73,0.07-1.46,0.13-2.18,0.2c-0.63-2.97-5.47-1.91-7.4-1.38c-1.5,0.42-3.03,0.82-4.54,1.28c0.27-0.68,0.42-1.41,0.43-2.15   c-0.67,0.01-1.34,0.01-2.01,0.02c1.15-1.17,1.62-2.97,1.17-4.55c-0.78,0.35-1.56,0.71-2.34,1.06c1.11-2.52,1.7-5.27,1.71-8.02   c-0.62,0.5-1.23,1.01-1.85,1.51c0.97-4.17,0.71-8.62-0.75-12.64c-0.58,8.68-1.19,17.42-2.8,25.95c-2.17-1.94-4.43-3.78-6.85-5.41   c-4.52-3.03-9.65-5.3-15.06-5.87c1.57,1.48,3.14,2.97,4.71,4.45c-1.42,0.13-2.85,0.27-4.27,0.4c1.57,0.98,3.14,1.95,4.71,2.93   c-0.76-0.19-1.69,0.13-1.95,0.89c-0.37,1.07,0.93,2.3,1.96,1.82c-0.11,0.41-0.65,0.51-1.07,0.42s-0.84-0.27-1.24-0.14   c-0.67,0.21-0.77,1.2-0.36,1.77c0.41,0.57,1.11,0.83,1.77,1.08c0.65,0.25,1.35,0.57,1.68,1.19c-0.52,0.01-1.04,0.02-1.55,0.04   c0.84,1.43,2.24,2.52,3.84,2.98c-0.46,0.08-0.92,0.16-1.37,0.23c0.6,0.73,1.43,1.27,2.34,1.53c-0.42,0.1-0.8,0.28-1.14,0.53   c0.42,0.13,0.89,0.56,1.29,0.69c-2.22,4.37-13.77,7.56-18.27,8.95c4.07-0.54,8.13-1.07,12.2-1.61c-2.56,2.11-4.48,4.99-5.46,8.16   c2.94-2.5,6.23-6.52,10.08-7.46c3.81-0.92,8.34-0.2,12.26-0.69c6.94-0.61,12.09-2.89,16.69-8.17c0.92-1.05,1.79-2.17,2.56-3.34   c0.29-0.44,0.57-0.88,0.83-1.33c0.23-0.4,0.77-2.58,1.01-2.68c0.54-0.23,1.06-0.52,1.54-0.87   C909.45,5279.49,908.81,5279.45,908.21,5279.57z"/>
          <path id="XMLID_80_" style={{opacity:0.2,fill:'#522E91'}} d="M317.56,5659.74c0.42-0.46,0.84-0.92,1.26-1.38   c-0.61,0.06-1.22,0.11-1.83,0.17c-0.53-2.5-4.59-1.61-6.22-1.16c-1.26,0.35-2.55,0.69-3.82,1.07c0.23-0.57,0.36-1.19,0.36-1.81   c-0.56,0-1.13,0.01-1.69,0.01c0.97-0.98,1.36-2.5,0.98-3.82c-0.66,0.3-1.31,0.59-1.97,0.89c0.94-2.12,1.43-4.43,1.44-6.74   c-0.52,0.42-1.04,0.85-1.56,1.27c0.82-3.5,0.59-7.24-0.63-10.63c-0.49,7.3-1,14.64-2.35,21.81c-1.82-1.63-3.73-3.18-5.76-4.54   c-3.8-2.54-8.11-4.46-12.66-4.94c1.32,1.25,2.64,2.49,3.96,3.74c-1.2,0.11-2.39,0.23-3.59,0.34c1.32,0.82,2.64,1.64,3.96,2.46   c-0.64-0.16-1.42,0.11-1.64,0.75c-0.31,0.9,0.78,1.94,1.65,1.53c-0.09,0.34-0.55,0.43-0.9,0.35c-0.35-0.07-0.71-0.23-1.05-0.12   c-0.56,0.18-0.65,1.01-0.31,1.49c0.34,0.48,0.94,0.7,1.49,0.91c0.55,0.21,1.14,0.48,1.41,1c-0.44,0.01-0.87,0.02-1.31,0.03   c0.7,1.21,1.89,2.12,3.23,2.51c-0.38,0.07-0.77,0.13-1.15,0.2c0.51,0.62,1.2,1.07,1.97,1.29c-0.35,0.08-0.67,0.23-0.96,0.44   c0.35,0.11,0.75,0.47,1.09,0.58c-1.87,3.68-11.58,6.35-15.36,7.52c3.42-0.45,6.84-0.9,10.26-1.35c-2.15,1.78-3.77,4.19-4.59,6.86   c2.47-2.1,5.24-5.48,8.48-6.27c3.21-0.78,7.01-0.17,10.31-0.58c5.83-0.51,10.17-2.43,14.03-6.87c0.77-0.89,1.5-1.82,2.16-2.81   c0.24-0.37,0.48-0.74,0.69-1.12c0.2-0.34,0.64-2.17,0.85-2.26c0.46-0.19,0.89-0.44,1.29-0.73   C318.59,5659.67,318.06,5659.63,317.56,5659.74z"/>
        </g>
        <path id="cloud-endpoint" d="M960.72 5581.04C960.72 5566.28 947.56 5554.31 931.33 5554.31C931.29 5554.31 931.25 5554.31 931.22 5554.31C929.95 5540.62 917.33 5529.86 901.95 5529.86C892.07 5529.86 883.33 5534.3 878 5541.11C873.75 5531.1 863.08 5524 850.58 5524C834.35 5524 821.19 5535.97 821.19 5550.73C821.19 5551.97 821.29 5553.2 821.47 5554.39C820.78 5554.35 820.09 5554.32 819.39 5554.32C803.16 5554.32 790 5566.29 790 5581.05C790 5595.81 803.16 5607.78 819.39 5607.78H932.76V5607.75C948.33 5607.05 960.72 5595.36 960.72 5581.04Z" fill="#E0D9F4"/>
        <path id="XMLID_00000121275792310105142680000016446452365842883251_" fill="#E0D9F4" d="     M932.34,2131.94c0-19.82-17.51-35.89-39.11-35.89c-0.05,0-0.1,0-0.15,0c-1.69-18.39-18.48-32.83-38.96-32.83     c-13.15,0-24.78,5.97-31.87,15.1c-5.65-13.44-19.86-22.98-36.5-22.98c-21.6,0-39.11,16.07-39.11,35.89     c0,1.67,0.13,3.31,0.37,4.92c-0.92-0.06-1.84-0.1-2.77-0.1c-21.6,0-39.11,16.07-39.11,35.89c0,19.82,17.51,35.89,39.11,35.89     h150.91v-0.04C915.86,2166.87,932.34,2151.17,932.34,2131.94z"/>
        
      </>
  )
  
}




