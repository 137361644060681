import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import  { ScrollTrigger }  from "gsap/ScrollTrigger";

// img
import logo from '../images/test/logo.svg';

// Component

import { useWindowSize } from './UseWindowSize';
import Section3 from "./svgSection/Section3.js";
import Section2 from "./svgSection/Section2.js";
import Section1 from "./svgSection/Section1.js";
import Section4 from "./svgSection/Section4.js";
import Section5 from "./svgSection/Section5.js";
import Section6 from "./svgSection/Section6.js";
import Section7 from "./svgSection/Section7.js";
import PulseButton from "./svgSection/PulseButton";
import PopupWindow from "./popupWindow/PopupWindow";
import FooterSection from "./svgSection/FooterSectioin";
import Header from "./svgSection/Header.js";
import Background from "./svgSection/Background.js";
import { data } from "./data/DataPopup";
import { settings } from "./popupWindow/PopupWindowSettings";
import Section1Figures from "./svgSection/Section1Figures";
import Section2Figures from "./svgSection/Section2Figures";
import Section3Figures from "./svgSection/Section3Figures";
import Section4Figures from "./svgSection/Section4Figures";
import Section5Figures from "./svgSection/Section5Figures";
import Section6Figures from "./svgSection/Section6Figures";
import Section7Figures from "./svgSection/Section7Figures";


gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

export default function Drawing() {

    const [showPopupKinderen, setShowPopupKinderen] = useState(false);
    const [showPopupOuders, setShowPopupOuders] = useState(false);
    const [showPopupCjg, setShowPopupCjg] = useState(false);
    const [showPopupScholen, setShowPopupScholen] = useState(false);
    const [showPopupZorg, setShowPopupZorg] = useState(false);
    const [showPopupGemeenten, setShowPopupGemeenten] = useState(false);
    const [showPopupLj, setShowPopupLj] = useState(false);
    const [showPopupFooter, setShowPopupFooter] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);

    const domainName = window.location.href;

    let widthHeight = useWindowSize();

    let section1 = useRef(null);
    let section2 = useRef(null);
    let section3 = useRef(null);
    let section4 = useRef(null);
    let section5 = useRef(null);
    let section6 = useRef(null);
    let section7 = useRef(null);

    let drow = useRef(null);
    let path = useRef(null);
    let hero = useRef(null);
    
    // Show popup window;
    const clickHandlerKinderen = () => {
        setShowPopupKinderen(!showPopupKinderen);  
    }
    const clickHandlerOuders = () => {
        setShowPopupOuders(!showPopupOuders);
        
    }
    const clickHandlerCjg = () => {
        setShowPopupCjg(!showPopupCjg);  
    }
    const clickHandlerScholen = () => {
        setShowPopupScholen(!showPopupScholen);  
    }
    const clickHandlerZorg = () => {
        setShowPopupZorg(!showPopupZorg);  
    }
    const clickHandlerGemeenten = () => {
        setShowPopupGemeenten(!showPopupGemeenten);  
    }
    const clickHandlerLj = () => {
        setShowPopupLj(!showPopupLj);  
    }
    const clickHandlerFooter = () => {
        setShowPopupFooter(!showPopupFooter); 
    }

    // GET container height
    
    const cont = () => {
        let h = drow.current.getBoundingClientRect();
        setContainerHeight(h.height);
    } 

    const navigate = (e, refElement) => {
        let el = refElement.current._gsap.yOrigin;
        window.scrollTo({top: (el-550), behavior: 'smooth'});
    };
    
    useEffect(() => {
        window.addEventListener('load', cont);
    }, [containerHeight]);

    // Animation GSAP
    useEffect(() => {
        let title = document.querySelectorAll('.section-title');
        
        // Detect browser
        var ua = navigator.userAgent.toLowerCase();

        // __________________
        if (widthHeight.width > 936) { 
            gsap.set('#balloon-kinderen', {scale: 0.8});

            gsap.timeline({
                defaults:{duration:90, ease:'none'},
                repeat: -1
            })
            .to('#balloon-kinderen', {
                motionPath:{
                    path: '#balloon-path',
                    align: '#balloon-path',
                    alignOrigin: [0.5, 1],
                    autoRotate: false
                }
            });
        }
        // ____________________

        if (widthHeight.width < 936) { 
            // Header
            gsap.set('#balloon-kinderen', {
                scale: .6
            });

            gsap.set('#balloon-path', {
                y: 0,
                x: -60
            });

            gsap.timeline({
                defaults:{duration:90, ease:'none'},
                repeat: -1
            })
            .to('#balloon-kinderen', {
                motionPath:{
                    path: '#balloon-path',
                    align: '#balloon-path',
                    alignOrigin: [0.5, 0.5],
                    autoRotate: false
                }
            });

            // Detect browser

            if (ua.indexOf('chrome') > -1) {
            } else {
                gsap.to(section1.current, {
                    y: -200,
                    x: -40
                });
            } 

            // Adjust position of elements on the smaller screen size

            gsap.to('.move-right', {
                x: 25
            });
            gsap.to('#title-lj-container', {
                x: -12
            });
            gsap.to('.section-title', {
                css: {
                    fontSize: '24px',
                } 
            });     
           gsap.to('.text-responsiveness', {            
              css: {
                  fontSize: '17px',
              }      
           });

        //    Animate mobile path:
        gsap.timeline({
            defaults:{duration:1.3, ease:'none'},
            scrollTrigger: {
              trigger: drow.current,
              start: 'top top',
              end: 'bottom bottom',
              scrub: 1
            }   
          })
          .to('#mobile-runner', {
            transformOrigin: 'center',
            motionPath:{
                path: '#mobile-path_2',
                align: '#mobile-path_2',
                alignOrigin: [0.5, 0.5],
                autoRotate: false,
            }
          });


          gsap.set('#mobile-move-right-top', {
              x: 100
          })
          gsap.set('#faces', {
              x: 100
          })

          gsap.set('#mobile-move-right-mid', {
              x: 70
          })
          gsap.set('#mobile-move-right-bottom', {
              x: -70
          })


        //   End of the width condition
        }

        gsap.fromTo(title, {opacity: 0, y: -40}, {opacity: 1, y: 0})
        gsap.timeline()
        .fromTo( section1.current,
            {opacity: 1, scale: 0.8,  transformOrigin: 'center'},  {
                    opacity: 1,
                    scale: 1,
                    transformOrigin: 'center',
                    scrollTrigger: {
                        trigger: section1.current,
                        start: "-=650px",
                        end: '-=450px',
                        scrub: 1,
                        toggleActions: "play none none reverse",
                    } 
                }
            )
        .fromTo(
            section2.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section2.current,
                    start: "-=650px", 
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        )
        .fromTo(
            section3.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section3.current,
                    start: "-=650px",
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        )
        .fromTo(
            section4.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1.1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section4.current,
                    start: "-=650px",
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        )
        .fromTo(
            section5.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1.1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section5.current,
                    start: "-=650px",
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        )
        .fromTo(
            section6.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section6.current,
                    start: "-=650px",
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        )
        .fromTo(
            section7.current,
            {opacity: 1, scale: 0.8, transformOrigin: 'center'}, 
            {
                opacity: 1,
                scale: 1,
                transformOrigin: 'center',
                scrollTrigger: {
                    trigger: section7.current,
                    start: "-=650px",
                    end: "-=450px",
                    scrub: 1,
                    toggleActions: "play none none reverse"
                } 
            }
        );
        
    }, [widthHeight]);
    
    // Animated a main hero and a path
    useEffect(() => {
        gsap.timeline({
            defaults:{duration:1.3, ease:'none'},
            scrollTrigger: {
              trigger: drow.current,
              start: 'top top',
              end: 'bottom bottom',
              scrub: 1
            }   
          })
          .to(hero.current, {
            transformOrigin: 'center',
            motionPath:{
                path: path.current,
                align: path.current,
                alignOrigin: [0.5, 0.5],
                autoRotate: false,
            }
          })
    }, []);
    
    // Menu active link
    useEffect(() => {
        let kinderen = document.querySelector('.menu-link-kinderen');
        let ouders = document.querySelector('.menu-link-ouders');
        let cjgRijnmond = document.querySelector('.menu-link-cjg-rijnmond');
        let scholen = document.querySelector('.menu-link-scholen');
        let zorg = document.querySelector('.menu-link-zorg');
        let gemeenten = document.querySelector('.menu-link-gemeenten');
        let landelijkJeugdgezondheidszorg = document.querySelector('.menu-link-landelijk-jeugdgezondheidszorg');
            
        gsap.to(kinderen, {
                scrollTrigger: {
                    trigger: section1.current, 
                    start: "-=650px",
                    end: "-=230px",
                    toggleClass: {targets: kinderen, className: 'active'},
                }
            })
            gsap.to(ouders, {
                scrollTrigger: {
                    trigger: section2.current, 
                    start: "-=550px",
                    end: "-=113px",
                    toggleClass: {targets: ouders, className: 'active'}
                }
            })
            gsap.to(cjgRijnmond, {
                scrollTrigger: {
                    trigger: section3.current, 
                    start: "-=500px",
                    end: "-=200px",
                    toggleClass: {targets: cjgRijnmond, className: 'active'}
                }
            })
            gsap.to(scholen, {
                scrollTrigger: {
                    trigger: section4.current, 
                    start: "-=670px",
                    end: "-=200px",
                    toggleClass: {targets: scholen, className: 'active'}
                }
            })
            gsap.to(zorg, {
                scrollTrigger: {
                    trigger: section5.current, 
                    start: "-=580px",
                    end: "-=200px",
                    toggleClass: {targets: zorg, className: 'active'}
                }
            })
            gsap.to(gemeenten, {
                scrollTrigger: {
                    trigger: section6.current, 
                    start: "-=580px",
                    end: "-=200px",
                    toggleClass: {targets: gemeenten, className: 'active'}
                }
            })
            gsap.to(landelijkJeugdgezondheidszorg, {
                scrollTrigger: {
                    trigger: section7.current, 
                    start: "-=580px",
                    end: "-=200px",
                    toggleClass: {targets: landelijkJeugdgezondheidszorg, className: 'active'}
                }
            })
    }, []);

    // Broek
    useEffect(() => {
        gsap.timeline({defaults: {duration: 2}, repeat: -1})
		.to('.broek',  {
			y: '-11px',
                
		})
		.to('.broek',  {
			y: 0,     
		})
    }, [])

    // Pulse btn
    useEffect(() => {
        gsap.set('.stage1', {opacity: 0, scale: 0.5, transformOrigin:"50% 50%"});
        gsap.set('.stage2', {opacity: 0.2, scale: 0.5, transformOrigin:"50% 50%"});

        gsap.to('.stage1', {
            scale: 1, 
            duration: 1.5,
            strokeWidth: '4px',
            stagger: {
              each: 0.5,
              repeat: -1,
            }
          });

        gsap.to('.stage2', {
                scale: 1, 
                strokeWidth: '6px',
                duration: 1.5,
                stagger: {
                    each: 0.5,
                    repeat: -1
                }
              });
          
          gsap.to('.stage1', {
                  opacity: 0.8,
                  duration: 0.75,
                  stagger: {
                    each: 0.5,
                    repeat: -1,
                    yoyo: true,
                  }
                });

          gsap.to('.stage2', {
                  opacity: 0.6, 
                  duration: 0.75,
                  stagger: {
                      each: 0.5,
                      repeat: -1,
                      yoyo: true
                  }
                });
    }, [])

    return (
        <>
        <div className="image-container" ref={drow}>
            <div className="introduction-text">
                <h3>2021 in beeld < br />(en in de buurt)</h3>
                <p>Het jaar 2021 was een jaar van vernieuwing: nieuwe producten, zoals webinars, de ontwikkeling van het 9 jaar consult, een visie op Kansrijke Start en op onderwijs, een nieuwe CJG-locatie en een nieuwe directeur Jeugd &#38; Gezin!</p>
                <p>Deels werd onze dienstverlening nog beïnvloed door het coronavirus: zo konden wij in het voorjaar geen vaccinatiedagen organiseren, maar in het najaar gelukkig weer wel. Ook was er nog een lockdown, maar hebben wij alsnog 290.000 contact-momenten gehad, online en offline!</p>
                <p>Loop mee langs onze hoofdonderwerpen, of navigeer via het menu onderin het scherm. Bij iedere tussenstop kun je op het plusje klikken voor een korte toelichting.</p>
                <p>Ben je benieuwd naar <a href={`${domainName}CJG_Rijnmond_Jaarverslag_2021.pdf`}  target="_blank" rel="noopener noreferrer">het financiële jaarverslag</a>? Deze kun je apart van dit overzicht inzien.</p>
            </div>
            <div className="name-logo">
                <a href={`${domainName}`} rel="noopener noreferrer">
                  <img src={logo} alt="Logo" />
                </a>
            </div>
            <div className="navigation">
                <ul>
                    <li onClick={(e) => navigate(e, section1)} className="menu-link-kinderen"><span>Kinderen</span></li>
                    <li onClick={(e) => navigate(e, section2)} className="menu-link-ouders"><span>Ouders</span></li>
                    <li onClick={(e) => navigate(e, section3)} className="menu-link-cjg-rijnmond"><span>CJG Rijnmond</span></li>
                    <li onClick={(e) => navigate(e, section4)} className="menu-link-scholen"><span>Scholen</span></li>
                    <li onClick={(e) => navigate(e, section5)} className="menu-link-zorg"><span>Zorg</span></li>
                    <li onClick={(e) => navigate(e, section6)} className="menu-link-gemeenten"><span>Gemeenten</span></li>
                    <li onClick={(e) => navigate(e, section7)} className="menu-link-landelijk-jeugdgezondheidszorg"><span>Landelijke Jeugdgezondheidszorg</span></li>
                </ul>
            </div>
           <svg id="main-svg" xmlns="http://www.w3.org/2000/svg" width="960.84" height="5998" viewBox="0 0 960.84 5998" >     
                <Header />
                <g id="mobile-path">
                    <path id="mobile-path_2" fill="transparent" d="M515 567V5267.47C515 5379 515 5577 659.019 5577H963" stroke="#EC008C" strokeWidth="8"/>
                    <g id="mobile-runner">
                        <path id="Vector_1395" d="M515.22 549C505.16 549 497 557.16 497 567.22C497 577.28 505.16 585.44 515.22 585.44C525.28 585.44 533.44 577.28 533.44 567.22C533.44 557.16 525.28 549 515.22 549Z" fill="#522E91"/>
                    </g>
                </g>
                <Background />
                <g id="main-path" > 
                    <path id="hidden-path" ref={path} d="M600 496C540.834 488.667 420.6 506.6 413 637C418.667 682.667 405.8 777.3 291 800.5C245.5 796.5 148.9 814.2 126.5 917C116 962.167 126.8 1058.3 254 1087.5H429.5H628C667.667 1092.67 748.7 1127.5 755.5 1225.5C755.334 1271.83 726.3 1366.6 611.5 1375H223H192C148.834 1376 63.1003 1407.9 65.5003 1527.5C68.3336 1564.33 97.6003 1641.9 192 1657.5H523L622.5 1753C660.834 1750.83 744.2 1768.5 771 1856.5C783.167 1908.17 775.1 2017.3 645.5 2040.5H397.5C344.334 2050.33 246.8 2101.9 282 2229.5C289.834 2263 334.1 2329.4 448.5 2327L523 2447.5C559.834 2460.83 631.7 2505.8 624.5 2579C628.167 2629.17 603.7 2729.7 476.5 2730.5H236.5C185.5 2745.67 93.6002 2805.5 134 2923.5C153.834 2963.33 213.7 3037.6 294.5 3016H576C619.334 3019.33 707.2 3052.2 712 3157C713.334 3200 688 3289.1 576 3301.5H192C137 3304.83 33.4002 3346.2 59.0002 3485C68.6668 3518.33 110 3585.4 198 3587H428.5L473 3705.5C523.667 3707.83 622.9 3744.9 614.5 3874.5C607.833 3914.5 566.9 3993.7 456.5 3990.5H254C203.333 3997.5 105.7 4041.4 120.5 4161C130.833 4200.17 173.7 4278.9 262.5 4280.5C272.5 4277.83 296.5 4276.9 312.5 4294.5C336.167 4311.83 395.1 4343.7 441.5 4332.5C487.667 4339 576.5 4380.7 562.5 4495.5C558 4534 522.7 4612.4 417.5 4618H279C228.167 4625.5 131.9 4670.4 153.5 4790C160 4821 192.4 4886.9 270 4902.5L493.5 4906L567.5 4910L606 4923C624.167 4932.83 657.5 4966.9 645.5 5024.5V5129.5L642 5169C639.667 5199.67 615.3 5265.9 536.5 5285.5C528.333 5287.83 504.2 5292.2 473 5291C436.167 5294.17 358.9 5323.4 344.5 5415C338.167 5454 348.9 5539.5 442.5 5569.5L465 5575.5H478.5L960 5579" stroke="transparent" fill="transparent" strokeWidth="8"/>
                </g>
                <g id="runner" ref={hero}>
                    <path fill='#522E91' d="M752.9,1240.89c-10.06,0-18.22,8.16-18.22,18.22c0,10.06,8.16,18.22,18.22,18.22     s18.22-8.16,18.22-18.22C771.12,1249.05,762.96,1240.89,752.9,1240.89z"/>
                </g>

                <PulseButton 
                clickHandlerKinderen={clickHandlerKinderen} 
                clickHandlerZorg={clickHandlerZorg} 
                clickHandlerCjg={clickHandlerCjg} 
                clickHandlerOuders={clickHandlerOuders} 
                clickHandlerGemeenten={clickHandlerGemeenten} 
                clickHandlerLj={clickHandlerLj} 
                clickHandlerScholen={clickHandlerScholen}
                clickHandlerFooter={clickHandlerFooter} 
                />
                <FooterSection />
                <g ref={section1}><Section1 /></g>
                <g><Section1Figures/></g>
                <g ref={section2}><Section2/></g>
                <g><Section2Figures/></g>
                <g ref={section3}><Section3/></g>
                <g><Section3Figures/></g>
                <g ref={section4}><Section4/></g>
                <g><Section4Figures/></g>
                <g ref={section5}><Section5/></g>
                <g><Section5Figures/></g>
                <g ref={section6}><Section6/></g>
                <g><Section6Figures/></g>
                <g ref={section7}><Section7/></g>
                <g><Section7Figures/></g>

                <g  id="btn-footer" className="pulse-with-lines" onClick={() => clickHandlerFooter()}>
                    <path d="M416.99 5472.7C404.843 5472.7 394.99 5482.55 394.99 5494.7C394.99 5506.85 404.843 5516.7 416.99 5516.7C429.137 5516.7 438.99 5506.85 438.99 5494.7C438.99 5482.55 429.146 5472.7 416.99 5472.7Z" fill="#EC008C"/>
                    <path d="M419.825 5491.89V5482.87H414.155V5491.89H405.192V5497.51H414.155V5506.53H419.825V5497.51H428.788V5491.89H419.825Z" fill="white"/>
                    <path className="pulse stage1" fill="transparent" d="M442 5495.04C442 5508.57 431.031 5519.54 417.5 5519.54C403.969 5519.54 393 5508.57 393 5495.04C393 5481.51 403.969 5470.54 417.5 5470.54C431.031 5470.54 442 5481.51 442 5495.04Z" stroke="#EC008C"/>
                    <path className="pulse stage2" fill="transparent" d="M444.5 5495C444.5 5510.19 432.188 5522.5 417 5522.5C401.812 5522.5 389.5 5510.19 389.5 5495C389.5 5479.81 401.812 5467.5 417 5467.5C432.188 5467.5 444.5 5479.81 444.5 5495Z" stroke="#EC008C"/>
                </g>

                <rect className="broek" x="648.914" y="5858" width="9.58329" height="22" fill="#6F57A6"/>

                <use xlinkHref="#XMLID_1055_"/>
                <use xlinkHref="#cloud-endpoint"/>
                <use xlinkHref="#cloud-111"/>
                <use xlinkHref="#gemeenten-cloud"/>
                <use xlinkHref="#top-cloud"/>
               
           </svg>
        </div>
        
        {
            showPopupKinderen &&  
            <PopupWindow toggle={clickHandlerKinderen}>
                <Slider {...settings}>
                    {
                    data.kinderen.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupOuders &&  
            <PopupWindow toggle={clickHandlerOuders}>
                <Slider {...settings}>
                    {
                    data.ouders.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupCjg &&  
            <PopupWindow toggle={clickHandlerCjg}>
                <Slider {...settings}>
                    {
                    data.cjg.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupScholen &&  
            <PopupWindow toggle={clickHandlerScholen}>
                <Slider {...settings}>
                    {
                    data.scholen.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupZorg &&  
            <PopupWindow toggle={clickHandlerZorg}>
                <Slider {...settings}>
                    {
                    data.zorg.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupGemeenten &&  
            <PopupWindow toggle={clickHandlerGemeenten}>
                <Slider {...settings}>
                    {
                    data.gemeenten.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupLj &&  
            <PopupWindow toggle={clickHandlerLj}>
                <Slider {...settings}>
                    {
                    data.landelijkeJeugdgezondheidzorg.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        {
            showPopupFooter &&  
            <PopupWindow toggle={clickHandlerFooter}>
                <Slider {...settings}>
                    {
                    data.footer.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </PopupWindow>
        }
        </>
    )
}