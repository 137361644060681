import React, { useEffect } from "react";
import { gsap } from "gsap";

// This button bellongs to the sections: coordinates of headers and clickHandler() to show slidere 
export default function PulseButton(props) {
    // Move header 
    useEffect(() => {
      gsap.to('#kinderen-header', {
        x: '120px',
        y: '-20px'
      });
      gsap.to('#ouders-header', {
        x: '120px',
        y: '5px'
      });
      gsap.to('#cjg-header', {
        x: '35px',
        y: '-15px'
      });

      gsap.to('#scholen-header', {
        x: '30px',
        y: '-15px'
      });
      gsap.to('#zorg-header', {
        x: '30px',
        y: '-5px'
      });
      gsap.to('#gemeenten-header', {
        x: '-10px',
        y: '15px'
      });
      gsap.to('#lj-header', {
        x: '-40px',
        y: '-50px'
      });

      // Title
      gsap.to('#title-lj', {
        x: '25px'
      });
      gsap.to('#titile-kinderen', {
        x: '15px'
      });
      gsap.to('#cjg-title', {
        x: '25px'
      });
      gsap.to('#title-scholen', {
        x: '12px',
        y: -2
      });
      gsap.to('#title-zorg', {
        x: '2px',   
      });
      gsap.to('#title-gemeenten', {
        x: '18px',   
      });
      gsap.to('#title-lj-container', {
        x: '18px',   
      });
    }, [])

    return (
      <>
      <g id="kinderen-header">
          <path d="M619.35 830.62H443.26C439.95 830.62 437.26 827.93 437.26 824.62V780.09C437.26 776.78 439.95 774.09 443.26 774.09H619.35C622.66 774.09 625.35 776.78 625.35 780.09V824.62C625.35 827.94 622.66 830.62 619.35 830.62Z" fill="white"/>
          <text id="titile-kinderen" className="section-title" x="470.35" y="810.62">Kinderen</text>
          <g className="pulse-with-lines" id="btn-kinderen" onClick={() => props.clickHandlerKinderen()} >
            <path d="M623 802C610.853 802 601 811.853 601 824C601 836.147 610.853 846 623 846C635.147 846 645 836.147 645 824C645 811.853 635.156 802 623 802Z" fill="#EC008C"/>
            <path d="M625.835 821.193V812.174H620.165V821.193H611.202V826.807H620.165V835.826H625.835V826.807H634.798V821.193H625.835Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M648 824.041C648 837.572 637.031 848.541 623.5 848.541C609.969 848.541 599 837.572 599 824.041C599 810.51 609.969 799.541 623.5 799.541C637.031 799.541 648 810.51 648 824.041Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M650.5 824C650.5 839.188 638.188 851.5 623 851.5C607.812 851.5 595.5 839.188 595.5 824C595.5 808.812 607.812 796.5 623 796.5C638.188 796.5 650.5 808.812 650.5 824Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="ouders-header">
          <g id="title-ouders">
            <path d="M593.56 1525.73H427.58C424.27 1525.73 421.58 1523.04 421.58 1519.73V1475.2C421.58 1471.89 424.27 1469.2 427.58 1469.2H593.56C596.87 1469.2 599.56 1471.89 599.56 1475.2V1519.73C599.56 1523.05 596.87 1525.73 593.56 1525.73Z" fill="white"/>
            <text className="section-title" x="470.954" y="1505.35">Ouders</text>
          </g>
          <g className="pulse-with-lines" id="btn-ouders" onClick={() => props.clickHandlerOuders()} >
              <path d="M594.63 1492.63C582.483 1492.63 572.63 1502.48 572.63 1514.63C572.63 1526.78 582.483 1536.63 594.63 1536.63C606.777 1536.63 616.63 1526.78 616.63 1514.63C616.63 1502.48 606.777 1492.63 594.63 1492.63Z" fill="#EC008C"/>
              <path d="M597.465 1511.82V1502.8H591.795V1511.82H582.832V1517.44H591.795V1526.46H597.465V1517.44H606.428V1511.82H597.465Z" fill="white"/>
              <path className="pulse stage1" fill="transparent" d="M619 1515.04C619 1528.57 608.031 1539.54 594.5 1539.54C580.969 1539.54 570 1528.57 570 1515.04C570 1501.51 580.969 1490.54 594.5 1490.54C608.031 1490.54 619 1501.51 619 1515.04Z" stroke="#EC008C"/>
              <path className="pulse stage2" fill="transparent" d="M621.5 1515C621.5 1530.19 609.188 1542.5 594 1542.5C578.812 1542.5 566.5 1530.19 566.5 1515C566.5 1499.81 578.812 1487.5 594 1487.5C609.188 1487.5 621.5 1499.81 621.5 1515Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="scholen-header">
          <g>
            <path d="M825.83 2812.85H659.85C656.54 2812.85 653.85 2810.16 653.85 2806.85V2762.32C653.85 2759.01 656.54 2756.32 659.85 2756.32H825.83C829.14 2756.32 831.83 2759.01 831.83 2762.32V2806.85C831.83 2810.16 829.14 2812.85 825.83 2812.85Z" fill="white"/>
            <text id="title-scholen" className="section-title" x="690.83" y="2794.85">Scholen</text>
          </g>
          <g className="pulse-with-lines" id="btn-scholen" onClick={() => props.clickHandlerScholen()}>
              <path d="M827.6 2780.96C815.453 2780.96 805.6 2790.81 805.6 2802.96C805.6 2815.11 815.453 2824.96 827.6 2824.96C839.747 2824.96 849.6 2815.11 849.6 2802.96C849.6 2790.81 839.747 2780.96 827.6 2780.96Z" fill="#EC008C"/>
              <path d="M830.435 2800.15V2791.13H824.765V2800.15H815.802V2805.77H824.765V2814.79H830.435V2805.77H839.398V2800.15H830.435Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M852 2803.04C852 2816.57 841.031 2827.54 827.5 2827.54C813.969 2827.54 803 2816.57 803 2803.04C803 2789.51 813.969 2778.54 827.5 2778.54C841.031 2778.54 852 2789.51 852 2803.04Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M854.5 2803C854.5 2818.19 842.188 2830.5 827 2830.5C811.812 2830.5 799.5 2818.19 799.5 2803C799.5 2787.81 811.812 2775.5 827 2775.5C842.188 2775.5 854.5 2787.81 854.5 2803Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="zorg-header">
          <g >
              <path d="M485.26 3452.63H319.29C315.98 3452.63 313.29 3449.94 313.29 3446.63V3402.1C313.29 3398.79 315.98 3396.1 319.29 3396.1H485.27C488.58 3396.1 491.27 3398.79 491.27 3402.1V3446.63C491.26 3449.94 488.58 3452.63 485.26 3452.63Z" fill="white"/>
              <text id="title-zorg" className="section-title" x="375.26" y="3432.63">Zorg</text>
          </g>
          <g className="pulse-with-lines" id="btn-zorg" onClick={() => props.clickHandlerZorg()}>
            <path d="M485.45 3416.8C473.303 3416.8 463.45 3426.65 463.45 3438.8C463.45 3450.95 473.303 3460.8 485.45 3460.8C497.597 3460.8 507.45 3450.95 507.45 3438.8C507.45 3426.65 497.597 3416.8 485.45 3416.8Z" fill="#EC008C"/>
            <path d="M488.285 3436V3426.98H482.615V3436H473.652V3441.62H482.615V3450.63H488.285V3441.62H497.248V3436H488.285Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M510 3439.04C510 3452.57 499.031 3463.54 485.5 3463.54C471.969 3463.54 461 3452.57 461 3439.04C461 3425.51 471.969 3414.54 485.5 3414.54C499.031 3414.54 510 3425.51 510 3439.04Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M512.5 3439C512.5 3454.19 500.188 3466.5 485 3466.5C469.812 3466.5 457.5 3454.19 457.5 3439C457.5 3423.81 469.812 3411.5 485 3411.5C500.188 3411.5 512.5 3423.81 512.5 3439Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="lj-header">
          <g >
              <path d="M845.88 4771.74H529.73C526.42 4771.74 523.73 4769.05 523.73 4765.74V4706.02C523.73 4702.71 526.42 4700.02 529.73 4700.02H845.88C849.19 4700.02 851.88 4702.71 851.88 4706.02V4765.74C851.88 4769.06 849.2 4771.74 845.88 4771.74Z" fill="white"/>
              <g id="title-lj-container">
                <text className="section-title" x="620.88" y="4731.74">Landelijke</text>
                <text id="title-lj" className="section-title" x="539.88" y="4759.74">Jeugdgezondheidszorg</text>
              </g>
          </g>
          <g className="pulse-with-lines" id="btn-lj" onClick={() => props.clickHandlerLj()} >
            <path d="M847.99 4738.7C835.843 4738.7 825.99 4748.55 825.99 4760.7C825.99 4772.85 835.843 4782.7 847.99 4782.7C860.137 4782.7 869.99 4772.85 869.99 4760.7C869.99 4748.55 860.146 4738.7 847.99 4738.7Z" fill="#EC008C"/>
            <path d="M850.825 4757.89V4748.87H845.155V4757.89H836.192V4763.51H845.155V4772.53H850.825V4763.51H859.788V4757.89H850.825Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M873 4761.04C873 4774.57 862.031 4785.54 848.5 4785.54C834.969 4785.54 824 4774.57 824 4761.04C824 4747.51 834.969 4736.54 848.5 4736.54C862.031 4736.54 873 4747.51 873 4761.04Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M875.5 4761C875.5 4776.19 863.188 4788.5 848 4788.5C832.812 4788.5 820.5 4776.19 820.5 4761C820.5 4745.81 832.812 4733.5 848 4733.5C863.188 4733.5 875.5 4745.81 875.5 4761Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="gemeenten-header">
          <g >
              <path d="M552.65 4089.55H325.59C322.28 4089.55 319.59 4086.86 319.59 4083.55V4039.02C319.59 4035.71 322.28 4033.02 325.59 4033.02H552.65C555.96 4033.02 558.65 4035.71 558.65 4039.02V4083.55C558.65 4086.86 555.97 4089.55 552.65 4089.55Z" fill="white"/>
              <text id="title-gemeenten" className="section-title" x="362.65" y="4069.55">Gemeenten</text>
          </g>
          <g className="pulse-with-lines" id="btn-gemeenten" onClick={() => props.clickHandlerGemeenten()}>
            <path d="M554.76 4058.51C542.613 4058.51 532.76 4068.36 532.76 4080.51C532.76 4092.66 542.613 4102.51 554.76 4102.51C566.907 4102.51 576.76 4092.66 576.76 4080.51C576.76 4068.36 566.916 4058.51 554.76 4058.51Z" fill="#EC008C"/>
            <path d="M557.595 4077.7V4068.68H551.925V4077.7H542.971V4083.32H551.925V4092.34H557.595V4083.32H566.558V4077.7H557.595Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M579 4081.04C579 4094.57 568.031 4105.54 554.5 4105.54C540.969 4105.54 530 4094.57 530 4081.04C530 4067.51 540.969 4056.54 554.5 4056.54C568.031 4056.54 579 4067.51 579 4081.04Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M581.5 4081C581.5 4096.19 569.188 4108.5 554 4108.5C538.812 4108.5 526.5 4096.19 526.5 4081C526.5 4065.81 538.812 4053.5 554 4053.5C569.188 4053.5 581.5 4065.81 581.5 4081Z" stroke="#EC008C"/>
          </g>
        </g>
        <g id="cjg-header">
          <g>
              <path  d="M623.4 2153.02H365.35C362.04 2153.02 359.35 2150.33 359.35 2147.02V2102.49C359.35 2099.18 362.04 2096.49 365.35 2096.49H623.4C626.71 2096.49 629.4 2099.18 629.4 2102.49V2147.02C629.4 2150.34 626.71 2153.02 623.4 2153.02Z" fill="white"/>
              <text id="cjg-title" className="section-title" x="400.4" y="2134.02">CJG Rijnmond</text>
          </g>
          <g className="pulse-with-lines" id="btn-cjg" onClick={() => props.clickHandlerCjg()} >
            <path d="M625.01 2119.98C612.863 2119.98 603.01 2129.83 603.01 2141.98C603.01 2154.13 612.863 2163.98 625.01 2163.98C637.157 2163.98 647.01 2154.13 647.01 2141.98C647.01 2129.82 637.157 2119.98 625.01 2119.98Z" fill="#EC008C"/>
            <path d="M627.845 2139.17V2130.15H622.175V2139.17H613.212V2144.79H622.175V2153.8H627.845V2144.79H636.808V2139.17H627.845Z" fill="white"/>
            <path className="pulse stage1" fill="transparent" d="M649 2142.04C649 2155.57 638.031 2166.54 624.5 2166.54C610.969 2166.54 600 2155.57 600 2142.04C600 2128.51 610.969 2117.54 624.5 2117.54C638.031 2117.54 649 2128.51 649 2142.04Z" stroke="#EC008C"/>
            <path className="pulse stage2" fill="transparent" d="M651.5 2142C651.5 2157.19 639.188 2169.5 624 2169.5C608.812 2169.5 596.5 2157.19 596.5 2142C596.5 2126.81 608.812 2114.5 624 2114.5C639.188 2114.5 651.5 2126.81 651.5 2142Z" stroke="#EC008C"/>
          </g>
        </g>
      </>
    )
}

// Color converter: https://www.w3schools.com/colors/colors_converter.asp