import React from "react";

export default function Section6() {
    return (
        <g id="gemeenten">
        
            <path
        style={{
          fill: "#7bc143",
        }}
        d="M865.74 4206.17h-50.75c20.81-2.45 36.96-20.06 36.96-41.45 0-23.07-18.77-41.77-41.92-41.77H475.2c-23.15 0-41.92 18.7-41.92 41.77 0 23.07 18.77 41.77 41.92 41.77l-23.95-16.38c26.49 2.84-7.16 18.05-7.16 45.19 0 29.07 84.8 76.15 55.63 76.15h-20.56c-16.58 0-30.03 13.4-30.03 29.92 0 16.53 13.45 29.92 30.03 29.92h221.17c16.58 0 30.03-13.4 30.03-29.92 0-16.53-13.44-29.92-30.03-29.92h166.91c29.17 0 51.34-23.57 51.34-52.64-.01-29.07-23.66-52.64-52.84-52.64z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M739.93 4345.41h-63.24c-27.5 0-49.79-22.29-49.79-49.79s22.29-49.79 49.79-49.79h-3.65c17.53 0 31.75-14.21 31.75-31.75 0-17.53-14.21-31.75-31.75-31.75H403.43c-17.53 0-31.75 14.21-31.75 31.75 0 17.53 14.21 31.75 31.75 31.75H268.35c-27.5 0-49.79 22.29-49.79 49.79s22.29 49.79 49.79 49.79h76.14"
      />
        <path
        style={{
          fill: "#f799d1",
        }}
        d="M709.12 4026.25h-7.61l-5.85-5.26h-41.69l-6.99 5.26h-5.36l-14.52 9.36v6.43h94.18v-6.43z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M725.5 4126.73v-91.77H623.11v40.29l-31.82 12.16v164.83H745.05v-118.03z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M647.01 4098.14h20.81v22.22h-20.81zM647.01 4142.57h20.81v22.22h-20.81zM647.01 4053.64h20.81v22.22h-20.81zM647.01 4184.34h20.81v22.21h-20.81zM686.19 4098.14H707v22.22h-20.81zM686.19 4142.57H707v22.22h-20.81zM686.19 4053.64H707v22.22h-20.81zM686.19 4184.34H707v22.21h-20.81zM601.18 4098.14h14.49v22.22h-14.49zM601.18 4142.57h14.49v22.22h-14.49zM601.18 4184.34h14.49v22.21h-14.49z"
      />
        </g>
    )
}