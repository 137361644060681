import React from "react";

export default function Section5() {
    return (
        <g id="zorg">
            <path
        style={{
          fill: "#7bc143",
        }}
        d="M779.31 3599.23h-53.62c21.99-2.73 39.05-22.35 39.05-46.18 0-25.7-19.83-46.54-44.29-46.54H366.63c-24.46 0-44.29 20.83-44.29 46.54s19.83 46.54 44.29 46.54l-25.3-18.25c27.99 3.16-7.57 20.11-7.57 50.36 0 32.4 89.61 84.85 58.78 84.85h-21.72c-17.52 0-31.73 14.93-31.73 33.34 0 18.41 14.21 33.34 31.73 33.34h233.7c17.52 0 31.73-14.93 31.73-33.34 0-18.41-14.21-33.34-31.73-33.34h176.37c30.83 0 54.24-26.26 54.24-58.65 0-32.41-24.99-58.67-55.82-58.67z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M141.56 3697.25h53.45c-21.92 2.59-38.93 21.2-38.93 43.81 0 24.39 19.77 44.15 44.15 44.15h352.69c24.39 0 44.15-19.77 44.15-44.15 0-24.39-19.77-44.15-44.15-44.15h-31.84c-27.9-3-49.63-26.61-49.63-55.31 0-30.73 24.92-55.65 55.65-55.65h21.66c17.47 0 31.63-14.16 31.63-31.63s-14.16-31.63-31.63-31.63H315.8c-17.47 0-31.63 14.16-31.63 31.63s14.16 31.63 31.63 31.63H139.99c-30.73 0-54.07 24.91-54.07 55.65s24.91 55.65 55.64 55.65z"
      />
      <g id="building">
        <path
        style={{
          fill: "#522e91",
        }}
        d="M363.36 3647.02V3492.3H173.6v154.72z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="m334.16 3460.12 2.87 25.67h-137.1l2.87-25.67z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m356.54 3473.35 3.78 20.73H176.64l3.79-20.73z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M363.36 3506.5H173.6l-5.32-20.73h200.4z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M216.81 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M216.81 3514.07h22.43v25.43h-22.43zM189.72 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M189.72 3514.07h22.43v25.43h-22.43zM270.81 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M270.81 3514.07h22.43v25.43h-22.43zM243.72 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M243.72 3514.07h22.43v25.43h-22.43zM324.82 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M324.82 3514.07h22.43v25.43h-22.43zM297.73 3514.07h22.43v25.43h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M297.73 3514.07h22.43v25.43h-22.43zM216.81 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M216.81 3545.53h22.43v25.42h-22.43zM189.72 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M189.72 3545.53h22.43v25.42h-22.43zM270.81 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M270.81 3545.53h22.43v25.42h-22.43zM243.72 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M243.72 3545.53h22.43v25.42h-22.43zM324.82 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M324.82 3545.53h22.43v25.42h-22.43zM297.73 3545.53h22.43v25.42h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M297.73 3545.53h22.43v25.42h-22.43zM216.81 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M216.81 3576.6h22.43v25.41h-22.43zM189.72 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M189.72 3576.6h22.43v25.41h-22.43zM270.81 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M270.81 3576.6h22.43v25.41h-22.43zM243.72 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M243.72 3576.6h22.43v25.41h-22.43zM324.82 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M324.82 3576.6h22.43v25.41h-22.43zM297.73 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#856abf",
        }}
        d="M297.73 3576.6h22.43v25.41h-22.43z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M291.19 3478.44c0 12.54-10.17 22.71-22.71 22.71-12.54 0-22.71-10.17-22.71-22.71 0-12.55 10.17-22.71 22.71-22.71 12.54 0 22.71 10.16 22.71 22.71z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M283.79 3473.37h-10.23v-10.24h-10.15v10.23h-10.24v10.15h10.24v10.24h10.15v-10.24h10.23z"
      />
        </g>
        </g>
    )
}