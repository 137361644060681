import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Section6Figures() {
  useEffect(() => {
    function thousandDelimeter(n) {
			return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");	
		}

    gsap.from('.gem-numbers', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.gem-numbers',
    })

    gsap.from('.gem-numbers-thousand', {
			textContent: 0,
			duration: 2,
			ease: "power3.inOut",
			snap: { textContent: 10 },
			scrollTrigger: '.gem-numbers-thousand',
			onUpdate: function() {
				this.targets().forEach(target => {
					const val = gsap.getProperty(target, "textContent");
					target.textContent = thousandDelimeter(val);
				});
			}
		})

  }, [])

  return (
      <>
       <g>
      
      <path
        style={{
          fill: "none",
        }}
        d="M449.13 4157.38h169.09v67.29H449.13z"
      />
      <text transform="translate(499.303 4198.635)">
        <tspan
          x={0}
          y={0}
          className="gem-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 55,
          }}
        >
          {"14"}
        </tspan>
        <tspan
          x={-4.38}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"gemeenten"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M765.58 4351.54h169.09v67.29H765.58z"
      />
      <text transform="translate(783.413 4370.292)">
        <tspan
          x={0}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"1 op de 13"}
        </tspan>
        <tspan
          x={-4.79}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"kinderen groeit op in "}
        </tspan>
        <tspan
          x={36.78}
          y={32}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"armoede"}
        </tspan>
      </text>
      <path
        style={{
          fill: "#fab15a",
        }}
        d="M339.35 4241.94h20.89v29.37h-20.89z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M368.96 4241.94h19.65v29.37h-19.65zM388.67 4241.94h-.06v29.37h5.59v-29.37zM397.86 4241.94h3.49v29.37h-3.49zM368.96 4237.8H389.87v-17.09h-20.91zM360.25 4220.71h-22.32v17.09h22.32zM397.86 4220.71v17.09h4.27v-17.09zM389.87 4220.71h4.33v17.09h-4.33z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M339.35 4234.47h20.89v7.47h-20.89zM368.96 4237.8h19.65v4.14h-19.65zM388.67 4237.8h-.06v4.14h5.59v-4.14h-4.33zM397.86 4237.8h3.49v4.14h-3.49z"
      />
      <path
        style={{
          fill: "#ff392b",
        }}
        d="M368.96 4237.8v-17.09h-8.71v50.6h8.71v-29.37z"
      />
      <path
        style={{
          fill: "#f70600",
        }}
        d="M397.86 4237.8v-17.09h-3.66v50.6h3.66v-29.37z"
      />
      <path
        style={{
          fill: "#ff392b",
        }}
        d="M349.56 4220.71h40.7a6.87 6.87 0 0 0 6.79-5.82c.49-3.16.71-7-.28-9.86-2.01-5.81-13.41-3.46-18.77 2.01-5.36 5.47-5.93 12.68-5.93 12.68-.5-.52-1.28-1.04-2.4-.98-.91.05-1.53.36-1.96.72-.16-1.33-1.14-7.56-5.9-12.42-5.36-5.47-16.76-7.82-18.77-2.01-.99 2.86-.76 6.69-.28 9.86a6.896 6.896 0 0 0 6.8 5.82zm29.4-10.4c3.84-3.77 11.99-5.39 13.43-1.39.51 1.43.57 3.22.43 4.92-.28 3.33-3.08 5.88-6.43 5.88H374.6c.01.01.52-5.63 4.36-9.41zm-31.52-1.38c1.44-4 9.6-2.39 13.43 1.39 3.84 3.77 4.48 9.42 4.48 9.42h-11.92c-3.34 0-6.15-2.55-6.43-5.88-.13-1.72-.08-3.5.44-4.93z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M310.96 4255.15h18.56v22.11h-18.56zM337.27 4255.15h17.46v22.11h-17.46zM362.94 4255.15h3.1v22.11h-3.1z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M354.78 4255.15h-.06v22.1h4.97v-22.1zM337.27 4252.03H355.84v-12.86h-18.57zM329.52 4239.17h-19.83v12.86h19.83zM362.94 4239.17v12.86H366.04v-12.86zM355.84 4239.17h3.85v12.86h-3.85zM310.96 4252.03h18.56v3.11h-18.56zM337.27 4252.03h17.46v3.11h-17.46z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M354.78 4252.03h-.06v3.12h4.97v-3.12h-3.85zM362.94 4252.03h3.1v3.11h-3.1z"
      />
      <path
        style={{
          fill: "#ba83db",
        }}
        d="M337.27 4252.03v-12.86h-7.75v38.08h7.75v-22.1z"
      />
      <path
        style={{
          fill: "#a96ed3",
        }}
        d="M362.94 4252.03v-12.86h-3.25v38.08h3.25v-22.1z"
      />
      <path
        style={{
          fill: "#ba83db",
        }}
        d="M325.42 4239.17c-4.37-2.28.04-6.53 2.64-5.35 2.59 1.18 6.69 5.34 6.7 5.35v-2.36c-4.4-9.93-10.25-8.81-13.39-6.04-3.15 2.77-1.26 8.4-1.26 8.4h5.31zM337.96 4239.17s4.11-4.17 6.7-5.35c2.6-1.18 7 3.07 2.64 5.35h5.31s1.89-5.63-1.26-8.4c-3.15-2.77-9-3.88-13.39 6.04v2.36zM334.76 4239.17H337.96v-2.36c-1.55-1.39-3.19-.01-3.2 0v2.36z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M405.68 4258.69c.04 1.1.1 1.81.1 1.81l-1.68-1.81h-.75l-1.46 1.81.01-1.81h-3.42v16.81h10.63v-16.81h-3.43zM421.12 4258.69l.01 1.81-1.46-1.81h-.75l-1.68 1.81s.06-.71.1-1.81h-3.79v16.81h10v-16.81h-2.43z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M428.26 4258.69h1.77v16.81h-1.77z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M423.59 4258.69h-.03v16.82h2.84v-16.82zM414.55 4246.54h-1v9.78h3.81c-.06-3.61-.57-8.46-2.81-9.78zM409.12 4246.54h-.65c-2.24 1.32-2.75 6.17-2.81 9.78h3.46v-9.78z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M401.94 4253.88c.05-6.62 2.77-7.33 2.77-7.33h3.57-10.51v9.78h4.16c0-.7 0-1.51.01-2.45zM423.59 4256.33h.61v-9.78h-5.11.01-.79.01-3.56 3.57s2.72.72 2.77 7.33c.01.94.01 1.75.02 2.44H423.59z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M424.19 4246.54h2.21v9.78h-2.21z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M428.26 4246.54v9.79h2.18v-9.79z"
      />
      <path
        style={{
          fill: "#74a300",
        }}
        d="M418.92 4258.69h.75l-.35-.43z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M417.36 4256.33h-3.81v2.37h3.79c.02-.67.04-1.49.02-2.37zM421.1 4256.33c.01 1.02.01 1.79.02 2.37h2.44v-2.37h-2.46z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="m403.7 4258.26-.35.43h.75zM409.12 4256.33h-3.46c-.01.89 0 1.7.02 2.37h3.44v-2.37z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M401.92 4256.33h-3.44v2.37h3.42c.01-.59.01-1.36.02-2.37z"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M428.26 4256.33h1.77v2.37h-1.77z"
      />
      <path
        style={{
          fill: "#664da1",
        }}
        d="M423.59 4256.33h-.03v2.36h2.84v-2.36h-2.21z"
      />
      <path
        style={{
          fill: "#a96ed3",
        }}
        d="M413.55 4256.33v-9.78h1c2.24 1.32 2.75 6.17 2.81 9.78.01.89 0 1.7-.02 2.37-.04 1.1-.1 1.81-.1 1.81l1.68-1.81.4-.43.35.43 1.46 1.81-.01-1.81c0-.58-.01-1.35-.02-2.37-.01-.69-.01-1.5-.02-2.44-.05-6.62-2.77-7.33-2.77-7.33h-3.57.37-2.39v-.02c-.2.01-.42.02-.66.02h-.79c-5.31-.08-5.54-4.62-2.76-4.43 2.78.19 5.53 3.13 5.53 3.13.32-.1.68-.15 1.07-.15 0 0-4.6-7.54-8.17-7.75-3.57-.21-7.09 6.35-3.57 9.2h4.89-3.57s-2.72.72-2.77 7.33c-.01.94-.01 1.75-.02 2.44-.01 1.02-.01 1.79-.02 2.37l-.01 1.81 1.46-1.81.35-.43.4.43 1.68 1.81s-.06-.71-.1-1.81c-.02-.67-.04-1.48-.02-2.37.06-3.61.57-8.46 2.81-9.78h.65V4275.51h4.44v-19.18zM428.26 4256.33v-9.79H426.4v28.97h1.86v-16.82z"
      />
      <path
        style={{
          fill: "#a96ed3",
        }}
        d="M416.32 4245.24s2.75-2.94 5.53-3.13c2.78-.19 2.55 4.35-2.76 4.43h-.01 7.91c3.52-2.86 0-9.42-3.57-9.2-3.57.21-8.17 7.75-8.17 7.75.37.01.71.08 1.02.18.03-.02.05-.03.05-.03z"
      />
      <path
        style={{
          fill: "#ffce00",
        }}
        d="M418.31 4246.54s-.01 0 0 0c-.3 0-.57-.02-.8-.03v.03h-2.25 3.05z"
      />
      <path
        style={{
          fill: "#a96ed3",
        }}
        d="M414.05 4245.24c-.77.24-1.3.72-1.32 1.28v.02h4.77v-.03c-.02-.54-.51-1-1.23-1.24-.31-.1-.65-.17-1.02-.18h-.13c-.38 0-.74.05-1.07.15z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M406.45 4268.8c-.01 2.25-.51 4.27-.51 4.27l-1.57-2.52-2.05 2.52s.12-2.13-.02-4.27h-8.72v8.7h18.29v-8.7h-5.42zM368.1 4268.8h19.75v8.7H368.1zM420.08 4268.8h3.1v8.7h-3.1z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M411.92 4268.8h-.06v8.7h4.97v-8.7zM407.83 4262.51h-5.1c.63.2 1.24.48 1.78.86 1.25.88 1.75 2.52 1.89 4.2h6.58v-5.06h-5.15zM387.85 4262.51h-21.02v5.06h21.02zM401.34 4264.78c-1.05-1.27-4.05-2-5.32-2.25-.02-.01-.05-.01-.07-.01h-2.38v5.06h8.61c-.14-1.21-.4-2.27-.84-2.8zM420.08 4262.51v5.06h3.8v-5.06zM412.98 4262.51h3.85v5.06h-3.85z"
      />
      <path
        style={{
          fill: "#d4eafc",
        }}
        d="M368.1 4267.57v1.23h19.75v-1.23M406.41 4267.57c.03.41.05.82.05 1.23h5.41v-1.23h-5.46z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M393.58 4267.57v1.23h8.72c-.03-.41-.06-.83-.11-1.23h-8.61z"
      />
      <path
        style={{
          fill: "#d4eafc",
        }}
        d="M420.08 4267.57h3.1v1.23h-3.1zM411.92 4267.57h-.06v1.23h4.97v-1.23h-3.85z"
      />
      <path
        style={{
          fill: "#33bef2",
        }}
        d="M393.58 4267.57v-5.06h-5.73V4277.5h5.73v-8.7z"
      />
      <path
        style={{
          fill: "#d9f3fd",
        }}
        d="M420.08 4267.57v-5.06h-3.25V4277.5h3.25v-8.7z"
      />
      <path
        style={{
          fill: "#33bef2",
        }}
        d="M382.12 4262.51c-1.42-2.56.24-4.76 3.31-4.3 2.46.37 6.59 2.9 8.15 3.9.01-.33.16-.63.39-.83-1.37-1.5-4.17-4.02-8.54-5.5-6.53-2.2-8.42 4.33-7 6.73h3.69zM396.33 4262.13c1.52-.97 5.72-3.55 8.2-3.92 3.07-.47 4.72 1.74 3.31 4.3h3.7c1.42-2.4-.47-8.93-7-6.73-4.4 1.48-7.2 4.02-8.56 5.52.21.21.35.5.35.83z"
      />
      <path
        style={{
          fill: "#33bef2",
        }}
        d="M406.41 4267.57c-.14-1.68-.64-3.32-1.89-4.2-.54-.38-1.15-.66-1.78-.86-2.38-.76-5.16-.41-6.4-.19v.19h-.38c.02 0 .05.01.07.01 1.27.26 4.27.98 5.32 2.25.44.53.7 1.6.84 2.79.05.4.09.81.11 1.23.14 2.14.02 4.27.02 4.27l2.05-2.52 1.57 2.52s.5-2.02.51-4.27c0-.4-.01-.81-.04-1.22z"
      />
      <path
        style={{
          fill: "#33bef2",
        }}
        d="M393.58 4262.11V4262.52h2.76v-.37c0-.33-.14-.63-.37-.84-.2-.19-.48-.3-.77-.3h-.47c-.29 0-.55.11-.75.28-.24.19-.39.48-.4.82z"
      />
      <path
        style={{
          fill: "none",
        }}
        d="M400.62 4271.12h169.09v67.29H400.62z"
      />
      <text transform="translate(449.822 4289.874)">
        <tspan
          x={0}
          y={0}
          className="gem-numbers-thousand"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"5700"}
        </tspan>
        <tspan
          x={-36.01}
          y={17}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"sinterklaaskadootjes "}
        </tspan>
        <tspan
          x={-7.66}
          y={34}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Toys n Wrap"}
        </tspan>
      </text>
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="m775.04 4290.9-4.18-14.39 22.09-6.21-9.43-20.3 13.84-6.22 13.1 28.21c.92 1.98.92 4.26-.01 6.24a7.583 7.583 0 0 1-4.82 4.06l-30.59 8.61zM716.48 4317.88l11.54-.91 6.78 21.5-13.19 3.87-2-10.75s-.26-7.88-3.13-13.71z"
      />
      <path
        style={{
          fill: "#ff7134",
        }}
        d="m723.85 4339-.01.01c0-.01 0-.01.01-.01z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M737.02 4335.45c-.06-.1-.16-.24-.27-.41-1.04-1.55-3.96-5.74-5.44-4.5-.08.06-.13.1-.15.09l.03.03v.03c.58 2.86-1.98 6.94-3.7 8.27-3.3 2.56-5.05.55-5.46-.17-.23-.42-.68-.7-1.14-.7-.26 0-.61 1.36-1.14 3.21-.04.14-.08.29-.13.44-.08.28-.17.57-.25.87-.04.14-.09.29-.13.43l-.18.58c-.05.14-.09.29-.14.43-.53 1.58-1.17 3.2-1.97 4.5l-.36.51-4.89 6.05c-.72.9-1.66 2.35-1.34 3.39 0 0-.01-.01-.02-.01 0 .01.02.02.02.04.06.18.14.35.29.49.67.7 1.67.93 2.45.56 7.38-2.77 13.97-7.99 18.91-14.01 1.25-1.52 2.41-3.13 3.94-4.36.76-.61 1.64-1.18 2-2.08.49-1.22-.19-2.58-.93-3.68z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="m782.78 4334.29-3.63-10.81-22.77 2.21 1.5 13.45 10.85-2.36s7.48-2.82 14.05-2.49z"
      />
      <path
        style={{
          fill: "#ff7134",
        }}
        d="M760.14 4335.82c0 .01 0 .01-.01.02.01-.01.01-.01.01-.02z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M758.35 4322.5c-.36-.07-.73-.11-1.11-.14.01 0 .02 0 .02-.01-.29-.02-.58-.02-.87 0l-.24.03c-.23.03-.44.08-.65.15-.08.03-.16.05-.24.09-.27.11-.52.26-.74.47-.18.17-.32.36-.44.56-.36.61-.52 1.36-.73 2.05-.56 1.87-1.62 3.55-2.55 5.27-.93 1.7-1.77 3.48-2.51 5.31-1.1 2.75-1.97 5.61-2.54 8.51s-.83 5.85-.73 8.76c-.03.69.37 1.34 1.01 1.75.15.09.29.19.47.25.16.06.32.08.48.08.08 0 .15-.03.22-.04.07-.01.15-.01.22-.04h-.01c.97-.32 1.84-1.56 2.35-2.47l3.77-6.78.34-.52c.63-.85 1.43-1.7 2.29-2.5.11-.1.22-.21.33-.31.17-.15.34-.3.5-.45.11-.1.23-.2.35-.3.15-.12.29-.25.43-.37.12-.1.23-.2.35-.29 2.18-1.79 4.14-3.12 4-3.43-.18-.41-.62-.71-1.1-.76-.84-.09-3.4-.89-2.28-4.88.58-2.07 3.41-5.98 6.31-6.56.02 0 .02-.01.03-.01.01 0 .03.01.04.01-.01 0 0-.07.03-.17.67-2.01-5.63-3.04-6.8-3.26z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="m837.48 4319.57-52.69-1.44v1.15s-28.54.65-44.1 0c-2.27-.1-4.23-.51-5.9-1.2-1.13-.47-2.13-1.06-2.99-1.76l-.03-.03c-.45-.37-.87-.77-1.25-1.19l-2.35-2.25-14.15-13.56-18.83-18.05 1.68-23.26s1.98-.38 7.68 3.9c4.75 3.57 12.07 10.36 22.97 23.29v-3.32l45.52 3.83 11.74 2.84s16.74 3.07 46.4 18.95c2.18 1.17 6.3 12.1 6.3 12.1z"
      />
      <path
        style={{
          fill: "#63439c",
        }}
        d="M801.84 4250.67c-.09.67-2.31 1.78-2.88 2.27-1.02.86-1.99 1.78-3.07 2.57-.95.69-1.98 1.27-3.03 1.81-1.15.59-2.34 1.11-3.54 1.59-1.02.41-3 1.47-4.06 1.31-.93-.14-1.79-2.6-2.22-3.39-1.5-2.8-2.99-5.6-4.49-8.4-.13-.23-.25-.47-.38-.7l.29 6.81-5.07 35.01c-27.27 6.88-45.14-.02-45.14-.02l-5.04-35.35-1.15-6.69c-1.95 3.44-3.49 7.1-5.73 10.38-.71 1.04-.73 1.38-1.86.97-.94-.33-1.86-1.03-2.77-1.46-1.81-.88-3.66-1.65-5.44-2.57-1.91-.98-3.77-2.13-5.29-3.64-.56-.55-1.84-1.67-1.96-2.47-.14-.86.66-1.51 1.12-2.22l7.6-11.83c5.96-9.28 15.08-16.22 25.77-19.4 1.05-.31 5.7-1.21 9.9-2 3.85-.72 7.32-1.35 7.32-1.35l.27.06.14-.03s4.3.81 8.64 1.69c3.83.77 7.69 1.59 8.67 1.94a49.311 49.311 0 0 1 15.64 9.05c4.57 3.91 8.41 8.64 11.3 13.99 1.49 2.75 2.98 5.5 4.46 8.25.5.92 1 1.84 1.5 2.77.12.22.54.77.5 1.05z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M761.48 4213.87c-.06 1.1-.64 2.12-1.62 2.82-1.76 1.26-4.32 2.01-6.83 2.01-2.44 0-4.67-.7-6.44-2.04-.9-.68-1.44-1.7-1.46-2.81l-.01-.35c3.85-.72 7.32-1.35 7.32-1.35l.27.06.14-.03c-.02 0 4.28.81 8.63 1.69z"
      />
      <path
        style={{
          fill: "#f58580",
        }}
        d="m766.96 4195 .45-1.53c.21.09.93.3 1.16.82.1.24.39.97.06 3.05a13.48 13.48 0 0 1-1.35 4.06c-.3.59-.95 1-1.71 1.08l1.39-7.48z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M762.19 4321.71c-.31.32-.46.85-.55 1.86-.4 4.1.22 8.32 1.77 12.16.55 1.36 1.44 4.15 2.86 4.32.65.08 1.6-.31 2.24-.43 7.09-1.37 14.18-2.73 21.28-4.1l24.97-5.2 16.98-3.27c3.91-.75 6.43-4.66 5.62-8.72l-.46-2.31c-1.75-8.8-9.16-11.58-17.6-9.74l-4.74 1.2c-7.67 1.94-9.52 3.15-17.19 5.1l-25.61 6.48c-2.63.67-5.26 1.33-7.9 2-.84.2-1.35.32-1.67.65zM717.88 4336.53c.92.58 2.12.25 3.58.07 2.09-.26 4.16-.71 6.12-1.46 2.07-.79 4.02-1.93 5.64-3.43.58-.54 1.94-1.64 2.07-2.45.14-.84-.9-2.23-1.23-2.99-2.96-6.76-5.92-13.53-8.88-20.29l-13.08-29.9-4.14-8.4c-2.43-4.94-5.59-10.32-11.83-10.94-10.04-.99-12.61 9.54-11.05 17.41.95 4.81 3.19 9.26 5.45 13.63 8.33 16.11 18.32 31.46 26.41 47.66.28.52.59.87.94 1.09z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="m741.32 4208.59.35 13.9c.03 1.09.63 2.11 1.62 2.77 6.17 4.13 14.94 2.7 19.16.02 1.12-.71 1.86-1.8 1.86-3.01l-.07-15.51-22.92 1.83zM739.93 4203.92h-1.88c-1.68 0-3.2-.78-3.9-1.99-.83-1.44-1.41-2.97-1.68-4.54-.89-4.99 1.36-4.89 2.58-5.4l2.8 1.92 2.08 10.01zM767 4193.91l2.8-1.92c1.22.51 3.47.41 2.58 5.4-.28 1.57-.85 3.1-1.68 4.54-.7 1.21-2.22 1.99-3.9 1.99h-1.88l2.08-10.01z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="m736.54 4196.4-3.67-10.5c-1.52-4.34-.44-9.06 2.87-12.55l7.65-8.06h20.84l6.04 8.56c2.2 3.12 2.86 6.89 1.81 10.45l-4.2 14.26-8.98-17.59-15.74-.61-6.62 16.04z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="m738.08 4176.31-1.71 3.97c-.87 2.02-1.19 4.2-.95 6.35l2.42 21.15c.15 1.31.86 2.51 2 3.37l8.8 6.67c2.18 1.65 5.4 1.65 7.58 0l8.76-6.64c1.15-.87 1.87-2.11 2-3.44l2.14-21.72c.19-1.95-.08-3.91-.81-5.77l-1.54-3.93h-28.69z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M775.53 4165.54c-.6-.77-2.29-1.39-4.33-1.88-.07-1.78-.49-3.28-1.55-3.96-3.09-1.98-16.73 1.41-16.73 1.41-10.32.6-18.23 7.09-17.67 14.49v.01l2.58-.15-2.79 1.65 2.65 1.97c2.27 1.69 5.34 2.33 8.24 1.7l20.85-4.49-1.05-.73 4.54 1.16c.01.02 7.45-8.37 5.26-11.18z"
      />
      <path
        style={{
          fill: "#ec008c",
        }}
        d="M700.68 4259.81c-.97-.53-2.34-.51-3.38-.02-1.54.73-2.61 2.14-3.85 3.3-1.24 1.16-2.94 2.14-4.59 1.7.88-1.29-.28-3.24-1.84-3.43s-3.05 1.02-3.55 2.49c-.91 2.68 1.08 5.67 3.7 6.81 2.63 1.14 5.66.86 8.47.29a20.605 20.605 0 0 1-6.04 6.19c-1.23-1.5-3.05-3.16-4.86-2.42-1.85.76-1.88 3.32-1.34 5.22.53 1.89 1.49 3.88 3.34 4.6 1.06.41 2.26.33 3.38.1 3.15-.64 6.05-2.38 8.07-4.83 4.33 1.61 9.08 3 13.52 1.74 3.73-1.06 6.72-3.89 8.75-7.14 3.82-6.13 6.54-13.46 4.64-20.58-1.45-5.4-5.52-11.44-11.84-11.44-3.98 0-7.25 2.58-9.34 5.73-.78 1.18-3.85 12.83-2.21 12.83-.2-.51-.57-.89-1.03-1.14z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M724.78 4249.45c-.58-.75-1.36-1.35-2.3-1.48.4.05-.49 6.76-.61 7.39-.44 2.45-.98 4.94-2.01 7.23-.69 1.53-1.3 1.89-2.81 2.28-1.46.38-2.56 1.64-2.32 3.19.19 1.22 1.25 2.24 2.49 2.45 2.7.45 3.09-2.04 4.51-3.53 1.43-1.51 2.67-3.29 3.32-5.26 1.16-3.5 2.14-8.08.24-11.52-.17-.26-.33-.51-.51-.75z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M713.86 4264.65c-.2.05-.39.09-.59.12-2.77.34-4.5-3.49-5.15-5.55-.71-2.28-.88-4.7-.9-7.09-.01-1.45.01-2.97-.7-4.24-.21-.37-.5-.74-.92-.84-.48-.12-.98.13-1.36.45-1.34 1.13-1.71 3-1.82 4.73-.22 3.45.38 6.5 2.12 9.52 1.1 1.92 2.43 3.76 4.14 5.2.82.69 1.48 1.15 2.12 1.95.63.79 1.25 1.45 2.33 1.63 1.19.2 2.49-.32 3.16-1.32.69-1.03.55-2.67-.06-3.72-.3-.51-.81-.91-1.4-.96-.35-.03-.66.04-.97.12zM696.72 4233.67c.76 2.78.01 5.4-1.68 5.84-1.68.44-3.66-1.46-4.42-4.24-.76-2.78-.01-5.4 1.68-5.84 1.69-.44 3.67 1.46 4.42 4.24z"
      />
      <path
        style={{
          fill: "#f799d1",
        }}
        d="M693.96 4228.7c0 11.63 9.23 21.05 20.61 21.05 11.38 0 20.61-9.43 20.61-21.05s-9.23-21.05-20.61-21.05c-11.38 0-20.61 9.42-20.61 21.05z"
      />
      <path
        style={{
          fill: "#7558a7",
        }}
        d="M716.6 4206.58c3.35-2.12 6.53-2.41 10.13-.59 3.47 1.76 6.99 4.96 8.25 8.66.77 2.25 1.09 4.54 1.07 6.91-.01 1.16-.08 2.32-.18 3.47-.09.97-.52 2.17-.69 3.67-.62-3.81-3.22-7.26-6.25-9.71 3.62 4.95 6.36 10.89 5.84 16.96-.4 4.69-2.73 9.05-5.65 12.78.26-.33 4.59-7.59 2.43-15.24-3.7-13.1-16.23-18.77-16.21-18.8-1.49 2.41-4.21 3.75-6.63 5.26-6.66 4.14-11.74 10.57-13.24 18.28-.71-2.04-1.11-4.17-1.51-6.29-.35-1.86-.69-3.71-1.04-5.57-.49-2.63-.37-5.81.51-8.35 1.03-2.98 2.98-5.58 5.25-7.8 2.36-2.31 5.17-4.28 8.38-5.14 3.22-.86 6.88-.49 9.54 1.5z"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M694.74 4263.47c-.68 3.96 2.62 7.85 6.57 8.85.3.08.64.13.9-.03.2-.13.31-.35.4-.57.65-1.58.95-3.36.47-4.99-.25-.83-.68-1.59-1.04-2.38-.53-1.16-.89-2.39-1.09-3.65-.1-.64-.16-1.29-.17-1.93-.01-.43.21-1.27-.02-1.65-.93-1.56-3.98 2.24-4.46 2.89-.76 1.03-1.34 2.2-1.56 3.46z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="m749.46 4288.79 26.71-.06.35-13.44-25.22-.28c-.91-.01-1.66.71-1.67 1.61l-.17 12.17z"
      />
      <path
        style={{
          fill: "#fab15a",
        }}
        d="m769.49 4269.22 5.44 9.84-17.07 9.73-5.72-10.04z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="m773.42 4280.4-.31 11.65-21.85.01c-1 0-1.81-.81-1.8-1.79l.17-13.66c0 .92.75 1.66 1.68 1.67l20.34.29c1 .03 1.8.85 1.77 1.83z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.75,
          strokeMiterlimit: 10,
        }}
        d="m773.13 4292.04.31-11.65c.03-.98-.77-1.8-1.77-1.82l-20.37-.29c-.91-.01-1.64-.74-1.64-1.64 0-.91.76-1.64 1.68-1.63l1.43.02"
      />
      <path
        style={{
          fill: "#fbcce8",
        }}
        d="M763.91 4289.16c-.05-.71.03-1.45.25-2.18.75-2.5 2.78-4.92 4.86-7.24 7.31-8.17 13.25-4.74 15.42 1.26 2.06 5.7-.28 11.66-10.47 12.56-2.48.22-5.07.34-7.01-.4-1.95-.75-2.92-2.28-3.05-4z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M707.7 4231.19c0-1 .83-1.82 1.85-1.82s1.85.81 1.85 1.82-.83 1.82-1.85 1.82-1.85-.82-1.85-1.82zM720.59 4231.19c0-1 .83-1.82 1.85-1.82s1.85.81 1.85 1.82-.83 1.82-1.85 1.82-1.85-.82-1.85-1.82z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#522e91",
          strokeMiterlimit: 10,
        }}
        d="M720.09 4243.23c-2.55-2.98-5.76-3.13-8.32-1.02"
      />
      <path
        style={{
          fill: "#fff",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
        }}
        d="m765.69 4238.66 8.69-10.07M765.69 4235.79l3.61 2.35M767.5 4233.6l3.5 2.19M769.25 4231.25l3.44 2.35M770.15 4229.09l5.07 3.16"
      />
    </g>
      </>
  )
}