import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Section3Figures() {
  useEffect(() => {
    gsap.from('.cjg-numbers', {
      textContent: 0,
      duration: 2,
      ease: "power3.inOut",
      snap: { textContent: 1 },
      scrollTrigger: '.cjg-numbers'
    })
    
  }, [])

  useEffect(() => {

    gsap.set('.location', {
     
    })

    gsap.timeline({repeat:-1})
    .to('#corona',
      { duration: 3,  transformOrigin: '50% 50%',  rotation:"-=360", ease:'none'}   
    );

    gsap.timeline({repeat:-1})
   .to('.spin',
      { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=30", ease:'none'}   
    )
   .to('.spin',
      { duration: 3,  transformOrigin: '50% 50%',  rotation:"+=30", ease:'none'}   
    )
  
  }, [])

  return (
      <>
      <g>
     
      <path
        style={{
          fill: "none",
        }}
        d="M580.41 2440.44H749.5v67.29H580.41z"
      />
      <text transform="translate(649.337 2459.195)">
        <tspan
          x={0}
          y={0}
          className="cjg-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"39"}
        </tspan>
        <tspan
          x={-20.42}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 12,
          }}
        />
        <tspan
          x={-17.22}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"stagiaires"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M613.47 2224.65h169.09v67.29H613.47z"
      />
      <text transform="translate(663.645 2265.9)">
        <tspan
          x={0}
          y={0}
          className="cjg-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 55,
          }}
        >
          {"52"}
        </tspan>
        <tspan
          x={68.75}
          y={0}
          style={{
            fill: "#ec008c",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 30,
          }}
        />
        <tspan
          x={6.4}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"locaties"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M45.06 2230.29h169.09v67.29H45.06z"
      />
      <text transform="translate(106.173 2249.038)">
        <tspan
          x={0}
          y={0}
          className="cjg-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 25,
          }}
        >
          {"100"}
        </tspan>
        <tspan
          x={-16.76}
          y={16}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"vrijwilligers"}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M669.32 2334.5h180v67.85h-180z"
      />
      <text transform="translate(726.515 2360.752)">
        <tspan
          x={0}
          y={0}
          className="cjg-numbers"
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 35,
          }}
        >
          {"642"}
        </tspan>
        <tspan
          x={-12.8}
          y={18}
          style={{
            fontFamily: "'Sansa'",
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"professionals"}
        </tspan>
        <tspan
          x={78.42}
          y={18}
          style={{
            fontFamily: "'Sansa'",
            fontSize: "12.7742px",
          }}
        />
      </text>
      
      
    
      <path
        style={{
          fill: "none",
        }}
        d="M793.42 2108.6h31.47v11.75h-31.47z"
      />
      <g >
        <rect x="793.42" y="2108.6" style={{fill:'none'}} width="31.47" height="11.75"/>
        <text transform="matrix(1 0 0 1 799.0986 2117.2886)" style={{fill:'#F99D31', fontFamily:'Sansa', fontWeight: 'bold', fontSize:'11.5885px'}}>ISO</text>
        <path style={{fill:'none', stroke:'#F99D31', strokeWidth:8, strokeMiterlimit:10}} d="M809.29,2084.18l-38.7,53.87    c0,0-3.08,14.7,24.67,16.09l59.37,0.02L809.29,2084.18z"/>
        <rect x="790.2" y="2121" style={{fill:'none'}} width="37.9" height="11.42"/>
        <text transform="matrix(1 0 0 1 794.665 2129.6899)" style={{fill:'#F99D31', fontFamily:'Sansa', fontWeight: 'bold', fontSize:'11.5885px'}}>9001</text>
        <rect x="788.8" y="2133.44" style={{fill:'none'}} width="40.69" height="15.01"/>
        <text transform="matrix(1 0 0 1 793.5859 2144.3042)" style={{fill:'#F99D31', fontFamily:'Sansa', fontWeight: 'bold', fontSize:'14.4856px'}}>HKZ</text>
      </g>
      <path
        style={{
          fill: "none",
        }}
        d="M788.8 2133.44h40.69v15.01H788.8z"
      />
  
      <path
        style={{
          fill: "none",
        }}
        d="M39.79 2325h167.69v50.95H39.79z"
      />
      <text transform="matrix(.9431 0 0 1 66.253 2335.499)">
        <tspan
          x={-15}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Nieuwe directeur "}
        </tspan>
        <tspan
          x={-14.13}
          y={17}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"Inge Diepenhorst "}
        </tspan>
      </text>
      <path
        style={{
          fill: "none",
        }}
        d="M361.18 2429.62h167.69v67.29H361.18z"
      />
      <text transform="matrix(.9431 0 0 1 373.543 2440.123)">
        <tspan
          x={0}
          y={0}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"CJG ondersteunt GGD "}
        </tspan>
        <tspan
          x={-8.46}
          y={17}
          style={{
            fill: "#522e91",
            fontFamily: "'Sansa'",
            fontWeight: 'bold',
            fontSize: 14,
          }}
          className="text-responsiveness"
        >
          {"met corona-vaccinaties"}
        </tspan>
      </text>
      <g className="spin location">
     

			<circle fill='#522E91' cx="705.12" cy="2194.96" r="22.54"/>
	
			<path fill='#FFFFFF' d="M702.53,2187.27c1.36-2.75,4.69-3.88,7.45-2.52c2.75,1.36,3.88,4.69,2.52,7.45     c-1.36,2.75-4.69,3.88-7.45,2.52C702.3,2193.35,701.17,2190.02,702.53,2187.27 M697.67,2209.65c6.58-2.23,16.99-9.64,19.67-15.07     c2.68-5.43,0.46-12-4.97-14.68c-5.42-2.68-12-0.46-14.68,4.97C695.01,2190.3,695.58,2203.48,697.67,2209.65"/>
      </g>
      <path
        style={{
          fill: "#fff",
        }}
        d="M354.36 2383.77h-4.13l5.17-65.12h5.67zM410.53 2383.77h4.13l-5.16-65.12h-5.68zM368.65 2290.34h3.21v18.23h-3.21zM393.04 2290.34h3.21v18.23h-3.21z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M349.42 2310.35c3.48 1.41 10.01 2.75 14.03 3.49 1.96.36 3.32.59 3.43.6h.01l15.64-5.71 13.73 5.81h.01l2.45-.27 2.92-.32-.04-.89-.47-10.73-.37-8.32-.08-1.91s-17.83-1.77-34.77-2.66a16.89 16.89 0 0 1-4.75 4.27c-.76.46-1.52.9-2.28 1.37-.69.55-2.35 2.87-4.1 5.56-2.51 3.9-5.2 8.55-5.36 9.71z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="m356.4 2276.25 5.72-21.83-3.09-9.75-8.8 5.27s-3.63 12.09-5.39 26.38c0 3.16 2.12 18.68 10.39 41.53l4.8-5.9-3.63-35.7z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M364.21 2290.57c-2.51 1.56-17.32 6.01-15.74 22.95l22.02-.77-4.29-16.17-1.99-6.01z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="m355.24 2240.06-10.99 20.02 15.68 5.9 4.79-11.15c-.01.01-2.52-18.43-9.48-14.77"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="m361.26 2288.63-.19 3.21c5.51 5.17 21.99 6.54 21.99 6.54s17.12-.98 22.63-6.15c0 0-1.09-18.69-1.61-28.42v-.01c-.19-3.35-.3-5.63-.28-5.73l.72-1.63 1.21-3.1 5.17-13.28s-13.92-12.7-27.84-14.33c-13.92 1.63-27.82 14.33-27.82 14.33l7.79 18.01h.01l-1.78 30.56z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M402.1 2291c2.51 1.56 15.11 4.99 12.51 22.51l-22.05-.77 9.54-21.74z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M393.02 2228.53s-2.42 6.86-10.25 7.15c-7.84.29-10.73-6.61-10.73-6.61l.71-3.35 1.43-6.8 7.97.92 7.87-1.5.58 1.99 2.42 8.2zM424.49 2276.7c-1.75-14.29-13.78-36.5-13.78-36.5l-2.96 4.47-5.18 9.75s6.83 12.39 11.27 19.77c-5.51 5.61-21.28 19.19-21.28 19.19l2.1 5.01c17.64-9.57 27.99-17.64 28.53-18.26 1-.86 1.57-1.45 1.3-3.43z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="m410.89 2240.06 1.69 3.08-8.77 14.93-3.12-2.53s3.24-19.14 10.2-15.48"
      />
      <path
        style={{
          fill: "#e5b7ac",
        }}
        d="M393.03 2365.55s-1.37 9.24-1.63 10.28c-.26 1.04-1.49 9.23 0 10.4s12.12 1.17 12.66 0c.54-1.17-3.13-11.46-3.5-12.74-.37-1.29.13-5.47.13-5.99s-7.66-1.95-7.66-1.95z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M391.4 2368.67c4.51-2.25 11.32.26 11.32.26s13.06-42.34 12.07-59.93c-.09-1.6-.3-3.01-.64-4.15-2.54-8.62-8.84-8.92-13.02-5.54a10.814 10.814 0 0 0-3.77 6.12c-.16.49-.32 1.21-.49 2.13-.13.66-.25 1.44-.38 2.3-.08.52-.15 1.07-.23 1.65-.92 6.86-1.92 17.9-2.77 28.46-1.18 14.82-2.09 28.7-2.09 28.7z"
      />
      <path
        style={{
          fill: "#e5b7ac",
        }}
        d="M370.14 2365.55s1.37 9.24 1.63 10.28c.26 1.04 1.49 9.23 0 10.4s-12.12 1.17-12.66 0c-.54-1.17 3.13-11.46 3.5-12.74.37-1.29-.13-5.47-.13-5.99s7.66-1.95 7.66-1.95z"
      />
      <path
        style={{
          fill: "#272425",
        }}
        d="M387.64 2385.03v.16c-.04 1.04.08 1.69.08 1.69 6.22 4.42 17.97-.55 17.97-.55s-.03-.23-.05-.57c-.06-.61-.15-1.56-.22-2.2v-.02c-.09-1-3.96-7.58-3.96-7.58s-.23-.53-.92-1.13a.138.138 0 0 1-.03-.04c-.99-.85-2.55-1.88-4.29-1.84-2.09.23-3.61 1.6-4.43 2.55-.46.53-.69.92-.69.92-2.47 3.28-3.46 8.61-3.46 8.61z"
      />
      <path
        style={{
          fill: "#272425",
        }}
        d="M401.58 2376.48s-.34-2.85-.75-3.16c-.41-.31-1.71.86-2.37.98-.67.12-3.85.49-4.34.18-.49-.31-1.91-1.62-2.23-1.54-.32.07-.73 3.09-.67 3.58.06.49 6 .72 6 .72l4.36-.76M375.57 2385.03v.16c.04 1.04-.08 1.69-.08 1.69-6.22 4.42-17.97-.55-17.97-.55s.03-.23.05-.57c.06-.61.15-1.56.22-2.2v-.02c.09-1 3.96-7.58 3.96-7.58s.23-.53.92-1.13c.01-.01.02-.02.03-.04.99-.85 2.55-1.88 4.29-1.84 2.09.23 3.61 1.6 4.43 2.55.46.53.69.92.69.92 2.47 3.28 3.46 8.61 3.46 8.61z"
      />
      <path
        style={{
          fill: "#272425",
        }}
        d="M361.63 2376.48s.34-2.85.75-3.16c.41-.31 1.71.86 2.37.98.67.12 3.85.49 4.34.18.49-.31 1.91-1.62 2.23-1.54.32.07.73 3.09.67 3.58-.06.49-6 .72-6 .72l-4.36-.76"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M360.44 2368.92s6.81-2.51 11.32-.26c0 0-.9-13.87-2.1-28.7-.85-10.61-1.86-21.7-2.78-28.55-.25-1.9-.5-3.46-.74-4.61-.12-.57-.23-1.03-.35-1.38-1.14-5.38-6.46-9.29-11.01-7.82-2.37.77-4.53 2.99-5.78 7.23-4.11 13.67 11.44 64.09 11.44 64.09z"
      />
      <path
        style={{
          fill: "#e0b812",
        }}
        d="M403.8 2258.07s1.51-15.41 6.02-18.94l-.92-.75s-6.37 3.84-5.1 19.69z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M413.88 2245.8c-.1.8-10.07 12.27-10.07 12.27s-.19-1.79-.04-3.35c.15-1.55.34-6.87 3.35-12.65 1.12-1.5 1.91-2.91 3.31-3.1.55-.08.93.35 1.19.9.41.85.52 1.98.52 1.98l.01-.01c.08-.03.67.22 1.41.66.79.49.41 2.49.32 3.3z"
      />
      <path
        style={{
          fill: "#e0b812",
        }}
        d="M412.13 2241.85s-3.7 4.77-6.02 10.48c.13-.47 2.18-8.07 5.5-12.46.41.85.52 1.98.52 1.98z"
      />
      <path
        style={{
          fill: "#19b6f1",
        }}
        d="m391.76 2194.3 2.09-.87s4.15 1.53 4.61 9.86c.02 8.59-1.94 10.86-1.94 10.86-1.29-.02-.44-.1-.56-.17 0 0 .35-8.83-1.45-12.4-1.81-3.56-2.75-7.28-2.75-7.28z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M385.89 2195.16c0-.01-9.38-2.29-14.34 8.62 0 0-2.95 7.06.09 13.9 0 0 6.57 9.06 14.26 11.63 5.4 1.61 9.74-7.77 10.97-17.11.74-8.61-4.84-16.27-10.98-17.04z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M372.29 2214.03v4.23c-3.7 1.29-6.74-2.24-7.29-5.12-.28-1.45.08-2.74 1.29-3.18 2.56-.92 4.49 1.49 5.4 2.98.39.62.6 1.09.6 1.09z"
      />
      <path
        style={{
          fill: "#19b6f1",
        }}
        d="m385.11 2207.87 2.18-3.5s-6.98 3.68-14.02 1.92c0 0 1.91 7.73-.46 12.03 0 0-.88-5.68-2.37-7.03-1.49-1.35-2.58-1.54-2.58-1.54s-4.99-8.55 1.28-16.48 19.48-6.08 25.27-1.42c8.78.92-.24 13.83-9.3 16.02z"
      />
      <path
        style={{
          fill: "#fdd8ad",
        }}
        d="M380.48 2301.57c-.21-.75 1.05-3.71 1.63-4.27.76-.72 4.16-3.11 5.62-3.42.88-.27 3.11-.53 4.87-.7.64-.6 2.77-2.11 4.06-3.37 1.57 1.82 2.37 3.46 3.92 5.31-1.31.84-4.34 2.86-4.42 3-2.22 3.68-6.56 3.28-6.78 3.53-.22.25-.34 3.77-1.52 4.02-.57.05-1.04-.89-1.04-.89-.33 1.66-.84 1.93-1.29 1.71-.45-.23-.52-1.46-.52-1.46-.08 1.26-1.53 2.01-2.02 1.43-.49-.59.07-5.93.51-6.6.45-.67 3.09-2.85 3.09-2.85l-3.09 2.85s-.52.69-1.52 2.92c-.92 1.25-1.48.23-1.5-1.21z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M264.69 2381.86h-45.24c-3.57 0-6.46-2.89-6.46-6.46s2.89-6.46 6.46-6.46h45.24c3.57 0 6.46 2.89 6.46 6.46s-2.89 6.46-6.46 6.46z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M224.32 2360.93h36.63v7.5h-36.63z"
      />
      <path
        style={{
          fill: "#272425",
        }}
        d="m229.79 2438.99 7.39.32 2.44-65.35c.07-4.22-3.33-7.68-7.56-7.69-4.43-.01-7.91 3.77-7.55 8.18l5.28 64.54zM255.47 2438.99l-7.39.32-2.44-65.35c-.07-4.22 3.33-7.68 7.56-7.69 4.43-.01 7.91 3.77 7.55 8.18l-5.28 64.54z"
      />
      <path
        style={{
          fill: "#efd2ba",
        }}
        d="M255.53 2277.95c-.22 1.3-.42 2.46-.45 3.37-.03.9.13 1.58.67 1.81.54.21 1.29-.17 1.98-1.12.34-.47.66-1.07.93-1.77.26-.7.46-1.49.57-2.32.22-1.67.04-3.15-.34-4.09-.37-.95-.92-1.36-1.46-1.16-.55.2-.9.89-1.18 1.83-.29.94-.49 2.15-.72 3.45z"
      />
      <path
        style={{
          fill: "#efd2ba",
        }}
        d="M242.2 2253.52c-7.44-.17-15.97 2.42-16.01 13.92 0 0 2.18 13.73 2.57 16.26.38 2.54 7.99 12.77 12.96 12.77h.96c5.01 0 12.58-10.23 12.96-12.77.38-2.54 2.57-16.26 2.57-16.26-.04-11.5-8.57-14.09-16.01-13.92z"
      />
      <path
        style={{
          fill: "#efd2ba",
        }}
        d="M228.94 2277.95c.22 1.3.42 2.46.45 3.37.03.9-.13 1.58-.67 1.81-.54.21-1.29-.17-1.98-1.12-.34-.47-.66-1.07-.93-1.77-.26-.7-.46-1.49-.57-2.32-.22-1.67-.04-3.15.34-4.09.37-.95.92-1.36 1.46-1.16.55.2.9.89 1.18 1.83.29.94.49 2.15.72 3.45z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M259.31 2255.9c-.38-.73-.84-1.41-1.41-2.03-1.76-1.94-4.71-3.04-7.28-2.53-1.6-3.55-5.75-5.92-9.9-5.96-4.51-.05-8.82 2.31-11.6 5.66s-4.17 7.56-4.68 11.78c-.35 2.86-.31 5.74-.15 8.62-.31.9-.64 2.06-.88 3.43-1.02 5.94 3.91 17.83 3.91 17.83-.22-8.31-1.02-17.22 1.71-20.82 3.25-.98 6.41-2.16 9.34-3.81 4.1-2.31 8.18-5.13 10.04-9.22 1.5 2.33 5.48 5.25 7.49 6.96 2.17 5.32 1.89 15.71-1.16 25.08 0 0 5.89-8.93 6.32-14.96.36-5.11-.65-17.9-1.75-20.03z"
      />
      <path
        style={{
          fill: "#f99d31",
        }}
        d="M250.62 2251.34c-1.6-3.55-5.75-5.92-9.9-5.96-4.51-.05-8.82 2.31-11.6 5.66s-4.17 7.56-4.68 11.78c-.34 2.8-.31 5.62-.16 8.44 3.09-5.51 1.25-12.27 5.06-17.58 3.32-4.33 8.86-5.74 14.12-5.06 3.33.39 7.35 2.4 7.62 6.01.53-.9 1.32-1.83 2.44-1.94.76-.12 1.61-.27 2.46-.33-1.62-.96-3.58-1.38-5.36-1.02z"
      />
      <path
        style={{
          fill: "#efd2ba",
        }}
        d="M251.45 2308c0 2.5-16.79 2.5-16.79 0v-9.46h16.79v9.46z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M234.79 2298.64s-8.93 0-14.48 5.23c-5.55 5.23-8.93 23.88-10.17 35.32-1.23 11.45.62 26.17.62 26.17l7.41-.32s.62-14.72 1.85-25.18c1.23-10.47 3.99-16.36 3.99-16.36l1.01 38.94 17.47-.01v-52.98c-6.17-2.3-7.7-10.81-7.7-10.81z"
      />
      <g
        style={{
          opacity: 0.82,
        }}
      >
        <path
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          d="M224.14 2323.8h-.03a.259.259 0 0 1-.21-.29c.83-7.34.29-14.67-1.57-21.19-.04-.14.03-.28.16-.32.13-.04.27.04.31.17 1.88 6.59 2.43 13.99 1.59 21.41-.03.13-.13.22-.25.22z"
        />
      </g>
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M249.89 2298.47s9.26-.27 14.36 5.4c5.4 6 10.2 23.96 10.35 35.47.15 12.24-18.7 13.32-18.7 13.32l-.27-10.33s12.18.53 9.74-7.81c-3.11-10.64-4.81-11.04-4.81-11.04l.41 38.55-18.89.37v-52.98c6.17-2.27 7.81-10.95 7.81-10.95z"
      />
      <g
        style={{
          opacity: 0.82,
        }}
      >
        <path
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          d="M260.56 2323.89c-.12 0-.23-.1-.24-.23-.87-7.66-.36-15.09 1.46-21.49.04-.14.18-.22.31-.17.13.04.2.19.16.32-1.81 6.33-2.31 13.69-1.44 21.28.02.14-.08.27-.21.29h-.04z"
        />
      </g>
      <path
        style={{
          opacity: 0.82,
          fill: "#008eaf",
        }}
        d="m244.61 2362.43-.62-.03 2.19-51.96c-.7.01-2.36-.09-4.29-1.12-2.31-1.23-5.46-4.06-7.4-10.58l.59-.2c1.47 4.97 3.91 8.49 7.05 10.17 2.36 1.27 4.32 1.06 4.34 1.06.09-.01.18.02.24.09.07.07.1.16.1.25l-2.2 52.32z"
      />
      <g
        style={{
          opacity: 0.82,
        }}
      >
        <path
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          d="M242.82 2312.71c0-.36.28-.65.62-.65.34 0 .62.29.62.65 0 .36-.28.65-.62.65-.35.01-.62-.29-.62-.65z"
        />
        <ellipse
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          cx={243.13}
          cy={2319.21}
          rx={0.62}
          ry={0.65}
        />
        <ellipse
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          cx={242.82}
          cy={2325.7}
          rx={0.62}
          ry={0.65}
        />
        <ellipse
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          cx={242.51}
          cy={2332.2}
          rx={0.62}
          ry={0.65}
        />
        <ellipse
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          cx={242.2}
          cy={2338.69}
          rx={0.62}
          ry={0.65}
        />
        <path
          style={{
            opacity: 0.82,
            fill: "#008eaf",
          }}
          d="M241.28 2345.19c0-.36.28-.65.62-.65s.62.29.62.65c0 .36-.28.65-.62.65s-.62-.29-.62-.65zM240.97 2351.68c0-.36.28-.65.62-.65.34 0 .62.29.62.65s-.28.65-.62.65c-.35 0-.62-.29-.62-.65zM240.66 2358.17c0-.36.28-.65.62-.65s.62.29.62.65c0 .36-.28.65-.62.65s-.62-.28-.62-.65z"
        />
      </g>
      <path
        style={{
          opacity: 0.82,
          fill: "#008eaf",
        }}
        d="M210.47 2361.77c-.17 0-.3-.14-.31-.32-.01-.18.13-.33.3-.34l7.79-.28c.17 0 .31.14.32.32s-.13.33-.3.34l-7.79.28h-.01z"
      />
      <path
        style={{
          fill: "#efd2ba",
        }}
        d="M238.65 2291.56h7.72v9.82h-7.72z"
      />
      <ellipse
        style={{
          fill: "#ec008c",
        }}
        cx={256.52}
        cy={2287.99}
        rx={0.5}
        ry={0.55}
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M256.08 2287.72c-.04.08-.07.18-.07.28 0 .3.23.55.52.55.29 0 .52-.25.52-.55 0-.3-.23-.55-.52-.55-.93 0-1.69-.8-1.69-1.79s.76-1.79 1.69-1.79c.93 0 1.69.8 1.69 1.79 0 .51-.2.98-.53 1.31"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M256.22 2287.99c0 .18.14.33.31.33"
      />
      <ellipse
        style={{
          fill: "#ec008c",
        }}
        cx={227.24}
        cy={2287.78}
        rx={0.48}
        ry={0.55}
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M227.63 2287.48c.04.08.07.18.07.28 0 .3-.22.55-.5.55s-.5-.25-.5-.55c0-.3.22-.55.5-.55.9 0 1.63-.8 1.63-1.79s-.73-1.79-1.63-1.79-1.63.8-1.63 1.79c0 .51.2.98.51 1.31"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M227.49 2287.75c0 .18-.13.33-.3.33"
      />
      <path
        style={{
          fill: "#020001",
          stroke: "#000",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
        }}
        d="m227.62 2282.67-.13.95M256.28 2282.91l-.13.95"
      />
      <path
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 0.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
        d="M238.54 2298.02c-.97 1.47-1.17 3.47-.37 5.26 1.17 2.59 4.02 3.72 6.37 2.53s3.31-4.26 2.14-6.85c-.09-.19-.18-.38-.29-.55"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M166.39 2362.58h159v6.2h-159z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M290.15 2364.13h-6.7l14.32 94.19c.12.68.58 1.11 1.04.98l.22-.06c.47-.13.79-.78.73-1.48l-9.61-93.63zM199.99 2365.14h6.7l-14.32 94.19c-.12.68-.58 1.11-1.04.98l-.22-.06c-.47-.13-.79-.78-.73-1.48l9.61-93.63z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M292.61 2361.66h-1.7c-1.87 0-3.39-1.73-3.39-3.86v-1.76c0-2.13 1.52-3.86 3.39-3.86h1.7c1.87 0 3.39 1.73 3.39 3.86v1.76c0 2.13-1.52 3.86-3.39 3.86zm-1.69-8.1c-1.2 0-2.18 1.11-2.18 2.48v1.76c0 1.37.98 2.48 2.18 2.48h1.7c1.2 0 2.18-1.11 2.18-2.48v-1.76c0-1.37-.98-2.48-2.18-2.48h-1.7z"
      />
      <path
        style={{
          fill: "#ffd200",
        }}
        d="M287.97 2348.7c-2.98 0-5.39.81-5.39 1.81l1.15 11.84c.06.57.48 1 .98 1l6.19-.01c.49 0 .91-.41.98-.97l1.47-11.86c.01-1-2.41-1.81-5.38-1.81z"
      />
      <path
        style={{
          fill: "#dba349",
        }}
        d="M292.56 2350.94c0 .78-2.06 1.41-4.6 1.41-2.54 0-4.6-.63-4.6-1.41 0-.78 2.06-1.41 4.6-1.41 2.53 0 4.6.63 4.6 1.41z"
      />
      <path
        style={{
          fill: "#895743",
        }}
        d="M283.73 2351.51c.71.5 2.33.85 4.22.85 1.89 0 3.51-.35 4.22-.85-.71-.5-2.33-.85-4.22-.85-1.89 0-3.51.35-4.22.85z"
      />
      <path
        style={{
          fill: "#cceffc",
        }}
        d="m232.42 2327.73-23.66-.01c-1.09 0-1.95.93-1.85 2.02l2.74 30.84c.08.96.89 1.69 1.85 1.69h41.86c.96 0 1.76-.73 1.85-1.69l2.74-30.84c.1-1.09-.76-2.02-1.85-2.02l-23.68.01z"
      />
      <path
        style={{
          fill: "#80d6f7",
        }}
        d="M254.83 2362.65H209.6a.96.96 0 0 1 0-1.92h45.23a.96.96 0 0 1 0 1.92z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M236.57 2344.28c0 2.29-1.86 4.15-4.15 4.15s-4.15-1.86-4.15-4.15 1.86-4.15 4.15-4.15 4.15 1.86 4.15 4.15z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M226.89 2446.34c-.36-.15-.63-.49-.81-.87-.61-1.27-.24-2.91.56-4.03.79-1.12 1.94-1.85 3.05-2.54 1.31-.82 2.63-1.64 4.05-2.15 1.42-.52 2.97-.72 4.41-.28.55 1.04.35 2.42-.23 3.43-.58 1.02-1.48 1.73-2.38 2.38-2.24 1.6-4.63 2.91-7.13 3.89-.49.19-1.03.38-1.52.17zM258.26 2446.17c.34-.14.61-.47.78-.84.59-1.22.23-2.81-.54-3.89-.77-1.09-1.87-1.78-2.94-2.45-1.26-.79-2.54-1.58-3.91-2.08s-2.87-.69-4.26-.27c-.53 1-.34 2.33.22 3.31s1.42 1.67 2.29 2.3c2.16 1.54 4.47 2.81 6.88 3.75.49.19 1.01.36 1.48.17z"
      />
      <g id="corona">

      <circle
        style={{
          fill: "#ffe466",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={353.11}
        cy={2421.62}
        r={7}
      />

      <path
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="m365.12 2419.76-5.11.71"
      />
      <circle
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={342.11}
        cy={2411.62}
        r={1}
      />
      <path
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="m354.81 2414.83 1.95-5.28h.01M355.87 2432.65l-1.06-4.24M347.85 2417.02l-5.01-4.71M347.45 2425.73l-4.53 3.3"
      />
      <circle
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={342.11}
        cy={2429.62}
        r={1}
      />
        <circle
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={356.11}
        cy={2433.62}
        r={1}
      />
      <circle
        style={{
          fill: "#f99d31",
          stroke: "#ec008c",
          strokeMiterlimit: 10,
        }}
        cx={366.11}
        cy={2419.62}
        r={1}
      />
      <circle
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={350.61}
        cy={2420.12}
        r={0.5}
      />
      <circle
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        cx={353.61}
        cy={2424.12}
        r={0.5}
      />
      <path
        style={{
          fill: "none",
          stroke: "#ec008c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M357.11 2409.62c-.12 0-.24-.02-.35-.07a.988.988 0 0 1-.65-.93c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1z"
      />

      </g>
      
    
      
      <path
        style={{
          fill: "#522e91",
        }}
        d="m481.43 2289.02 1.41-34.73 33.51-1.74 9.16 33.36c.33 41.41-6.76 61.39-7.12 100.8h-6.88l-8.8-94.19 5.09 91.58h-8.28c-3.64-40.59-14.79-55.99-18.09-95.08z"
      />
      <path
        style={{
          fill: "#c5e3ed",
        }}
        d="M482.52 2392.77s-1.38 2.11.2 4.29c2.02 2.79 12.32 2.09 17.11.39 4.79-1.7 8.99.44 10.2-2.12 1.21-2.56.2-4.16.2-4.16l-27.71 1.6z"
      />
      <path
        style={{
          fill: "#16c3f9",
        }}
        d="M482.27 2393.37c.19.75 1.2 3.18 7.23 2.7 1.03-.08 2.66-.32 3.65-.42-1.2-1.12-1.59-3.56-1.59-3.56s6.52-2.35 7.88-3.9c1.37-1.56 1.87-7.96 1.87-7.96-.42-1.77-4.86-2.84-5.39-.32-.31 1.45-1.42 3.65-2.93 5.78-1.21 1.7-2.57 3.41-4.71 4.19-.05.02-.1.04-.16.06-.56.43-2.11.83-2.79 1.05-.06.02-.12.05-.19.08-2.76 1.15-3 1.72-2.87 2.3z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M491.13 2392.09s.37 2.44 1.53 3.56c4.09-.43 7.89-1.89 11.58-1.96 4.56-.08 6.11-1.78 6.11-1.78 1.14-2.99-.55-4.99-.85-5.97-.3-.98-.7-6.37-.7-6.37 0-.38-.8-1.17-2.28.81-1.48 1.98-6.89-.24-6.89-.24s-1.32 5.02-2.21 6.87c-1.01 2.09-6.29 5.08-6.29 5.08zM485.1 2391.07l.04-.01 3.08-.6c4.93-2.3 7.92-10.55 7.92-10.55h-.47s0 .01-.02.03c-.27.55-4.39 8.69-7.39 9.94-.05.02-.1.04-.16.06-.56.43-2.11.83-2.79 1.05l-.21.08z"
      />
      <path
        style={{
          fill: "#c5e3ed",
        }}
        d="M506.19 2389.27c0 1.05.58 1.9 1.3 1.9.72 0 1.3-.85 1.3-1.9 0-1.05-.58-1.9-1.3-1.9-.72 0-1.3.85-1.3 1.9zM494.71 2381.56l5.05 1.16-.2.93-5.05-1.16zM493.8 2383.55l5.1 1.52-.39.87-4.97-1.47zM492.66 2385.48l5.31 1.81-.33.89-5.24-1.78zM491.49 2387.09l5.07 2.42-.43.85-5-2.39zM508.71 2394.96s-.51 2.75 0 3.72c.51.97 2.46 3.19 8.08 2.8 5.63-.4 6.46-3.05 6.46-3.05l-.2-3.73-14.34.26z"
      />
      <path
        style={{
          fill: "#16c3f9",
        }}
        d="M508.71 2394.96s-.21 2.48 5.17 3.15c4.57.57 6.67-.15 9.17-3.41l-1.64-4-4.52-9.02s-.33-1.74-3-.77c-2.67.98-1.42 7.96-1.42 7.96l-3.76 6.09z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M513.78 2381.54s-.31 8.76-.2 10.95c0 0-2.53 2.02-4.87 2.47l2.62-12.82 2.45-.6zM521.5 2390.92l-3.72-9-1.44-.38c.51.87 2.69 11 2.69 11 1.78 1.16 4.02 2.16 4.02 2.16l-1.55-3.78z"
      />
      <path
        style={{
          fill: "#c5e3ed",
        }}
        d="M512.6 2383.21h5.05l.28.87-5.33.09zM512.6 2385.35h5.61l.22.87-5.83.09zM512.36 2387.64h6.4l.22.87-6.62.09zM512.36 2389.64l6.91-.07.24.94-7.27.09z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M450.45 2239.21s-6.78.15-7.63-.38c-.86-.53-5.25-4.06-6.46-4.24-1.21-.18-7.19-1.15-7.75-1.16-.57 0-1.28 1.65.34 2.6 1.62.95 3.54 1.16 3.86 1.24.32.09 1.35.84 1.21 1.07-.13.23-4.5.27-5.71.09-1.21-.18-7.28-1.84-8.33-1.42-1.05.42-.22 2.28 2.31 2.94s4.13 1.1 4.13 1.1.69 3.94 1.33 4.75c.64.81 4.04 1.78 7.01 2.2 2.97.42 6.06-.28 7.42-1.03l7.41.72.86-8.48z"
      />
      <path
        style={{
          fill: "#dbd4f5",
        }}
        d="m426.84 2239.43-1.97-1.59-7.75 6.86c.15.22.42.58.8.9.32.26.67.46 1.03.57l7.89-6.74z"
      />
      <path
        style={{
          fill: "#df922f",
        }}
        d="m422.07 2241.86-1.04-.75-4.13 3.53s.22 1.26 2.11 1.73l4.22-3.6-1.16-.91z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m424.85 2237.3 2.63 2.12-8.4 7.18c-.6-.13-1.09-.42-1.47-.74a4.414 4.414 0 0 1-1.03-1.23l8.27-7.33zm-6.92 8.29c.32.26.67.46 1.03.57l7.89-6.74-1.97-1.59-7.75 6.86c.14.23.41.58.8.9zM426.05 2237.88s.19.53.71.65l3.1-2.65-.8-.65-3.01 2.65z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m417.25 2245.2-1.05.94s.38.73 1.17.91l1.03-.88c-.4-.32-.78-.64-1.15-.97zM428.09 2239.99l-3.99-3.24.79-.68 3.98 3.24zM430.46 2236.68l-2.27-1.84.44-.38 2.27 1.84z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M427.27 2238.1s-.48-.29-.99-.4l-4.51 3.86.8.65 4.7-4.11z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m421.16 2240.74 2.27 1.85-.45.38-2.27-1.84zM416.69 2246.44l-3 2.83 3.24-2.62z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M426.21 2243.86s-1.09-.9-1.36-1.39c-.27-.49-.15-1.34-.13-1.72.02-.38 1.69-2.77 2.38-2.81.69-.04 1.44 1.08.68 2.71-.76 1.63-1.57 3.21-1.57 3.21z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M427.99 2245.32s-1.59-.1-2.29-.79c-.7-.69.23-3.76.73-4.4.5-.64 1.89-.72 2.26 1 .37 1.71-.7 4.19-.7 4.19z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="M430.75 2246.12c-.22.42-.81.85-1.87.4s-1.39-.58-1.52-.88c-.14-.29-.3-1.99-.05-2.47.25-.48 1.21-2.59 1.98-2.64.77-.06 1.6 1.12.94 2.4-.66 1.28-.75 1.57-.75 1.57s2.04.15 1.27 1.62z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m449.31 2236.11-1.04 15.1 20.27 3.55 8.28-15.66zM483.04 2172.96c1-4.55 5.26-3.04 5.26-3.04 6.06 2.01 10.85-.21 10.85-.21 3.37-1.87 6.55-1.64 6.55-1.64 4.16.23 5.6 5.2 5.6 5.2.99 3.16-1.32 17.56-1.27 17.54l2.71-1.81c-1.54-4.07 1.99-9.58 1.99-9.58 9.4-10.49 1.45-15.82 1.45-15.82s-4.84-2.58-6.06-5.69c0 0-2.89-8.32-13.47-4.7 0 0-4.25 1.72-7.14 1.9-1.75.11-3.18 1.56-4.1 2.92-.38.55-.7 1.14-.99 1.75-.31.66-.44 1.22-1.09 1.63-1.09.7-2.31 1.21-3.58 1.44 0 0-8.77 1.86 1.08 19.31 0 0 1.04 2.49-.09 4.61 0 0-1.09 2.53.67 4.7l2.29-2.12s2.41-8.91-.3-13.86c0-.02-.67-1.14-.36-2.53z"
      />
      <path
        style={{
          fill: "#f4d9bf",
        }}
        d="m498.78 2201.07-.54.16c-1.38.4-4.11.48-7.48-.59l-.53-.15a11.536 11.536 0 0 1-8.16-9.14l-.11-.65c-.35-2.07.08-4.19 1.21-5.96l.42-.65-.76-11.28c.64-8.69 8.42-15.07 17.07-14.01 8.36 1.03 14.3 8.65 13.27 17.01l-1.22 9.86a18.44 18.44 0 0 1-13.17 15.4z"
      />
      <path
        style={{
          fill: "#4b2d90",
        }}
        d="M482.14 2177.01c.06.65.13 1.27.4 1.89.29.65.54 1.34.68 2.03.37 1.85 1.49 2.89 3.3 3.23 1.49.28 2.97.29 4.38-.22 1.36-.49 2.29-1.43 2.82-2.78.14-.37.27-.75.4-1.12.27-.74.66-1 1.4-.96.71.04 1.09.34 1.37 1.09.1.26.18.52.27.78.77 2.24 2.38 3.48 4.64 3.9.24.04.48.08.71.11h.02c.98.11 1.96.08 2.92-.18 1.28-.35 1.97-1.22 2.3-2.47.23-.85.57-1.67.79-2.53.13-.52.17-1.06.22-1.61.05-.6-.34-.75-.34-.75-.86-.24-1.72-.48-2.6-.64-.7-.13-1.41-.14-2.11-.21-1.82-.17-3.59-.04-5.32.46-1.93.56-3.9.49-5.84-.26-2.07-.8-4.19-.97-6.32-.9-1.17.04-2.34.22-3.5.34-.46.05-.63.32-.59.8zm16.67.52c3.18-.96 6.46-.08 6.46-.08 2.31.45 2.03 3.1 2.03 3.1-.1 2.35-.68 2.82-.68 2.82-.95 1.26-3.6 1.03-3.6 1.03-5.33-.23-5.38-4.66-5.38-4.66-.14-1.87 1.17-2.21 1.17-2.21zm-13.13-.93s3.27-.6 6.47.64c0 0 1.32.45 1.21 2.31 0 0 .03 4.43-5.3 4.2 0 0-2.65 0-3.62-1.34 0 0-.59-.53-.73-2.88-.01-.01-.34-2.68 1.97-2.93z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M476.2 2169.76c-.52-6.06 3.55-6.93 3.55-6.93 1.28-.23 2.5-.74 3.58-1.44.64-.42.77-.98 1.09-1.63.29-.6.61-1.19.99-1.75.92-1.36 2.35-2.81 4.1-2.92 2.89-.18 7.14-1.9 7.14-1.9 10.58-3.62 13.47 4.7 13.47 4.7 1.22 3.12 6.06 5.69 6.06 5.69s7.95 5.33-1.45 15.82c0 0-3.53 5.51-1.99 9.58l-2.71 1.81c-.06.02 2.26-14.37 1.27-17.54 0 0-1.45-4.97-5.6-5.2 0 0-3.18-.23-6.55 1.64 0 0-4.79 2.22-10.85.21 0 0-4.26-1.51-5.26 3.04 0 0-.17 1.25-.11 2.49M483.75 2225.07l5.79 20.29c.47-31.94 4.22-41.92 4.22-41.92l-.05-.03c-2.13 1.33-8.68 5.6-9.42 8l4.66 7.31-5.2 6.35zM502.98 2220.57l8.42-11.79c-2.53-2.04-5.57-4.12-9.23-6.28l-.36 1.29s-3.47 17.49-4.47 39.51l10.22-18.72-4.58-4.01z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M523.26 2259.09c3.84-2.89 2.98-14.96 1.71-23.7-1.06-7.31-.66-16.22-13.57-26.61l-8.42 11.79 4.58 4.02-10.22 18.72c-.52 11.48-.37 24.2 1.29 36.3 3.05 22.2 5.87 36.92 5.87 36.92l18.54-3.72 10.63-2.28c.01 0-4.04-33.65-10.41-51.44z"
      />
      <path
        style={{
          fill: "#f4d9bf",
        }}
        d="M495.14 2218.85c3.39.24 5.52-10.37 6.11-13.76l-1.05-.52-.9-5.58s-4.16 1.64-5.94 1.54l1.01 5.49s-3.26 12.56.77 12.83z"
      />
      <path
        style={{
          fill: "#522e91",
        }}
        d="M501.25 2202.07c-.59 3.4-2.73 14-6.11 13.76-4.03-.27-.89-9.32-.89-9.32s-.13-3.36-.55-3.1l.05.03s-4.87 19.76-5.11 54.42c1.33.2 4.61.65 8.96.33.49-25.07 4.87-55.52 4.87-55.52l-1.22-.6z"
      />
      <path
        style={{
          fill: "#767a84",
        }}
        d="M493.28 2257.3c-.74 1.53-3.07 7.82 3.27 16.66.13-.04.25-.08.38-.13.65-9.33-1.68-14.16-3.65-16.53z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M524.72 2232.71c-1.36-8.51-3.08-14.81-6.25-15.2 0 0-8.09.51-7.45 11.98.64 11.46 3.42 18.32-2.4 20.87-5.81 2.56-15.15 6.57-15.15 6.57s-.08.13-.19.37c1.97 2.37 4.3 7.19 3.65 16.53 8.96-2.97 21.37-9.53 26.34-14.74 3.62-4.19 2.81-17.87 1.45-26.38zM508.01 2279.96l17.38-2.29s5.53 16.18-5.43 17.53c-10.97 1.34-11.87-6.11-11.95-15.24zM485.59 2210.98c-.03.1-.06.2-.06.3-3.98 3.85-13.69 26.08-15.02 28.71-.47-2.03 2.23 1.89 1.93.91-.59 3.06-1.99-3.46-5.06-2.5-3.06.96-6.51-.26-8.42-1.14-.18 2.32 1.05 5.89 3.04 9.93 2.87 5.84 5.88 9.19 10.64 7.27 4.76-1.91 7.81-5.64 7.81-5.64s1.41-21.03 5.47-37.32l-.33-.52z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M489.54 2260.03c-.08-5.34-.08-10.22-.01-14.67l-5.79-20.29 5.18-6.35-4.32-6.79c-4.07 16.28-5.47 37.32-5.47 37.32s-2.23 39.74.28 65.26l4.74.59 8.44.05c.01-.01-1.82-20.36-3.05-55.12z"
      />
      <path
        style={{
          fill: "#00aeef",
        }}
        d="m493.52 2257.3-2.53 1.49s-1.61-1.3-7.91-.52c-1.88.47-4.99-.78-5.32-.37-.33.41-.86 3.68 5.3 3.03-4.68 2.84-7.62 5.69-7.04 7.53.57 1.3 2.66-.8 3.96-2.09 1.31-1.29 4.49-2.97 4.49-2.97s-7.3 6.44-5.95 8.83c.58.82 2.47-1.21 4.62-3.72 2.15-2.51 3.39-3.32 3.39-3.32s-6.46 8.69-4.13 9.79c1.16.49 2.28-1.76 3.19-3.46.92-1.7 3.52-4.41 3.52-4.41s-4.12 8.02-2.18 8.51c1.94.49 3.13-4.48 4.12-7.13 2.27-.8 2.73-2.44 2.88-5.1 1.56-.74 1.95-1.03 1.95-1.03s-.45-2.27-2.36-5.06z"
      />
    </g>

      </>
  )
}