import React from "react";

export default function Section4() {
    return (
        <g id="scholen">
            <path
        style={{
          fill: "#7bc143",
        }}
        d="M728.32 3043.48h-63.24c-27.5 0-49.79-22.29-49.79-49.79s22.29-49.79 49.79-49.79h-3.65c17.53 0 31.75-14.21 31.75-31.75 0-17.53-14.21-31.75-31.75-31.75H391.82c-17.53 0-31.75 14.21-31.75 31.75 0 17.53 14.21 31.75 31.75 31.75H256.74c-27.5 0-49.79 22.29-49.79 49.79s22.29 49.79 49.79 49.79h76.14"
      />
      <path
        style={{
          fill: "#7bc143",
        }}
        d="M317.5 2908.29h63.24c27.5 0 49.79 22.29 49.79 49.79s-22.29 49.79-49.79 49.79h3.65c-17.53 0-31.75 14.21-31.75 31.75 0 17.53 14.21 31.75 31.75 31.75H654c17.53 0 31.75-14.21 31.75-31.75 0-17.53-14.21-31.75-31.75-31.75h135.08c27.5 0 49.79-22.29 49.79-49.79s-22.29-49.79-49.79-49.79h-76.14"
      />
        </g>
    )
}