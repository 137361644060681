export const data = {
    'kinderen': 
    [
    <div>
        <h3>Aandacht voor gevaren Squid Game</h3>
        <p>
        De Netflix-serie Squid Game was een grote hype en onderwerp van gesprek, maar absoluut niet geschikt voor kinderen onder de 16 jaar. Toch werden kinderen er op verschillende manieren mee geconfronteerd, bijvoorbeeld op sociale media en op het schoolplein. 
        </p>
        <p>
        In <i>Squid Game</i> worden kinderspelletjes gespeeld door mensen met schulden. Wie verliest of niet snel genoeg is, overleeft het niet. In deze serie zit veel geweld, maar tegelijkertijd worden er spelletjes gespeeld die herkenbaar zijn voor kinderen (zoals Annemaria koekoek). Dit kan voor kinderen erg beangstigend zijn óf ze juist nieuwsgierig maken.   
        </p>
        <p>
        <a rel="noopener noreferrer" href="https://centrumvoorjeugdengezin.nl/actueel/squid-game-doodeng-of-doodgewoon-voor-kinderen" target="_blank">Onze orthopedagoog Marieke gaf advies aan ouders om hiermee om te gaan</a>.
        </p>
        <p className="image-content kinderen-1"></p>
    </div>, 
    <div>
        <h3>SOA-thuistestactie voor jongeren</h3>
        <p>
        Op 29 september konden jongeren gratis en zonder afspraak een <a target="_blank" rel="noopener noreferrer" href="https://centrumvoorjeugdengezin.nl/actueel/soa-thuistestactie-voor-jongeren">soa-thuistestpakket</a> ophalen bij onze CJG-locatie in de Centrale Bibliotheek Rotterdam. Ook konden de jongeren terecht bij de jeugd-verpleegkundige met vragen over relaties, seks en liefde. 
        </p>
    </div>,
    <div>
        <h3>Ruim 5.700 sinterklaascadeautjes voor kinderen in Rotterdam</h3>
        <p>Voor sommige kinderen is het niet vanzelfsprekend om cadeautjes te krijgen tijdens Sinterklaas. Daarom worden er dit jaar 200 verhuisdozen met speelgoed verspreid onder gezinnen met een krappe portemonnee. Dit deden wij samen met Toys ‘n Wrap en meer dan 78 verschillende netwerkpartners.</p>
        <p>Sinds 3 jaar werken wij samen met Toys ‘n Wrap. Zij zamelen speelgoed in op scholen en kinderopvangorganisaties. Daarnaast zijn er veel speelgoedleveranciers en donateurs die Toys ‘n Wrap sponsoren met speelgoed. <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/ruim-5-700-sinterklaascadeautjes-voor-kinderen-in-rotterdam">Op deze manier</a> kunnen veel kinderen toch leuke en mooie cadeautjes krijgen voor Sinterklaas en Kerst.</p>   
        <p className="image-content kinderen-2"></p>
    </div>, 
    <div>
        <h3>Vaccinatiedagen: nog steeds iets anders dan normaal</h3>
        <p>Vanwege het coronavirus organiseerden wij in het voorjaar geen grootschalige vaccinatiedagen, zoals in sporthallen of Rotterdam Ahoy. Alle (ruim 13.000) 14-jarigen uit ons werkgebied, inclusief de jongeren uit 2006 die de vaccinatie nog niet hebben gehad, werden uitgenodigd om de prik tegen de meningokokkenziekte A, C, W en Y (MenACWY) te komen halen op een CJG-locatie bij hen in de buurt.</p>
        <p>In het najaar werden de CJG-vaccinatiedagen weer grootschaliger, maar wel coronaproof georganiseerd. Ruim 40.000 kinderen ontvingen hiervoor een uitnodiging.</p>
        <p className="image-content kinderen-3"></p>
    </div>,
    <div>
        <h3>Webinar en artikel over depressieve klachten bij jongeren</h3>
        <p>In 2021 steeg het aantal zelfdodingen onder jongeren met zo'n 15% ten opzichte van vorige jaren. 113 Zelfmoordpreventie signaleerde in 2021 ook veel ander leed onder jongeren, zoals toegenomen levensmoeheid, somberheid en verlies van perspectief.</p>
        <p>De coronatijd vroeg om meer flexibiliteit van zowel pubers als van hun ouders. Maar waarom is de coronatijd juist voor pubers zo zwaar? En hoe ga je er als ouder mee om?  Wij organiseerden de webinar ‘Pubers in coronatijd’, waarin orthopedagoog Anneke antwoord vragen van ouders beantwoordde. Daarnaast gaf jeugdverpleegkundige Annelies advies in een <a target="_blank" rel="noopener noreferrer" href="https://centrumvoorjeugdengezin.nl/actueel/depressie-bij-jongeren-wat-zijn-de-tekenen-en-hoe-ga-je-het-gesprek-aan">artikel over depressie bij jongeren</a>.</p>
    </div>
    ],
    'ouders': [
        <div>
            <h3>Onderzoek naar effectiviteit vrijwilligers van Ouders in Actie</h3>
            <p>Vrijwilligers die themabijeenkomsten organiseren om andere ouders te ondersteunen bij de opvoeding. Het klinkt gezellig en nuttig, maar kómen die ouders ook? En wat levert het op? Sinds 2013 werkt CJG Rijnmond met de methode 'Ouders in Actie'. Het doel: zorgen dat CJG Rijnmond ouders ondersteunt en, met hulp van vrijwilligers, óók in aanraking komt met moeilijk bereikbare ouders.</p>
            <p>‘Onderzoek naar de effectiviteit en meerwaarde van vrijwilligers was er nog niet’, vertelt Anja van den Berg-Bakker, beleidsadviseur bij CJG Rijnmond. ‘Dankzij de werkplaats ST-RAW <a target="_blank" rel="noreferrer" href="https://zonmw-jeugdmagazines.nl/magazine/samen-bouwen-aan-betere-jeugdhulp/thema-basisondersteuningenpreventie-2-vrijwilligers-vragen-worstelen/">konden we dat onderzoek uitvoeren</a>.’</p> 
        </div>,
        <div>
            <h3>Wethouder Reshma Roopram met CJG-professionals naar het theater</h3>
            <p>Reshma Roopram, wethouder van Barendrecht, genoot samen met CJG-professionals en meer dan 200 ouders van de theatervoorstelling <a target="_blank" rel="noopener noreferrer" href="https://centrumvoorjeugdengezin.nl/cursus/barendrecht-puber-in-huis-een-toer">‘Puber in huis? Een Toer’</a>!  Dit was een reeks van 3 theatercolleges over opvoeding. De theatercolleges waren een samenwerking tussen theater Het Kruispunt en CJG Rijnmond en ontstaan vanuit het preventief jeugdbeleid.</p>
            <p className="image-content kinderen-4"></p>
        </div>,
        <div>
            <h3>Nieuw cursusoverzicht zorgt voor veel meer bezoekers!</h3>
            <p>Er worden veel gratis cursussen, themabijeenkomsten en webinars georganiseerd voor (aanstaande) ouders en kinderen in de regio Rotterdam-Rijnmond. Door CJG Rijnmond zelf, of door onze samenwerkingspartners. In 2021 is ons nieuwe <a target="_blank" rel="noopener noreferrer" href="https://centrumvoorjeugdengezin.nl/cursus">cursusoverzicht</a> gelanceerd. Dit is een overzicht dat wij hebben gemaakt van al het aanbod in de regio!</p>
            <p>Dit heeft een enorme groei van bezoekers van de cursuspagina’s opgeleverd: van een gemiddelde van 327 bezoekers per maand naar 783 bezoekers per maand. Op een jaarbasis zijn dat 43.523 meer bezoekers!</p>
            <p className="image-content kinderen-5"></p>
        </div>,
        <div>
            <h3>Recordaantal aanmeldingen CJG-webinar 'Praten met je kind'</h3>
            <p>Met de komst van het cursusoverzicht zijn wij ook begonnen met het geven van webinars. Op 9 november hebben onze pedagogen Aïsha en Marjolein de webinar <a href="https://centrumvoorjeugdengezin.nl/cursus/rotterdam-webinar-praten-met-je-kind" target="_blank" rel="noopener noreferrer">'Praten met je kind'</a> gegeven. 104 ouders meldden zich aan, waarvan 62 de webinar live bekeken: de meeste kijkers ooit sinds we zijn gestart met het geven van webinars!</p>
            <p>Andere webinars die we hebben ontwikkeld in 2021:</p>
            <ul>
                <li><a href="https://centrumvoorjeugdengezin.nl/cursus/rotterdam-webinar-mediaopvoeding-0tot4jaar" target="_blank" rel="noopener noreferrer">Mediaopvoeding (0-4 jaar)</a></li>
                <li><a href="https://centrumvoorjeugdengezin.nl/cursus/rotterdam-webinar-mediaopvoeding-12" target="_blank" rel="noopener noreferrer">Mediaopvoeding  (12+)</a></li>
                <li><a href="https://centrumvoorjeugdengezin.nl/cursus/rotterdam-webinar-starten-met-oefenhapjes" target="_blank" rel="noopener noreferrer">Starten met oefenhapjes</a></li>
            </ul>
        </div>,
        <div>
            <h3>Gratis de Rotterdamse Peutergroep uitproberen met waardebon van CJG Rijnmond</h3>
            <p>Ouders konden in 2021 <a href="https://cjgrijnmond.nl/gratis-en-vrijblijvend-uitproberen-rotterdamse-peutergroep" target="_blank" rel="noopener noreferrer">de Rotterdamse Peutergroep</a> gratis 4 weken uitproberen. Zij kregen hiervoor bij hun bezoek aan CJG-locaties een waardebon mee. De gemeente Rotterdam heeft hier extra geld voor beschikbaar gesteld. Hiermee krijgen kinderen, die dat goed kunnen gebruiken, een steuntje in de rug. Onderwijswethouder Kasmi wil zo gelijke kansen creëren voor alle kinderen, zodat zij zonder achterstand kunnen starten op de basisschool.</p>
            <p className="image-content kinderen-6"></p>
        </div>
    ],
    'cjg': [
        <div>
            <h3>Hoe ervaren ouders en jongeren onze dienstverlening?</h3>
            <p>Ieder jaar voeren wij een <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/zo-werd-onze-dienstverlening-in-2021-ervaren">klanttevredenheidsonderzoek (KTO)</a> uit. Net als in 2020 werden wij dit jaar met een 7,8 gemiddeld beoordeeld. Ons sterkste punt is de vriendelijkheid van onze medewerkers. Daarnaast voelen veel ouders zich welkom en serieus genomen. Een van de reacties: 'Zeer kundig personeel. Ik voel me zeer welkom. Fijn dat jullie er voor ons zijn!’</p>
            <p className="image-content kinderen-15"></p>
        </div>,
        <div>
            <h3>Hans drinkt koffie met onze nieuwe directeur Jeugd & Gezin!</h3>
            <p>Op 1 juni 2021 verwelkomden wij Inge Diepenhorst als nieuwe directeur J&G (Jeugd en Gezin) bij CJG Rijnmond! Inge heeft ruime ervaring in het bedrijfsleven en maakt nu enthousiast een bewuste overstap naar het publieke domein.</p>
            <p>Inge's motivatie om CJG Rijnmond te komen werken: ‘Ik heb altijd al gezegd dat ik ooit de overstap wilde maken naar een functie met een maatschappelijke impact! Daarnaast geloof zelf heel erg in het preventieve stuk van de jeugdgezondheidszorg. Dat maakte CJG Rijnmond een logische keuze!’ Hans drinkt een kopje koffie met Inge om haar beter te leren kennen. Bekijk en beluister hun gesprek: </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/B6cK10Zda5A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>,
        <div>
            <h3>Trots om één van de ‘100 van Sander’ te zijn</h3>
            <p>CJG Rijnmond heeft een intentieverklaring getekend met <a href="https://cjgrijnmond.nl/cjg-rijnmond-tekent-intentieverklaring-100-van-sander" target="_blank" rel="noopener noreferrer">De 100 van Sander</a>. Daarmee spreken wij de intentie uit ons sterk maken om mensen voor wie het extra lastig is om aan werk te komen aan een baan te helpen.</p>
            <p>Wij zijn trots om één van ‘de 100 van Sander’ te zijn en zo bij te kunnen dragen aan een inclusieve arbeidsmarkt!</p>
            <p className="image-content kinderen-17"></p>
        </div>,
        <div>
            <h3>Vrijwilligers in het zonnetje tijdens het Jaar van de Vrijwilliger</h3>
            <p>2021 was het jaar van de vrijwilliger! Omdat vrijwilligers vaak een sleutelrol spelen die professionals niet altijd kunnen vervullen, werkt CJG Rijnmond veel en graag met vrijwilligers. In een blogserie zetten wij 4 van onze honderden toppers in de spotlights.</p>
            <p>Interim-directeur Jeugd & Gezin Alicia van der Klei trapt de blogserie af en vertelt <a href="https://cjgrijnmond.nl/vrijwilligers-van-cjg-rijnmond-in-de-spotlight" target="_blank" rel="noopener noreferrer">waarom onze vrijwilligers zo belangrijk</a> zijn.</p>
        </div>,
        <div>
            <h3>Weer geslaagd voor HKZ-certificaat Publieke Gezondheid</h3>
            <p>CJG Rijnmond is sinds 2012 de trotse bezitter van het HKZ-kwaliteitskeurmerk. Dit keurmerk toont aan dat de organisatie de klant centraal stelt en de ambitie om continu te verbeteren verankerd heeft in de inrichting en sturing van de organisatie. Elk jaar toetst een onafhankelijke organisatie of CJG Rijnmond nog aan de voorwaarden voor dit keurmerk voldoet. Ook dit jaar, op 15 september, slaagden we voor deze toetsing!</p>
            <p>We kregen complimenten voor de bevlogenheid van onze JGZ-professionals en de manier waarop we rekening houden met risico’s en gebruik maken van kansen. Verder beoordeelden zij onze dienstverlening aan ouders en kinderen en onze vernieuwingskracht als positief.</p>
            <p className="image-content kinderen-18"></p>
        </div>,
        <div>
            <h3>Nieuwe locatie CJG Albrandswaard</h3>
            <p>Albrandswaard heeft er een nieuwe CJG-locatie bij! Sinds maandag 13 september verwelkomen wij ouders en verzorgers met hun kind(eren) van 0 tot 18 jaar ook op de CJG-locatie gevestigd in gezondheidscentrum Portland, aan de Langstraat 11-12 in Rhoon. Deze CJG-locatie nu de hoofdlocatie van CJG Rijnmond in Albrandswaard.</p>
            <p className="image-content kinderen-19"></p>
        </div>,
        <div>
            <h3>Corona en onze aangepaste dienstverlening</h3>
            <p>In 2021 werd onze dienstverlening nog steeds deels beïnvloed door het coronavirus. Zo konden wij in het voorjaar geen vaccinatiedagen organiseren, maar in het najaar gelukkig weer wel. Ook was er nog een lockdown, maar hebben wij alsnog <strong>290.000</strong> contactmomenten gehad, online én offline. En door eigen webinars te ontwikkelen, konden wij grote groepen ouders in één keer te woord staan: soms wel 64 ouders per webinar!</p>
            <p>Alle ouders hielden wij via onze website en sociale media op de hoogte van de – soms snel opeenvolgende – aanpassingen van onze dienstverlening op basis van de gestelde maatregelen vanuit de overheid. Hierin hebben wij altijd de adviezen van het RIVM aangehouden.</p>
        </div>,
        <div>
            <h3>Ondersteuning bij vaccinatiecampagne GGD</h3>
            <p>Op 8 januari startte GGD Rotterdam-Rijnmond met de vaccinatiecampagne tegen het coronavirus. In een convenant tussen CJG Rijnmond en GGD Rotterdam-Rijnmond is afgesproken dat wij hen ondersteunen bij een pandemie. Zowel in de voorbereiding als in de uitvoering hebben wij meegeholpen: we hebben immers veel ervaring met het organiseren van grootschalige vaccinatiecampagnes!</p>
        </div>,
        <div>
            <h3>Jeugdarts Cissy terug van pensioen om GGD te ondersteunen</h3>
            <p>Ken je CJG-jeugdarts Cissy Siebel (67) nog? Zij was al even met pensioen, na 41 dienstjaren in de jeugdgezondheidszorg. Toch twijfelde ze geen moment toen haar werd gevraagd of ze de GGD wilde ondersteunen tijdens de vaccinatiecampagne. Het AD Rotterdam zocht haar op, bij de vaccinatielocatie Rotterdam/The Hague Airport en <a href="https://www.ad.nl/rotterdam/deze-jeugdarts-springt-na-haar-pensioen-bij-op-een-priklocatie-dit-verleer-je-nooit~ad51e335/" target="_blank" rel="noopener noreferrer">maakte een portret</a> van deze bevlogen jeugdarts.</p>
            <p className="image-content kinderen-20"></p>
        </div>,
        <div>
            <h3>Ellen Lindhout praat met RTV Rijnmond over thuiswerken</h3>
            <p>Ellen is jeugdverpleegkundige en teamcoördinator bij CJG Rijnmond. Ze werkte in 2021 volledig vanuit huis. Eerst ging zij naar een middelbare school om gesprekken te voeren met leerlingen, over zaken als: (mentale) gezondheid, de thuissituatie en eventuele hulp. Die gesprekken voerde ze in 2021 nog steeds, maar dan vanachter haar laptop. Rijnmond stelde aan haar en 3 andere regiogenoten de vraag: <a href="https://www.rijnmond.nl/nieuws/1022307/een-jaar-thuiswerken-hoe-bevalt-dat-wij-vragen-het-drie-regiogenoten" target="_blank" rel="noopener noreferrer">Een jaar thuiswerken, hoe bevalt dat?</a></p>
            <p className="image-content kinderen-21"></p>
        </div>
    ],
    'scholen': [
        <div>
            <h3>Tweede contactmoment basisonderwijs</h3>
            <p>Ouders en kinderen zien we nu al in groep 2. Vanaf de zomer van 2021 hebben wij hard gewerkt aan een nieuw contactmoment: het 9 jaar consult. Deze afspraak is er om de ontwikkeling van kinderen te volgen en te onderzoeken hoe het gaat met de gezondheid. Ook wordt de afspraak gecombineerd met het vaccinatiemoment voor kinderen rond de 9 jaar oud.</p>
            <p>In een pilot toetsen wij of dit aanbod aansluit bij de doelgroep en of het praktisch haalbaar is voor onze organisatie. Op 17 februari 2022 is deze pilot in 4 gebieden gestart. Ouders met kinderen rond 9 jaar worden in Rotterdam (Centrum, Prins-Alexander en Delfshaven), Brielle en Ridderkerk hiervoor uitgenodigd.</p>
        </div>,
        <div>
            <h3>Visie op onze rol in het onderwijs gemaakt</h3>
            <p>We stelden een visie op over de rol en inzet van onze organisatie in het onderwijs. De visie biedt een handvat om hierover het gesprek aan te gaan met partners in het onderwijs. De visie kan richting geven om namens CJG Rijnmond deel te nemen in de verschillende initiatieven die er op dit vlak zijn. We formuleerden een advies met focus om in te zetten op kansrijke of urgente activiteiten.</p>
        </div>,
        <div>
            <h3>PATS: van idee tot succes</h3>
            <p>Zo’n 5 jaar geleden vroeg de gemeente Rotterdam aan CJG Rijnmond om een aanpak te bedenken tegen schoolziekteverzuim. Hiervoor ontwikkelden wij PATS (Preventieve Aanpak Terugdringen Schoolziekteverzuim). Eind 2021 werken ongeveer 45 scholen in Rotterdam, Schiedam en Vlaardingen met PATS en elk jaar komen er zo’n 10 scholen bij. Ook bij scholen die (nog) niet met de methode werken, is er door PATS meer bewustwording voor het onderwerp schoolziekteverzuim.</p>
            <p>Geen wonder dus dat wethouders Kasmi (Onderwijs, Cultuur en Toerisme) en Bokhove (Mobiliteit, Jeugd en Taal) van dichtbij wilden zien of én hoe de aanpak werkt. Op 4 november gingen zij op werkbezoek bij het Zuiderpark College. De persoonlijke verhalen van professionals maakten veel indruk.</p>
            <p>Om alle partners op de hoogte te houden, is er ook een PATS-nieuwsbrief ontwikkelt en delen wij op onze website <a href="https://cjgrijnmond.nl/pats/" target="_blank" rel="noopener noreferrer">informatie en praktijkverhalen over PATS</a>.</p>
            <p className="image-content kinderen-11"></p>
        </div>
    ],
    'zorg': [
        <div>
            <h3>Pilot orthoptistenspreekuur op locatie Centrum</h3>
            <p>Jaarlijks worden zo'n 1200 kinderen door de huisarts of jeugdgezondheidsarts doorverwezen naar Het Oogziekenhuis Rotterdam. Hierdoor ontstaan lange wachttijden. Daarom is er in september 2021 een pilot gestart om het eerste consult van deze kinderen niet binnen het ziekenhuis, maar op CJG-locatie Centrum plaats te laten vinden. Deze pilot is inmiddels afgelopen en geëvalueerd.</p>
            <p>Tijdens deze pilot werd er gekeken of de tijd tot het eerste consult verkort kan worden en of het aantal kinderen dat uiteindelijk een consult in het ziekenhuis nodig heeft verkleind kan worden, waardoor de zorg goedkoper en patiëntvriendelijker kan worden georganiseerd.</p>
            <p>Ondanks dat de samenwerking van beide kanten als prettig is ervaren, heeft de pilot niet opgeleverd wat we gehoopt hadden. Op het gebied van wachttijd en kosten kon er geen winst behaald worden.</p>
            <p><a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/evaluatie-van-pilot-orthoptist-op-cjg-centrum">Wat de pilot wel heeft aangetoond</a>, is dat de oogheelkundige screening van de jeugdgezondheidsarts in vrijwel de meeste gevallen overeenkomt met de bevindingen van de orthoptist, wat heel positief is. Ook is de consultatiefunctie is voor de jeugdartsen prettig om te behouden.</p>
            <p className="image-content kinderen-12"></p>
        </div>,
         <div>
            <h3>Paul Blokhuis bezoekt @ease in Rotterdamse bibliotheek</h3>
            <p>Staatssecretaris Paul Blokhuis bracht op 15 april een bezoek aan @ease, bij de CJG-locatie in de Centrale Bibliotheek van Rotterdam. Jongeren van 12 tot 25 jaar met (beginnende) psychische en sociale problemen kunnen daar gratis en anoniem binnenlopen voor een gesprek. Vrijwilligers van @ease staan daar voor hen klaar, met CJG-professionals als achterwacht. Blokhuis ging met zowel jongeren als vrijwilligers in gesprek.</p>
            <p className="image-content kinderen-13"></p>
         </div>,
         <div>
            <h3>Samenwerken voor een gezonde arbeidsmarkt</h3>
            <p>Sinds 1 januari 2021 is CJG Rijnmond officieel lid van het samenwerkingsverband deRotterdamseZorg. Binnen dit initiatief slaan 32 zorg- en onderwijsorganisaties in de regio Rotterdam Rijnmond de handen ineen voor een gezonde arbeidsmarkt in de sector zorg en welzijn.</p>
            <p className="image-content kinderen-14"></p>
         </div>,
         <div>
            <h3>Online conferentie Zero Tolerance Day</h3>
            <p>Op Zero Tolerance Day wordt er wereldwijd aandacht gevraagd voor de strijd tegen genitale verminking bij vrouwen en meisjes. In Nederland doen we dat tijdens een jaarlijkse conferentie, waar kennis, ervaringen en verhalen worden uitgewisseld. In 2021 was deze conferentie in Rotterdam.</p>
            <p>Heb je dit gemist? Je kunt de conferentie terugkijken! Luister onder andere naar het verhaal van Sahra (vanaf 35 minuten van onderstaande video): zij is een van de vrijwilligers die zich inzet binnen het project Vrouwelijke Genitale Verminking vanuit CJG Rijnmond.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/rW_FhBGhv_s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
         </div>
    ],
    'gemeenten': [
        <div>
            <h3>NCJ-column over Westvoornse jeugd</h3>
            <p>Westvoorne komt over als een rustige gemeente. Inwoners hebben een goed inkomen, er is weinig werkloosheid en er lijken geen grote problemen te spelen. Het NCJ werd nieuwsgierig naar deze gemeente, onder de rook van Rotterdam. <a target="_blank" rel="noopener noreferrer" href="https://www.ncj.nl/actueel/nieuws/artikel/onder-de-rook-van-de-grote-stad-hoe-westvoorne-inzet-op-duurzaam-preventiebeleid">Zij interviewden wethouder Wilbert Borgonjen</a> over de jeugd in de gemeente Westvoorne en welke preventieve kansen hij ziet voor het verbeteren van hun gezondheidspositie.</p>
        </div>,
        <div>
            <h3>Armoede de wijk uit <br /> in Oosterflank</h3>
            <p>Armoede in Rotterdam is een groot probleem. In Oosterflank (Prins Alexander) werken 4 organisaties samen om gezinnen met kinderen en voor jongeren in deze wijk te helpen. Hiervoor zijn <a href="https://centrumvoorjeugdengezin.nl/actueel/armoede-de-wijk-uit-in-oosterflank" target="_blank" rel="noopener noreferrer">verschillende projecten</a> opgezet.</p>
            <p className="image-content kinderen-7"></p>
        </div>,
        <div>
            <h3>Signalen Ophalen en beleidsadviezen</h3>
            <p>14 gemeenten hebben de beleidsadviezen ontvangen van de corona-editie van Signalen Ophalen. Naast de duidelijke en lezenswaardige factsheets, is er dit jaar voor elke gemeente ook een adviesrapport opgesteld over de belangrijkste signalen in hun werkgebied.</p>
        </div>,
        <div>
            <h3>Deelname aan lokale coalitie Kansrijke Start</h3>
            <p>Wij vinden dat ieder kind een goede start verdient. Helaas is dit niet voor alle kinderen vanzelfsprekend: 1 op de 6 baby’s in Nederland heeft geen goede start. De eerste 1000 dagen van een kind, van het moment van bevruchting totdat hij 2 jaar is, vormen een belangrijke basis voor zijn ontwikkeling en groei. Het is dus belangrijk om (aanstaande) ouders in een kwetsbare situatie zo vroeg mogelijk te bereiken en te begeleiden. Wij nemen daarom actief deel aan de Rijnmondse coalitie Kansrijke Start.</p>
            <p>De animatie ‘Belang van lokale coalitie Kansrijke Start’ laat zien wat zo'n coalitie is en hoe deze bijdraagt aan een kansrijke start voor kinderen.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/rp3T_6e5brw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
    ],
    'landelijkeJeugdgezondheidzorg': [
        <div>
            <h3>Handreiking Omgaan met armoede in de JGZ</h3>
            <p>In Nederland groeit 1 op de 13 kinderen op in armoede. Professionals in de geboorte- en jeugdgezondheidszorg spelen een belangrijke rol in het signaleren en bestrijden van kinderarmoede. Om hen te ondersteunen is de Handreiking <a target="_blank" rel="noopener noreferrer" href="https://assets.ncj.nl/docs/15d3df3a-22c6-4251-bed6-57a1af266d31.pdf">‘Omgaan met armoede in de jeugdgezondheid’</a> ontwikkeld. De handreiking is donderdag 14 oktober officieel gelanceerd door het NCJ.</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Nu2Clng0XDo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>,
        <div>
            <h3>Visie op Kansrijke Start</h3>
            <p>In 2018 lanceerde het ministerie van Volksgezondheid, Welzijn en Sport (VWS) het landelijke actieprogramma Kansrijke Start met als hoofddoel: een kansrijke start voor zoveel mogelijk kinderen. Uit de vierde voortgangsrapportage blijkt dat 275 van de 352 gemeenten in Nederland bouwen aan lokale coalities, waarin domeinoverstijgend wordt samengewerkt.</p>
            <p>Wij nemen actief deel aan de lokale coalities van Kansrijke Start, die in de gemeenten van Rotterdam-Rijnmond starten of al actief zijn. Onze rol en ambities voor Kansrijke Start werden begin 2021 verduidelijkt en vastgesteld in de visie Kansrijke Start CJG Rijnmond.</p>            
        </div>,
        <div>
            <h3>Diversity Day</h3>
            <p>Sinds 2019 vieren we op de eerste dinsdag van oktober Diversity Day. Ook CJG Rijnmond staat op deze dag stil bij thema’s als inclusie, diversiteit en verbondenheid. Onder het motto ‘Jezelf zijn werkt’ willen bedrijven die deelnemen aan Diversity Day laten zien dat een mix van mensen met een verschil in culturele afkomst, seksuele oriëntatie, leeftijd, gender of arbeidsvermogen positief is.</p>
            <p>Diversity Day was de perfecte dag om ons manifest ‘Iedereen is welkom’ te publiceren voor onze medewerkers. Hiermee faciliteren we gesprekken over inclusie en diversiteit. Hiermee gaven we ook direct het startschot om de komende tijd, samen met onze medewerkers, aan de slag te gaan met deze thema’s.</p>
            <p className="image-content kinderen-8"></p>
        </div>,
        <div>
            <h3>Wetsvoorstel prenataal huisbezoek door JGZ</h3>
            <p>In juni 2021 werd het wetsvoorstel ‘Prenataal huisbezoek door de JGZ’ aangenomen in de Tweede Kamer. Dit voorstel is erop gericht zwangere vrouwen of gezinnen in een kwetsbare situatie in een vroeg stadium de juiste hulp en ondersteuning te bieden. Met deze wetswijziging krijgen gemeenten de taak om een prenataal huisbezoek aan te bieden aan zwangeren of gezinnen in een kwetsbare situatie.</p>
        </div>,
        <div>
            <h3>Samen Toekomst Maken</h3>
            <p>De jeugd is de toekomst, dus investeren en innoveren in het jeugddomein is van groot belang. Organisaties in diverse sectoren van het jeugddomein hebben dezelfde uitdagingen op het gebied van (digitale) innovaties. Daarom worden kennis, tools, ervaringen en vraagstukken over (digitale) innovatie in het jeugddomein samengebracht tijdens het evenement Samen Toekomst Maken.</p>
            <p>In aanloop van de derde editie van Samen Toekomst Maken in 2022 werden er in 2021 al dialoogsessies georganiseerd: over <a target="_blank" rel="noopener noreferrer" href="https://samentoekomstmaken.nl/dialoogsessies/dialoogsessie-24-juni-2021">nudging</a> en <a target="_blank" rel="noopener noreferrer" href="https://samentoekomstmaken.nl/dialoogsessies/dialoogsessie-5-oktober-2021">mentale gezondheid</a>.</p>
            <p>De organisatie van Samen Toekomst Maken is in handen van Associatie voor Jeugd: een programmagroep van professionals en communicatiespecialisten van organisaties uit de diverse sectoren. De betrokken organisaties zijn Garage 2020, GGD GHOR, NCJ, CJG Rijnmond, NJI, Actiz, Cardea en GGD Hollands Noorden.</p>
            <p className="image-content kinderen-9"></p>
        </div>,
        <div>
            <h3>Brandbrief Jeugd aangeboden aan Blokhuis</h3>
            <p>Onder aanvoering van de Barendrechtse wethouder Reshma Roopram ondertekenden ruim 150 wethouders de brandbrief Jeugd, waarin zij het kabinet oproepen tot actie vanwege de gevolgen van de coronapandemie voor jongeren. Tientallen instanties, waaronder CJG Rijnmond, ondersteunden dit initiatief. Op 19 februari overhandigde Roopram de brandbrief digitaal aan staatssecretaris Blokhuis. Het kabinet maakte al eerder bekend extra geld uit te willen trekken voor een maatschappelijk steunpakket.</p>
            <p className="image-content kinderen-10"></p>
        </div>
    ],
    'footer': [
        <div>
            <h3>Nieuw merk CJG Rijnmond gelanceerd</h3>
            <p>Trots lanceerden we eind 2021 de nieuwe merkpositionering van CJG Rijnmond. Wat een mijlpaal! We hebben na 10 jaar ons merk tegen het licht gehouden, opgepoetst en vernieuwd. De corporate story vertelt het verhaal van onze organisatie. De nieuwe pay-off belooft wat we doen: de ouders centraal zetten. Want zij weten wat het beste is voor hun kind.</p>
            <p>Krachtige beelden en aansprekende zinnen maken de merkcampagne compleet. En dat allemaal met 1 nieuw logo. We werken vanuit 1 organisatie met 1 naam: CJG Rijnmond. Zo zijn we niet alleen meer herkenbaar, maar ook beter vindbaar online. In 2022 kan niemand meer om ons heen!</p>
            <br />
            <p>Hans Butselaar</p>
            <p>Voorzitter Raad van Bestuur</p>
        </div>
    ]
}
